/* istanbul ignore file */
import {Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DeviceInfo from 'react-native-device-info';
import {getCMSV2Call} from '../core/cmsServices';
import {buildNumberWeb} from '../web.config';
//import {reportError} from '../helpers/crashlytics/fbCrashlytics';

/**
 * get graphQL auth
 * @returns JWT
 */
const getGraphqlAuth = async () => {
  const os = Platform.OS;
  let buildNumber = os === 'web' ? buildNumberWeb : DeviceInfo.getBuildNumber();
  // if (buildNumber === 'unknown') {
  //   console.log('no hay :) ');
  //   buildNumber = '140';
  // }
  const path = `auth_app/get_graphql_token/{uid}/${os}/${buildNumber}`;
  try {
    console.warn('Getting new token');
    const data = await getCMSV2Call(path);
    console.log('data funcionando: ', data, 'path: ', path);
    if (data.status !== 'SUCCESS') {
      throw new Error(data?.message);
    }
    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const getToken = async () => {
  let token = await AsyncStorage.getItem('apolloToken');
  let user = await AsyncStorage.getItem('user');
  if (token) {
    token = JSON.parse(token);
    const expired = new Date() > new Date(token.expiresAt);
    if (!expired) {
      return token.token;
    }
  }

  try {
    token = await getGraphqlAuth();
    let expiresAt = new Date();
    token.expiresAt = expiresAt.setSeconds(
      expiresAt.getSeconds() + token.expires_in - 10,
    );
    await AsyncStorage.setItem('apolloToken', JSON.stringify(token));
    return token?.token;
  } catch (e) {
    // No requiere reportError
    // reportError('auth', {token: token}, 'getToken', e);
    //console.error('No estas autorizado!!', e);
  }
};
export const refreshToken = async () => {
  return AsyncStorage.removeItem('apolloToken').then(getToken);
};
