import React, {useState} from 'react';
import {
  Image,
  Heading,
  FormControl,
  Stack,
  Box,
  WarningOutlineIcon,
  Center,
  Input,
  Text,
} from 'native-base';

import images from '../../res/images';
import KeyboardContainer from '../../components/Chat/ChatContainer/KeyboardContainer';
import {useTranslation} from 'react-i18next';
import PrimaryButton from '../../components/PrimaryButton';
import {ImageBackground} from 'react-native';
import {getWidthAndHeight} from '../../helpers/utils/utils';
import InputForm from '../../components/Form/InputForm';

/**
 * Screen donde se agrega la nueva contraseña
 */

export default function EntryEmployeeLogin({
  onSubmit,
  onBack,
  loading,
  commonStylesWeb,
}) {
  const {t, i18n} = useTranslation();
  const {logoMindsurf, backgroundGradient} = images;
  const [errors, setErrors] = useState({});

  const [employeeNumber, setEmployeeNumber] = useState('');
  const [employeeName, setemployeeName] = useState('');

  const {height} = getWidthAndHeight();

  function validateEmployeeNumber() {
    return employeeNumber.length >= 1;
  }

  function validateEmployeeName() {
    return employeeName.length > 1;
  }

  function validateForm() {
    return validateEmployeeNumber() && validateEmployeeName();
  }

  return (
    <KeyboardContainer>
      <Stack
        alignSelf="center"
        px="4"
        safeArea
        my="2"
        w={{
          base: '100%',
          md: '90%',
        }}>
        {/*<Center w={'20%'}>
            <IconIon
              onPress={onBack}
              name={'arrow-back-outline'}
              size={30}
              color={colors.primary100}
            />
          </Center>*/}
        {/* Titulo */}
        <Box mt={5} alignItems="center">
          <Image
            size={'xl'}
            resizeMode={'contain'}
            source={logoMindsurf}
            alt="Mindsurf"
            height={30}
          />
          <Heading
            fontFamily={'recoleta'}
            my={5}
            textAlign={'center'}
            color="primary.100"
            fontWeight={800}
            size={'xl'}>
            {t('login:labelLoginEmployee')}
          </Heading>
        </Box>
        {/* Form */}
        <Box>
          {/* Número de empleado*/}
          <Box mb={2}>
            <InputForm
              testID={'number-employee'}
              helperText={t('login:inputEmployeeNumberInvalid')}
              label={t('login:inputEmployeeNumber')}
              setInput={setEmployeeNumber}
              input={employeeNumber}
              isEmail={true}
              valid={employeeNumber.length > 0 && validateEmployeeNumber()}
              showWarning={
                employeeNumber.length !== 0 && !validateEmployeeNumber()
              }
            />
          </Box>

          {/* Nombre de empleado */}
          <InputForm
            testID={'name-employee'}
            helperText={t('login:inputNameInvalid')}
            label={t('login:inputName')}
            setInput={setemployeeName}
            input={employeeName}
            isEmail={true}
            valid={employeeName.length > 0 && validateEmployeeName()}
            showWarning={employeeName.length !== 0 && !validateEmployeeName()}
          />
        </Box>
        <PrimaryButton
          testId={'button-submit'}
          onPress={() => {
            onSubmit({employeeNumber, employeeName});
          }}
          extraParams={{
            isDisabled: !validateForm(),
            mt: 5,
            isLoading: loading,
            ...commonStylesWeb,
          }}>
          {t('login:buttonLogin')}
        </PrimaryButton>
        <Center mt={5}>
          <Text
            fontSize={'md'}
            color={'primary.100'}
            fontWeight={800}
            alignSelf={'center'}
            testID={'middle-id-code'}>
            {t('login:employeeLoginHelperTitle')}
          </Text>
          <Text textAlign={'center'}>{t('login:employeeLoginHelper')}</Text>
        </Center>
      </Stack>
    </KeyboardContainer>
  );
}
