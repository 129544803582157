import {Box, FormControl, Heading, Image, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import validator from 'validator';
import InputForm from '../../components/Form/InputForm';
import NavigateBar from '../../components/NavigateBar';
import PrimaryButton from '../../components/PrimaryButton';
import images from '../../res/images';
import {colors} from '../../styles/colors';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';

/**
 * Screen donde se agrega la nueva contraseña
 */

export default function NewPassword({
  onSubmit,
  onBack,
  loading,
  commonStylesWeb,
}) {
  const {t} = useTranslation();
  const {topBarLogo} = images;
  const [password, setPassword] = useState('');
  const [comparePassword, setComparePassword] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [confirmPW, setConfirmPW] = useState('');
  const [helperTextPassword, setHelperTextPassword] = useState();
  const [helperTextConfirmPass, setHelperTextConfirmPass] = useState();

  const {height} = getWidthAndHeight();

  function onChangePassword(val) {
    setPassword(val);
    const validPW = validator?.isStrongPassword(val, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    setValidPassword(validPW || val.length <= 3);
  }

  function validateForm() {
    return validPassword && password === confirmPW && password.length > 7;
  }

  const handleConfirmPassword = text => {
    setConfirmPW(text);
    setComparePassword(password === confirmPW);
    console.log('password - confirmpass', password, confirmPW);
  };

  useEffect(() => {
    setHelperTextPassword(validPassword ? '' : t('login:inputPasswordInvalid'));
    setHelperTextConfirmPass(
      comparePassword ? '' : t('login:inputPasswordAgainInvalid'),
    );
  }, [validPassword, comparePassword, password]);

  useEffect(() => {
    setComparePassword(password === confirmPW);
  }, [password, confirmPW]);

  return (
    <Box height={!isWeb() ? undefined : height * 0.8}>
      <NavigateBar
        backEvent={onBack}
        headerTitle={
          <Image
            size={'xs'}
            resizeMode={'contain'}
            alt={'logo'}
            source={topBarLogo}
          />
        }
      />
      {/* Titulo */}
      <Box alignItems="center">
        <Text
          testID={'login-title-new-password-label'}
          w={'80%'}
          mt={5}
          fontFamily={isWeb() ? 'Recoleta' : 'recoleta'}
          fontWeight={800}
          textAlign="center"
          color="primary.100"
          fontSize={'3xl'}>
          {t('login:titleNewPassword')}
        </Text>
        <Heading
          mt={5}
          textAlign="center"
          fontWeight={600}
          fontFamily={'Quicksand'}
          color={colors.tertiary10}
          fontSize="lg">
          {t('login:descNewPassword')}
        </Heading>
      </Box>
      {/* Form */}
      <Box w={'100%'} mt={2} px={3}>
        <FormControl mb="2" isInvalid={!validPassword}>
          {/* Contraseña */}
          <Box mt={5}>
            <InputForm
              helperText={helperTextPassword}
              label={t('login:inputPassword')}
              setInput={onChangePassword}
              input={password}
              isEmail={false}
              valid={validPassword}
              testID={'input-password'}
            />
          </Box>
          {/* Contraseña repetir */}
          <Box>
            <InputForm
              helperText={helperTextConfirmPass}
              label={t('login:inputPasswordAgain')}
              setInput={handleConfirmPassword}
              input={confirmPW}
              isEmail={false}
              valid={comparePassword}
              confirmPassword={true}
              testID={'input-confirm-password'}
            />
          </Box>
        </FormControl>
      </Box>
      <Box px={3}>
        <PrimaryButton
          testId={'button-new-password'}
          onPress={() => {
            onSubmit(password);
          }}
          extraParams={{
            isDisabled: !validateForm(),
            mt: isWeb() ? '10%' : '15%',
            isLoading: loading,
            ...commonStylesWeb,
          }}>
          {t('login:newPasswordButton')}
        </PrimaryButton>
      </Box>
    </Box>
  );
}
