import InputResponse from '../InputResponse';
import OptionResponse from '../OptionResponse';

const ResponseType = ({...props}) => {
  const type = props.type;
  switch (type) {
    case 'INPUT':
      return <InputResponse {...props} />;
    case 'OPTIONS':
      return <OptionResponse {...props} />;
    default:
      return () => <></>;
  }
};

export default ResponseType;
