import {Button, ScrollView} from 'native-base';
import React, {useState} from 'react';
import FavIcon from '../icons/FavIcon';
import {isWeb} from '../../helpers/utils/utils';
//import {useQuery} from '@apollo/client';
//import {GET_DISPLAY_DETAILS_GROUPS} from '../../graphql/queries/displayDetails';

export default function FilterButton({
  filterId,
  filter,
  setFilter,
  isFilterTypes,
  activeIndex,
}) {
  const [colorActive, setColorActive] = useState(activeIndex);
  let filterLength = isFilterTypes ? filter?.length : filter?.length - 1;

  // funcion para AllTools - utiliza el nombre de contenido (flow, audio, etc)
  const handleFilterContent = (changeFilter, index) => {
    const filterMapping = {
      Audio: 'audio',
      Conversations: 'flow',
      Conversaciones: 'flow',
      Evaluations: 'evaluation_v2',
      Evaluaciones: 'evaluation_v2',
      Video: 'video',
      Actividad: 'programa_v2_activity',
      Activity: 'programa_v2_activity',
      Article: 'article',
      Artículo: 'article',
      'Terapia Digital': 'programa_v2',
      'Digital Therapy': 'programa_v2',
    };
    const filterAsigned = filterMapping[changeFilter] || changeFilter;
    setFilter(filterAsigned);
    setColorActive(index === 0 ? 0 : index);
  };

  // funcion que se maneja para Tools - utiliza el order
  const handleFilterClick = (changeFilter, i) => {
    const newFilter = changeFilter === 0 ? null : filterId?.[i]?.id;
    setFilter(newFilter);
    setColorActive(changeFilter === 0 ? 0 : i);
  };

  return (
    <ScrollView
      testID="list-buttons-filter"
      showsHorizontalScrollIndicator={false}
      mx={2}
      horizontal>
      {filter?.map((filtro, index) => (
        <Button
          shadow={2}
          testID={`button-filter-${filtro}`}
          key={index}
          px={6}
          py={2}
          mt={1}
          ml={2}
          mb={1}
          _pressed={{bg: 'secondary.100'}}
          onPress={() =>
            isFilterTypes
              ? handleFilterContent(filtro, index)
              : handleFilterClick(filter?.indexOf(filtro), index)
          }
          bg={colorActive === index ? 'primary.100' : 'secondary.100'}
          borderRadius={'full'}
          disabled={colorActive === index ? true : false}
          _text={{
            color: colorActive === index ? 'secondary.100' : 'primary.100',
            fontSize: 'xs',
            fontWeight: 800,
            fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
          }}>
          {filterLength === index ? <FavIcon /> : filtro}
        </Button>
      ))}
    </ScrollView>
  );
}
