export default {
  typeDescription: '{{type}} Exercise',
  duration: 'Duration:',
  minutes: 'minutes',
  emptyHistory:
    "Here will appear a record of the times you've done this exercise or assessment.",
  historyTitle: 'Usage History',
  seeAll: 'See All',
  title: 'Exercises',
  titleCategory: 'Categories',
  noDD: 'There isn’t any content of this type yet… but there will be soon. 😃 ',
  noFav:
    'Add exercises to your favorites so you can access them ⚡ just that fast ⚡.',
  titleRating: 'How do you rate the help provided by this exercise?',
  commentRating: 'Leave a comment',
  textareaRating: 'How can we improve?',
  sendRating: 'Send',
  rating0: "It didn't help me at all",
  rating1: 'It helped me very little',
  rating2: 'It helped me a little',
  rating3: 'The help is average',
  rating4: 'The help is good',
  rating5: 'It helped me a lot',
  modalMessage: 'Thank you for your feedback! 🙌',
};
