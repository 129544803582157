import {Box, Button, Center, HStack, Input, Pressable, Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import {logSimpleEvent} from '../../helpers/analytics/fbAnalytics';
import {ChatContext} from '../../hooks/ChatContext';
import {colors} from '../../styles/colors';
import {FEEDBACK_LEVEL, FEEDBACK_TYPES} from '../../types';
import AnimatableBox from '../AnimatableBox/AnimatableBox';

const FeedbackCard = ({
  setShowModalThanks,
  isProgram,
  content,
  id,
  contentType,
}) => {
  const {t} = useTranslation();
  const [text, setText] = useState('');
  const [disable, setDisable] = useState(true);
  const {chatOptions, setChatOptions} = useContext(ChatContext);
  const [roundedStyle, setRoundedStyle] = useState('full');
  const [sessionP, setSessionP] = useState();
  const [programP, setProgramP] = useState();
  const [feedback, setFeedback] = useState({
    dislike: false,
    neutral: false,
    emojiLove: false,
    comment: null,
  });

  useEffect(() => {
    // Verificar el número de líneas de texto y actualizar el estilo de borde redondeado
    if (text.length > 30) {
      setRoundedStyle('3xl');
    } else {
      setRoundedStyle('full');
    }
  }, [text]);

  async function storageFeedbackProgram(type, value, send) {
    /*
     * 1. Si  lleva send = true se envia lo que esta en feedback
     * 2. Si no, solo se hace el guardado.
     * */
    if (
      (type === FEEDBACK_TYPES.DISLIKE ||
        type === FEEDBACK_TYPES.NEUTRAL ||
        type === FEEDBACK_TYPES.EMOJI_LOVE) &&
      !send
    ) {
      const currentVal = feedback[type];

      const resetFeedback = {
        dislike: false,
        neutral: false,
        emojiLove: false,
        type: null,
      };
      setFeedback({
        ...feedback,
        ...resetFeedback,
        [type]: !currentVal,
        type: !currentVal ? type : undefined,
      });
      return;
    }
    // aqui teoricamente dio clic a boton de enviar.
    // get type depending on the feedback
    const newType = feedback?.type || FEEDBACK_TYPES.COMMENT;
    const payload = {
      step: '3.Z.A FeedbackRecommendation',
      properties: {
        from: {
          component: 'ProgramBox',
          screen: 'Home',
        },
      },
      type: newType,
      value: 1,
      comment: text ? text : null,
      level: FEEDBACK_LEVEL.PROGRAM_SESSION,
      program_id: programP?.id,
      program_name: programP?.name,
      program_type: 'program_v2',
      program_session_id: sessionP?.id,
      program_session_internal_name: sessionP?.internalName,
      program_session_name: sessionP?.achievement?.displayDetails?.title,
      program_session_order: sessionP?.order,
    };
    setShowModalThanks(true);
    console.log('payload a rudder', JSON.stringify(payload, null, 2));
    logSimpleEvent('Program', {...payload});
  }

  async function storageFeedback(type, value) {
    let payload;
    console.log('===================');
    const lastFeedbackType = feedback?.type;
    const currentVal = feedback[type];
    console.log('currentVal', type, ': ', currentVal);
    payload = {
      step: '3.B FeedbackRecommendation',
      sessionID: chatOptions?.sessionID,
      conversationFlowName: chatOptions?.conversationFlowName,
      type,
      value: currentVal ? -1 : 1,
      level: FEEDBACK_LEVEL.EXERCISE,
      message: content,
      displayDetailId: id,
      displayDetailContentType: contentType,
      conversationMessageId: id,
      conversation: chatOptions?.conversation,
      date: new Date().toISOString(),
    };
    await logSimpleEvent('conversationFlow', {...payload});
    console.log('rudder 1');

    console.log('payloaGENERAL: ', JSON.stringify(payload, null, 2));

    const resetFeedback = {
      dislike: false,
      neutral: false,
      emojiLove: false,
      type: null,
    };
    setFeedback({...feedback, ...resetFeedback, [type]: !currentVal, type});
    const dislikePayload = {
      ...payload,
      value: -1,
      type: lastFeedbackType,
    };
    console.log('payloadForRevert: ', JSON.stringify(dislikePayload, null, 2));
    await logSimpleEvent('conversationFlow', {...dislikePayload});
  }

  return (
    <AnimatableBox animation="fadeIn" duration={2000}>
      <Box testID="cardBodyFeedback">
        <Center>
          <Text
            mx={4}
            mb={4}
            fontSize={'sm'}
            color={colors.primary100}
            fontWeight={800}
            textAlign={'center'}>
            {isProgram ? t('chat:feedbackProgram') : t('chat:feedback')}
          </Text>
        </Center>
        <Box flexDirection={'row'} overflow={'hidden'}>
          <Pressable
            testID="button-dislike"
            py={2}
            mx={2}
            borderColor={feedback.dislike ? 'primary.100' : 'transparent'}
            borderWidth={2}
            bg={'secondary.100'}
            onPress={() => {
              isProgram
                ? storageFeedbackProgram(FEEDBACK_TYPES.DISLIKE, 1)
                : storageFeedback(FEEDBACK_TYPES.DISLIKE, 1);
              setDisable(!!feedback.dislike);
            }}
            borderRadius={'full'}
            flex={1}>
            <Text fontSize={'md'} textAlign={'center'}>
              👎
            </Text>
          </Pressable>
          <Pressable
            py={2}
            mx={2}
            borderColor={feedback.neutral ? 'primary.100' : 'transparent'}
            borderWidth={2}
            bg={'secondary.100'}
            onPress={() => {
              isProgram
                ? storageFeedbackProgram(FEEDBACK_TYPES.NEUTRAL, 2)
                : storageFeedback(FEEDBACK_TYPES.NEUTRAL, 2);
              setDisable(!!feedback.neutral);
            }}
            borderRadius={'full'}
            flex={1}>
            <Text fontSize={'md'} textAlign={'center'}>
              😐
            </Text>
          </Pressable>
          <Pressable
            py={2}
            mx={2}
            borderColor={feedback.emojiLove ? 'primary.100' : 'transparent'}
            borderWidth={2}
            bg={'secondary.100'}
            onPress={() => {
              isProgram
                ? storageFeedbackProgram(FEEDBACK_TYPES.EMOJI_LOVE, 3)
                : storageFeedback(FEEDBACK_TYPES.EMOJI_LOVE, 3);
              setDisable(!!feedback.emojiLove);
            }}
            borderRadius={'full'}
            flex={1}>
            <Text fontSize={'md'} textAlign={'center'}>
              ❤
            </Text>
          </Pressable>
        </Box>
        {isProgram && (
          <>
            <HStack testID="input-feedback" mt={4}>
              <Input
                testID={'feedback-input'}
                w={'100%'}
                h={'100%'}
                fontFamily={'quicksand'}
                value={text}
                onChangeText={setText}
                placeholderTextColor={colors.darkLight}
                placeholder={t('chat:placeholderProgram')}
                multiline={true}
                numberOfLines={2}
                autogrow={true}
                scrollEnabled={true}
                returnKeyType={'done'}
                variant={'lila'}
                py={roundedStyle === '3xl' || Platform.OS === 'ios' ? 4 : 1}
                px={4}
                rounded={roundedStyle}
                size={'lg'}
                borderColor={'secondary.20'}
              />
            </HStack>
            <Center mt={4}>
              <Button
                testID="button-send-feedback"
                _text={{color: 'secondary.100', fontWeight: 800}}
                w={'60%'}
                bg={disable ? 'violet.300' : 'violet.100'}
                py={4}
                px={4}
                onPress={async () => {
                  await storageFeedbackProgram(null, text, true);
                }}
                _pressed={{bg: 'tertiary.100'}}
                disabled={disable}
                rounded={'full'}>
                {t('program:send')}
              </Button>
            </Center>
          </>
        )}
      </Box>
    </AnimatableBox>
  );
};

export default FeedbackCard;
