import React, {useState} from 'react';
import {Center, Image, Fade} from 'native-base';
import images from '../res/images';

/**
 * Screen de Splash del inicio
 */

export default function Splash() {
  const [logo, setLogo] = useState(images.splashScreenLogo);

  async function getCompanyLogo() {
    // Request for logo company
    //setLogo(newLogo);
  }

  return (
    <Center bg={'primary.100'} flex={1} px="3">
      <Fade in={true}>
        <Image
          animation="fadeIn"
          size={81}
          resizeMode={'contain'}
          source={logo}
          alt="Mindsurf"
        />
      </Fade>
    </Center>
  );
}
