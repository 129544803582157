import PropTypes from 'prop-types';
import React from 'react';
import {ImageBackground, StyleSheet, View} from 'react-native';
import Chat from '../../res/svgs/bg-chat.svg';
import Login from '../../res/svgs/bg-login.svg';
import Onboarding from '../../res/svgs/bg-onboarding.svg';
import images from '../../res/images';
import {ScrollView} from 'native-base';

/**
 * Componente  BackgroundGradient.web normal
 */

const BackgroundGradient = ({
  children,
  padding = false,
  type = 'ONBOARDING',
  safeBottom = true,
  safeTop = true,
  scroll = true,
  bgColor = undefined,
}) => {
  const SVG_BG = {
    ONBOARDING: <Onboarding style={styles.backgroundImage} />,
    LOGIN: <Login style={styles.backgroundImage} />,
    CHAT: <Chat style={styles.backgroundImage} />,
  };

  const {backgroundGradient} = images;

  return (
    <ImageBackground
      style={{
        flex: 1,
        //width: width,
        resizeMode: 'cover',
        justifyContent: 'center',
      }}
      source={backgroundGradient}>
      <ScrollView
        style={bgColor ? {backgroundColor: bgColor} : undefined}
        py={5}>
        {children}
      </ScrollView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    ...StyleSheet.absoluteFillObject,
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

BackgroundGradient.propTypes = {
  text: PropTypes.string,
};
export default BackgroundGradient;
