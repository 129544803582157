import React from 'react';
import {Text, HStack} from 'native-base';
import PropTypes from 'prop-types';
import {createChatStyles} from '../../../styles/base';

/**
 * Componente  NoBotMessage.native normal
 */

const NoBotMessage = ({content, id}) => {
  const chatStyles = createChatStyles();
  return (
    <HStack
      ml={10}
      key={id}
      style={{...chatStyles.message, ...chatStyles.user}}>
      <Text color={'primary.100'} fontSize={'16px'}>
        {content}
      </Text>
    </HStack>
  );
};

NoBotMessage.propTypes = {
  message: PropTypes.object.isRequired,
};
export default NoBotMessage;
