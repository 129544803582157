import {Button, Center, Modal, Text, TextArea} from 'native-base';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RemoteConfigContext} from '../hooks/SessionContext';
import {FEEDBACK_TYPES} from '../types';
import {KeyboardAvoidingView} from 'react-native';

/**
 * Componente  ModalFeedback normal
 */

const ModalFeedback = ({
  showModal,
  setShowModal,
  onFeedback,
  closeModalComent = () => {},
}) => {
  const {t, i18n} = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const {
    remoteConfig: {gpt3Diary},
  } = useContext(RemoteConfigContext);
  const [language] = useState(i18n?.language);
  // console.log('gpt3Diary', JSON.stringify(gpt3Diary, null, 2));
  const gpt3Labels = {
    title: gpt3Diary?.feedback?.title?.[language] || '',
    afterTitle: gpt3Diary?.feedback?.afterTitle?.[language] || '',
    afterDescription: gpt3Diary?.feedback?.afterDescription?.[language] || '',
  };

  function sendFeedBack() {
    if (!feedback) return;
    onFeedback({
      feedBackType: FEEDBACK_TYPES.COMMENT,
      comment: feedback,
    });
    setShowModal(false);
  }

  function handleTextChange(text) {
    setFeedback(text);
  }

  return (
    <Modal
      zIndex={100}
      isOpen={showModal}
      onClose={() => {
        setFeedback('');
        setShowModal(false);
      }}
      size={'md'}
      _backdrop={{opacity: 0.6, bg: 'primary.100'}}>
      <KeyboardAvoidingView
        style={{flex: 1, justifyContent: 'flex-end'}}
        enabled
        behavior={'position'}>
        <Modal.Content
          w={'100%'}
          bg={'secondary.100'}
          my={isFocus ? 1 : 20}
          borderColor={'primary.5'}>
          <Modal.CloseButton
            _icon={{color: 'primary.100'}}
            _pressed={{bg: 'transparent'}}
            onPress={() => {
              closeModalComent();
              setShowModal(false);
            }}
          />
          <Modal.Body mt={3} p={5}>
            <Center w={'100%'} mt={2}>
              <Text
                px={8}
                numberOfLines={1}
                adjustsFontSizeToFit={true}
                fontSize={'md'}
                color={'primary.100'}
                fontWeight={800}>
                {gpt3Labels?.title}
              </Text>
              <TextArea
                testID={'feedback-textarea'}
                my={5}
                bg={'white'}
                _focus={{bg: 'white', borderColor: 'violet.90'}}
                borderColor={'violet.90'}
                borderRadius={10}
                borderWidth={1}
                minH={105}
                onFocus={() => {
                  console.log('onFocus');
                  setIsFocus(true);
                }}
                numberOfLines={10}
                defaultValue={feedback}
                onChangeText={text => {
                  handleTextChange(text);
                }}
                // value={feedback}
                // onChangeText={text => setFeedback(text)}
                // onChange={text => setFeedback(text)}
                returnKeyType="next"
              />
              <Button
                alignSelf={'flex-end'}
                testID={'sendFeedback'}
                isDisabled={feedback === ''}
                onPress={() => {
                  sendFeedBack();
                  setFeedback('');
                }}
                variant={'primary'}>
                <Text color={'secondary.100'} mx={7}>
                  {t('chat:feedbackButton')}
                </Text>
              </Button>
            </Center>
          </Modal.Body>
        </Modal.Content>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default ModalFeedback;
