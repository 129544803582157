/* istanbul ignore file */
//import crashlytics from '@react-native-firebase/crashlytics';
//import * as Sentry from '@sentry/react-native';
//import crashlytics
import firebase from 'firebase/compat/app';
import * as Sentry from '@sentry/react';

export class CrashlyticsError extends Error {
  constructor(error) {
    super(error.type);
    this.data = {error};
    this.statusCode = 400;
  }
}

export const reportError = (type, data, method, e, message) => {
  try {
    const error = {
      data,
      type,
      method,
      description: e instanceof Error ? e.message : e,
    };
    const msg = message || (e instanceof Error ? e.message : e.toString());
    console.warn('Error', error);
    firebase?.crashlytics?.recordError(new CrashlyticsError(error));
    const errorInstance = new Error(msg);
    Sentry.captureException(errorInstance, {
      extra: {
        ...error,
        message: msg,
        description: e instanceof Error ? e.toString() : JSON.stringify(e),
      },
    });
  } catch (error) {
    console.warn('Error', error);
  }
};

export const sentryCaptureMessage = (message, data) => {
  Sentry.captureMessage(message, {
    extra: data,
  });
};
