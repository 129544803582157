/* eslint-disable no-sparse-arrays */
/* istanbul ignore file */

//import fbFunctions from '@react-native-firebase/fbFunctions';
import {reportError} from '../helpers/crashlytics/fbCrashlytics';
import {fbFunctions} from './firebase/firebaseAuth';

// android emulator
// fbFunctions().usefbFunctionsEmulator('http://10.0.2.2:5001');
// iOS emulator
// fbFunctions().usefbFunctionsEmulator('http://localhost:5001');
// fbFunctions.useEmulator('http://10.0.2.2', 5001);
// local web
// fbFunctions.useFunctionsEmulator('http://localhost:5001');

/**
 *
 * CMS DATA NEW
 * @params endpoint: url
 */

/** Nueva Función de CMS Data */
async function getCMSV2Call(url) {
  const httpsCallableCMS = fbFunctions.httpsCallable('cmsDataV2');
  try {
    const response = await httpsCallableCMS({
      endpoint: url,
    });
    return response.data;
  } catch (err) {
    console.log('Error', err);
    reportError('cms', url, 'cmsDataV2', err);
    throw new Error(err);
  }
}

export {getCMSV2Call};

/**
 * USERS
 */

export const getUserMenuEntries = async () => {
  const path = 'profile_topics/{uid}';
  const response = await getCMSV2Call(path);
  return !response.result ? [] : response.result;
};

/** TODO: {"message": "StatusCodeError", "status": "ERROR"}  */
export const getMedia = async (courseId, urlId) => {
  const path = `get_exercise_url/${urlId}/{uid}/${courseId}`;
  // const path = `programcoursesdetail/{uid}/${courseId}`;
  try {
    const data = await getCMSV2Call(path);
    if (data?.status !== 'SUCCESS') {
      throw new Error(data?.message);
    }
    return data.result;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const getCourseDetail = async courseId => {
  const path = `programcoursesdetail/{uid}/${courseId}`;
  try {
    const data = await getCMSV2Call(path);
    if (data?.status !== 'SUCCESS') {
      throw new Error(data?.message);
    }
    return data.result;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const checkCompanyPrefix = async registerPrefix => {
  const path = `membership/company_prefix_employee/${registerPrefix}`;
  try {
    const data = await getCMSV2Call(path);
    console.log('data---');
    console.log(data);
    if (data?.status === 'SUCCESS') {
      return data?.result?.status || false;
    }
  } catch (e) {
    console.log(e);
    // TODO exception: Validar el correcto manejo de errores
    reportError('cms', registerPrefix, 'checkCompanyPrefix', e);
    return false;
  }
};

/**
 *
 * Borrar Usuario
 */
export const deleteUser = async () => {
  // aquí cambie esta linea porque fbFunctions no es una función sino un objeto y me estaba dando error
  const deleteUserData = fbFunctions.httpsCallable('deleteUserData');
  try {
    const data = await deleteUserData();
    return data;
  } catch (e) {
    console.log('Error', e);
    // TODO exception: Manejar el tipo de error e informar al usuario
    reportError('cms', {}, 'deleteUser', e);
  }
};

/*
 * Obtener la hora exacta del servidor
 * */
export const getServerTimestamp = async () => {
  const callable = fbFunctions.httpsCallable('TempServerTimestamp');
  try {
    const data = await callable();
    console.log('data', data);
    return data?.data?.time;
  } catch (e) {
    console.log('Error en TestServerTimestamp', e);
    // No requiere reporte de error
    // reportError('cms', {}, 'getServerTimestamp', e);
    return new Date().getDate();
  }
};
