export default {
  buttonDate: 'Agendar cita',
  buttonProfile: 'Ver más terapeutas',
  meet: 'Conócelos:',
  title: 'Agenda con un terapeuta',
  typeTherapy: 'Especialidad',
  reasonTherapy: 'Motivo de consulta',
  notFound:
    'Ninguno de nuestros terapeutas coincide con esas características. Intenta con otras. 🙂',
  therapyHeader: 'Terapia digital',
  allFilters: 'Todos',
  specialties: 'Especialidades',
  profileTherapist: 'Perfil del terapeuta',
  appointment: 'Agendar una cita',
  timeSelection: 'Selección de horario',
  timeZone: 'Zona horaria:',
  availableHour: 'Horarios disponibles',
  detailsAndPayment: 'Detalles y pago de cita',
  detailsAndConfirm: 'Detalles y confirmación de cita',
  psychologist: 'Psicólogo(a)',
  dateAndTime: 'Fecha y hora',
  amountToPay: 'Monto a pagar',
  durationTime: 'Tiempo de duración',
  schedule: 'Agendar',
  makePayment: 'Realiza tu pago',
  pay: 'Pagar',
  therapyScheduled: 'Terapia agendada',
  confirmAppointment: '¡Tu cita ha sido confirmada!',
  details: 'Detalles de tu cita 📅',
  addCalendar: 'Agregar a calendario',
  backHome: 'Regresar a home',
  reminderTomorrow: '¡Tu cita es el día de mañana! 😊',
  reminder2Days: '¡Tu cita es dentro de 2 días! 🌻',
  reminderToday: '¡Hoy es el día de tu cita! 🌈',
  reminderTitle: 'Recordatorio de tu cita 🔔',
  myTherapy: 'Mis terapias',
  myTherapyDesc:
    'El autocuidado comienza aquí. ¡Da el primer paso hacia tu bienestar!',
  noTherapies:
    'Parece que no tienes ninguna terapia agendada. ¿Por qué no visitas a nuestros terapeutas para empezar a cuidar tu bienestar emocional? 💫',
  seeTherapist: 'Ver terapeutas',
  nextTherapy: 'Próximas citas',
  pastTherapy: 'Pasadas',
  availableAppointment:
    '¡Aún cuentas con {{appointments}} citas disponibles! 🌈',
  manualBanner:
    'Sigue disfrutando de tus terapias y contrata un nuevo paquete.',
  scheduleButton: 'Agendar',
  noAvailability: 'No hay horarios disponibles por el momento.',
  confirmation: 'Confirmar',
  saveSuccess: 'Cita añadida a tu calendario con éxito.',
  titleEvent: 'Mindsurf: Sesión de terapia.',
  notesEvent:
    'Sigue el link para entrar a tu sesión de terapia. Puedes acceder a tu terapia desde la app de Mindsurf.',
  notesEventAndroid:
    'Sigue el link para entrar a tu sesión de terapia. Puedes acceder a tu terapia desde la app de Mindsurf. {{link}}',
  therapyDetail: 'Terapia individual · {{minutes}} minutos',
  detailsTitle: 'Terapia individual',
  detailsSubLabel: 'Sesión · {{minutes}} minutos',
  description: 'Descripción',
  rulesTherapy: 'Reglas para tomar terapia',
  rulesCancel: '¿Necesitas reagendar o cancelar?',
  startTherapy: 'Iniciar mi terapia',
  titleTherapySession: 'Terapia',
  webNotice: 'El botón se habilitará cuando sea el momento de tu terapia.',
  timeSession: '50',
  sessionDescription:
    '¡Bienvenido/a a tu sesión de terapia! Con el apoyo de nuestros psicólogos capacitados, exploraremos juntos tus emociones y desafíos personales para mejorar tu bienestar emocional y alcanzar tus metas.',
  noAppointments:
    'No hay citas próximas. Explora y agenda con nuestros terapeutas.',
  noPastAppointments: 'Por el momento, no hay citas pasadas registradas.',
  rhAdvice:
    'Parece que no cuentas con citas disponibles. ¿Por qué no contactas con Recursos Humanos para obtener más información?',
  ruleTherapy:
    '1. La tolerancia son 15 minutos para ambas partes, si después de este tiempo no se conectan la sesión se da por dada.',
  ruleTherapy2:
    '2. Las sesiones con cada uno de nuestros especialistas es totalmente confidencial.',
  ruleReschedule:
    '1. Para reagendar o cancelar alguna sesión es con 24 hrs de anticipación por respeto al tiempo de los especialistas, de no ser así la sesión se toma como dada.',
  ruleReschedule2:
    '2. Avisar al contacto de hello@mindsurf.ai sobre algún contratiempo, para reagendar o cancelar alguna sesión.',
  PAID_NOT_BOOKED: 'Pendiente de reservar',
  BOOKED: 'Reservada',
  CANCELLED_THERAPIST: 'Cancelada por terapeuta',
  CANCELLED_USER: 'Cancelada por usuario',
  TAKEN: 'Completada',
  LOST: 'Perdida',
  noTimezones:
    'No se encontraron resultados para tu búsqueda. Por favor, intenta con otras palabras.',
  searchTimeZone: 'Buscar zona horaria.',
  errorGeneral:
    'Hubo un error al intentar agendar tu cita. Por favor, inténtalo de nuevo o contacta con nosotros en hello@mindsurf.ai',
  errorOpenPayment:
    'Ocurrió un error al confirmar tu cita. Por favor, inténtalo de nuevo más tarde.',
  errorAgenda:
    'La fecha y hora seleccionada no está disponible, la reservación se debe hacer al menos 24 horas antes.',
  erroAvailable:
    'No tienes sesiones pagadas o gratuitas disponibles. Por favor, adquiere una sesión para continuar.',
  errorNotFound:
    'La fecha y hora seleccionada no están disponibles. Intenta seleccionar otra fecha y hora.',
  notFoundAppointment:
    'Esta cita no se encontró o ya caducó. Por favor, verifica los detalles de tu cita y vuelve a intentarlo. Si el problema persiste, contacta con nuestro soporte.',
  popUpNamesNull:
    'Es necesario completar la información de tu perfil para agendar una terapia.',
  popUpButton: 'Ir a mi perfil',
  popUpAnonymous: 'Es necesario crear una cuenta para agendar una terapia.',
  popUpLogin: 'Ir a registro',
  therapistNull:
    'En este momento no hay citas disponibles, pero puedes intentar con otro terapeuta para encontrar una opción que se adapte a ti. 😊',
};
