/* istanbul ignore file */
import {Center, Image} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import {isWeb} from '../../helpers/utils/utils';
import images from '../../res/images';
import Send from '../../res/svgs/send.svg';

/**
 * Componente  Again-icon normal
 */

const SendIcon = ({colour = 'white', h = 20, w = 20}) => {
  const {sendIcon} = images;
  if (isWeb()) {
    return (
      <Center>
        <Image src={sendIcon} resizeMode={'stretch'} h={30} w={30} />
      </Center>
    );
  }

  return (
    <Center>
      <Send width={h} height={w} fill={colour} />
    </Center>
  );
};

SendIcon.propTypes = {
  colour: PropTypes.string,
};
export default SendIcon;
