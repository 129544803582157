export default {
  // title: 'Elige un camino\npara comenzar\ntu viaje',
  title: 'Terapia digital',
  programTitle: 'Terapia digital para la {{name}}',
  programTitleForNavbar: 'Programa de $program',
  buttonStart: 'Comenzar programa',
  buttonStartV2: 'Comenzar',
  buttonStartSession: 'Comenzar sesión {{session}}',
  buttonContinue: 'Continuar programa',
  buttonNextEvaluation: 'Continuar con evaluación de salida',
  buttonContinueV2: 'Continuar',
  buttonNextSession: 'Iniciar siguiente sesión',
  buttonNextActivity: 'Continuar',
  buttonHome: 'Ir al inicio',
  buttonNext: 'Continuar',
  buttonAgain: 'Tomar el programa de nuevo',
  buttonSOSMindsurf: 'Llamar a linea de ayuda\nemocional',
  buttonSOSContact: 'Llamar a tu contacto de\nemergencia',
  buttonSkip: 'Prefiero no hacerlo',
  buttonShareImage: 'Compartir foto',
  buttonReadMore: 'Leer más',
  min: '$min minutos',
  shortMin: '{{min}} MIN.',
  labelTherapy: 'Comienza\nuna terapia con \nun especialista',
  buttonStartExercise: 'Comenzar',
  progressGridTitle: 'Registro de tu progreso',
  achievementsTitle: 'Logros',
  achievementsRegisterPrefix: 'Registro del',
  achievementsSessions: '{{number}} sesiones terminadas',
  achievementsSessionsOne: '{{number}} sesión terminada',
  achievementsSessionsDsc: 'terminadas',
  achievementsSessionsDscOne: 'terminada',
  achievementsPoints: '$number puntos',
  achievementsPointsDsc: 'acumulados',
  achievementsDsc:
    'Revisa el resultado de cada sesión y continúa con el programa.',
  noAchievements: 'Aquí podrás ver las misiones que cumpliste en cada sesión.',
  sessionLabel: 'Sesión {{number}}',
  labelFinishSession:
    '¡Terminaste la sesión {{number}}\ndel programa de {{program}}!',
  labelEndSession: '¡Terminaste la sesión {{number}}!',
  labelFinishProgram: '¡Terminaste el programa de {{program}}!',
  depressionDailyTitle: 'Tus actividades',
  depressionDailyDescription:
    'Marca cuáles has cumplido y pregúntate cuáles han sido las más difíciles de cumplir.',
  depressionDailyListAgreements: 'Actividades compromiso:',
  depressionDailyListOthers: 'Otras actividades:',
  timerWidget: {
    title: 'Caminata',
    description: 'Restantes',
  },
  timerCheckList: {
    title: 'Cosas por identificar:',
  },
  agreement: {
    instructions: {
      title: 'Completa el pacto',
      description:
        'Toca los espacios en blanco para rellenar y firma con el dedo al final.',
    },
    widget: {
      title: 'Pacto conmigo',
      text1: 'Yo,',
      text2: 'al timón de esta nave, me comprometo a agregar',
      text3: 'Selecciona 4 actividades',
      text4:
        'a mi lista de actividades porque serán importantes para encontrar el cofre del tesoro.',
      text5: 'Dibuja tu firma con tu dedo.',
      signatureAltImage: 'Firma',
    },
    modal: {
      title: 'Actividades',
      description: 'Selecciona cuatro actividades para tu pacto.',
      accept: 'Aceptar',
    },
  },
  permissionCamera: {
    accept: 'Aceptar',
    cancel: 'Cancelar',
    title: 'Permiso de cámara',
    message: 'Se requiere acceso a la cámara para capturar fotos',
    noAccess: 'Sin acceso a la cámara',
    noPermission: 'No se ha otorgado permiso para acceder a la cámara.',
  },
  cont: {
    startCont: 'Iniciar contador',
    pauseCont: 'Pausar contador',
  },
  skip: 'Saltar ejercicio',
  changeRiskLevel: {
    title: 'Cambio de programa',
    desc: 'Actualmente estas cursando el ',
    descBold:
      'Programa de {{variableName}} para nivel de atención {{riskLabel}}.',
    question: '¿Quieres cancelar dicho programa para comenzar el ',
    questionBold:
      'Programa de {{variableName}} para nivel de atención {{riskLabel}}?',
    buttonOk: 'Cancelar programa actual y comenzar nuevo',
    buttonCancel: 'Continuar con mi programa actual',
  },
  selectOption: 'Selecciona una opción',
  buttonIRatherNot: 'Prefiero no compartir',
  pressForRecord: 'Deja presionado para grabar audio',
  titleHome: 'Terapia digital',
  descriptionHome:
    'Sesiones psicológicas diarias para que mejores en tu salud emocional y mental.',
  benefitsTitle: '¿Qué beneficios puedes tener?',
  subscriptionButton: 'Ver planes de suscripción',
  sessions: 'Sesiones',
  evaluation: 'Evaluación',
  sessionNumberExplanation:
    'El número de sesiones depende del resultado de tu evaluación.',
  evaluationDescription:
    'Para personalizar tu terapia, contesta la evaluación. ¡Te toma unos minutos!',
  nextSession: 'Siguiente sesión',
  iDontWantToContinue: 'Ya no quiero cursar esta terapia',
  cancelTherapy: 'Cancelar terapia',
  cancelTherapyDescription:
    'Tu progreso se borrará y será necesario que comiences de nuevo.',
  cancelTherapyButton: 'Cancelar terapia',
  sessionLastDateLabel: 'Sesión realizada el {{date}}:',
  evaluationLastDateLabel: 'Evaluación realizada el {{date}}:',
  startSessionNumber: 'Iniciar sesión {{number}}',
  continueSessionButton: 'Continuar con siguiente sesión',
  backToHome: 'Regresar al inicio',
  finishTherapyTitle: 'Terminaste el {{date}}',
  finishTherapyDescription:
    'Puedes volver a tomar esta terapia.\n\nEste registro se guardará y podrás verlo una vez que vuelvas a terminar.',
  buttonStartAgain: 'Comenzar de nuevo',
  endEvaluation: 'Evaluación de salida',
  openRegister: 'Ver registro',
  thanks: 'Gracias',
  comment: 'Con tus comentarios podré mejorar y te daré un apoyo más adecuado.',
  send: 'Enviar',
  continueProgram: 'Sigue con tu terapia 💫',
  seeReport: 'Ver reporte',
  seeActivity: 'Ver actividad',
  seeAll: 'Ver más',
  sessionNumber: 'Sesión {{number}}',
  endSession: 'Terminar sesión',
};
