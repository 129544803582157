import React from "react";
import {
  AiOutlineExclamationCircle,
  AiOutlineMenu,
  AiOutlineSmile,
  AiOutlineSetting,
  AiOutlineRead,
  AiOutlineQuestionCircle,
  AiOutlineLogout,
  AiFillLeftCircle,
  AiOutlineLeft,
  AiFillRightCircle,
  AiOutlineRight
} from "react-icons/ai";

/**
 * Componente  Icon.mobile normal
 */

const Icon = ({ name, size, style, color }) => {
  const styles = {
    fontSize: size >= 30 && size <= 50 ? "3rem" : size,
    ...style
  };
  const props = {
    style: styles,
    color
  };


  switch (name) {
    case "exclamationcircleo":
      return <AiOutlineExclamationCircle {...props} />;
    case "menu":
      return <AiOutlineMenu {...props} />;
    case "smile":
      return <AiOutlineSmile {...props} />;
    case "theme":
      return <AiOutlineRead {...props} />;
    case "help":
      return <AiOutlineQuestionCircle {...props} />;
    case "setting":
      return <AiOutlineSetting {...props} />;
    case "logout":
      return <AiOutlineLogout {...props} />;
    case "profile":
      return <AiOutlineSmile {...props} />;
    case "leftcircleo":
      return <AiFillLeftCircle {...props} />;
    case "rightcircleo":
      return <AiFillRightCircle
        {...props} />;
    default:
      return <AiOutlineExclamationCircle {...props} />;
  }
};


export default Icon;
