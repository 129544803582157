export default {
  title: 'Digital Therapy',
  programTitle: 'Digital therapy for {{name}}',
  programTitleForNavbar: 'Digital Therapy for $program',
  buttonStart: 'Start program',
  buttonStartV2: 'Start',
  buttonContinue: 'Start program',
  buttonContinueV2: 'Continue',
  buttonNextEvaluation: 'Continue with exit evaluation',
  buttonNextSession: 'Start next session',
  buttonNextActivity: 'Continue',
  buttonHome: 'Return to Home',
  buttonNext: 'Continue',
  buttonAgain: 'Take it again',
  buttonSOSMindsurf: 'Call support line',
  buttonSOSContact: 'Talk to your emergency contact',
  buttonSkip: 'I’d rather not',
  buttonShareImage: 'Upload my photo',
  buttonReadMore: 'Read more',
  min: '$min minutes',
  shortMin: '{{min}} MIN.',
  labelTherapy: 'Start therapy with a specialist:',
  buttonStartExercise: 'Start',
  progressGridTitle: 'Record of your progress',
  achievementsTitle: 'Achievements',
  achievementsRegisterPrefix: 'Record of ',
  achievementsSessions: '{{number}} sessions finished',
  achievementsSessionsOne: '{{number}} session finished',
  achievementsSessionsDsc: 'finished',
  achievementsSessionsDscOne: 'finished',
  achievementsPoints: '$number points',
  achievementsPointsDsc: 'gained',
  achievementsDsc:
    'Check the results at the end of each session and continue with the program.',
  noAchievements:
    'Here you’ll be able to see the missions you’ve completed after each session.',
  sessionLabel: 'Session {{number}}',
  labelFinishSession:
    'You’re done with session {{number}} of the {{program}} program!',
  labelEndSession: 'You’re done with session {{number}}!',
  labelFinishProgram: 'You finished the {{program}} program!',
  depressionDailyTitle: 'Your activities',
  depressionDailyDescription:
    'Tap on the activities you have completed and reflect on which have been the most difficult to carry out.',
  depressionDailyListAgreements: 'Activities of the pact:',
  depressionDailyListOthers: 'Other activities:',
  timerWidget: {
    title: 'Walk',
    description: 'left',
  },
  timerCheckList: {
    title: 'Things to look for::',
  },
  agreement: {
    instructions: {
      title: 'Fill out the pact',
      description:
        'Tap on the blank spaces to fill them out and draw your signature at the end.',
    },
    widget: {
      title: 'Pact with myself',
      text1: 'I,',
      text2: 'captain of this ship, commit to do these activities:',
      text3: 'Choose 4 activities',
      text4:
        'because these activities will be important to restore my wellbeing:',
      text5: 'Draw your signature with your finger.',
      signatureAltImage: 'Sign',
    },
    modal: {
      title: 'Activities',
      description: 'Choose 4 activities for your pact.',
      accept: 'Accept',
    },
  },
  permissionCamera: {
    accept: 'Accept',
    cancel: 'Cancel',
    title: 'Camera Permission',
    message: 'Access to the camera is required to capture photos.',
    noAccess: 'No access to the camera',
    noPermission: 'Permission to access the camera has not been granted.',
  },
  cont: {
    startCont: 'Start counter',
    pauseCont: 'Pause counter',
  },
  skip: 'Skip exercise',
  changeRiskLevel: {
    title: 'Change of program',
    desc: "You're currently taking the ",
    descBold: '{{variableName}} for the {{riskLabel}} risk level.',
    question: 'Would you like to cancel said program to start the ',
    questionBold:
      '{{variableName}}  program, for the {{riskLabel}} risk level?',
    buttonOk: 'Cancel current program and start new one',
    buttonCancel: 'Continue with my current program',
  },
  selectOption: 'Select an option',
  buttonIRatherNot: 'I’d rather not',
  pressForRecord: 'Press and hold to record',
  titleHome: 'Digital therapy',
  descriptionHome:
    'Daily psychological sessions to improve your emotional and mental health.',
  subscriptionButton: 'See subscription options',
  benefitsTitle: 'What benefits can you have?',
  sessions: 'Sessions',
  evaluation: 'Evaluation',
  sessionNumberExplanation:
    'The number of sessions depends on the result of your assessment.',
  evaluationDescription:
    ' To customize your care plan, answer this questionnaire. It only takes a few minutes!',
  nextSession: 'Next session',
  iDontWantToContinue: 'I no longer want to attend this therapy.',
  cancelTherapy: 'Cancel therapy',
  cancelTherapyDescription:
    'Your progress will be erased and it will be necessary for you to start over.',
  cancelTherapyButton: 'Cancel therapy',
  sessionLastDateLabel: 'Session held on {{date}}:',
  evaluationLastDateLabel: 'Evaluation held on {{date}}:',
  startSessionNumber: 'Start session {{number}}',
  continueSessionButton: 'Continue to next session',
  backToHome: 'Back to home',
  finishTherapyTitle: 'You finished on {{date}}',
  finishTherapyDescription:
    'You can take this therapy again.\n\nThis record will be saved and you can view it once you finish again.',
  buttonStartAgain: 'Start again',
  endEvaluation: 'Exit Evaluation',
  openRegister: 'View record.',
  thanks: 'Thanks',
  comment:
    'I’ll be able to improve and give you more appropriate support with your feedback.',
  send: 'Send',
  continueProgram: 'Keep up with your therapy 💫',
  seeAll: 'Show more',
  seeActivity: 'See activity',
  buttonStartSession: 'Start session {{session}}',
  sessionNumber: 'Session {{number}}',
  endSession: 'End session',
};
