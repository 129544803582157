import {colors} from './colors';
import {fonts} from './fonts';

export const phoneCountry = {
  containerOut: {
    borderWidth: 1,
    borderColor: colors.purpleGray,
    borderRadius: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  containerIn: {
    width: '35%',
  },
  containerInWeb: {
    width: '20%',
  },
  errorMessage: {
    marginTop: 2,
    fontSize: '12@ms',
    color: colors.negative1,
    fontFamily: fonts.quicksand,
  },
  touchCont: {
    borderLeft: 0,
    padding: 7,
    height: 56,
    borderWidth: 1,
    borderColor: colors.purpleGray,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontFamily: fonts.quicksand,
    backgroundColor: colors.secondary100,
    borderRightWidth: 0,
  },
  buttonStyle: {
    fontSize: 10,
    padding: 5,
    fontWeight: 'bold',
    fontFamily: fonts.quicksand,
  },
  icon: {
    color: 'black',
    width: '25%',
    alignSelf: 'center',
  },
  input: {
    backgroundColor: colors.white,
    width: '75%',
    fontSize: 15,
    padding: 7,
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
    fontFamily: fonts.quicksand,
  },
};
