export default {
  diaryOfEmotions: 'Emotional journal',
  chatDiary: 'Written diary of Emotions',
  days: {
    monday: 'M',
    tuesday: 'T',
    wednesday: 'W',
    thursday: 'T',
    friday: 'F',
    saturday: 'S',
    sunday: 'S',
  },
  daysHistorial: {
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'Su',
  },
  months: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  buttonAlwaysShow: 'ALWAYS SHOW',
  buttonIDK: 'I don’t know',
  close: 'CLOSE',
  continue: 'Continue',
  continueWithExtendedDiary: 'Continue writing as you feel',
  diary: 'Diary',
  emotional: 'Emotional',
  emotionalDiary: 'Diary of Emotions',
  emotionalRegister: 'Registry of emotions',
  explanation:
    'In this diary you will be able to express your emotions and thoughts, and I will be able to analyze your mood to give you recommendations.',
  extendedConversation: 'See complete entry',
  history: 'History',
  home: 'End chat',
  iThinkIFeel: 'I feel',
  labelInterested: 'I recommend...',
  labelRegisterChat: `You’ve tracked your emotions!`,
  labelRegisterToday: `You’ve tracked your emotions!`,
  labelSelect: 'Choose your current emotional state:',
  messageAfterRegister: 'You logged in your mood today!',
  newEmotionalRegister: 'New emotional register',
  noHistory: "You still don't have Emotional Diary History",
  numberSteps: '{{currentStep}} to {{totalStep}}',
  points: ' points',
  recommendations: 'I recommend...',
  register: 'This week, you have $1 entry.',
  registers: 'This week, you have $1 entries.',
  seeAll: 'SEE ALL',
  seeEmotions: 'see emotions',
  seeHistory: 'See History',
  seeResult: 'See result',
  selectPeriodPlaceholder: 'Last month',
  shortStep1: 'What does it make you feel?',
  shortStep2: 'What emotions do you think you’re feeling?',
  showOthers: 'Show others',
  step1: 'Which emotion are you feeling today?',
  step2: 'Which word best describes how you feel?',
  title: 'Emotional journal',
  todayIFeel: 'My emotion',
  understandEmotion: 'Written Diary of Emotions...',
  write: 'Written Diary of Emotions...',
  primaryTitle: 'What does it make you feel?',
  secondaryTitle: 'What emotions do you think you’re feeling?',
  itMakesMeFeel: 'It makes me feel::',
  relatedWith: 'Related to {{emotion}}',
  titleHome: 'How are you feeling today?',
  emotions: 'Emotions',
  historyDescription:
    'The days on the calendar show the latest record of the day in primary emotion.',
  writeDiary: 'Write on your journal',
  diaryIntro:
    'This is your space for identifying, exploring, and tracking your daily moods. Every emotion is valid!',
  noRegister: 'You didn’t track your emotions that day.',
  buttonRegisterEmotions: 'Log emotion',
  iDontKnowLabel:
    "If you don't know how you're feeling, you can get help from the emotional assistant.",
  writingLabel: 'Dive deeper into why you feel this way',
  optional: 'Optional',
  writingDescription:
    'Describe the situation that made you have these feelings or leave a note on your emotions.',
  today: 'Today',
  backHome: 'Go back to the home',
  page: 'Page {{current}} of {{total}}',
  goAssistant: 'Go to the Emotional Assistant',
  openAssistantButton: 'Open emotional assistant',
};
