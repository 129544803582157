/** Menu que muestra el  menú del CHAT*/
import PropTypes from 'prop-types';
import React, {useState, useContext, useEffect} from 'react';
import {colors} from '../styles/colors';
import MenuButton from './MenuButton';
import MenuChips from './MenuChips';
import {useTranslation} from 'react-i18next';
import {Box, HStack, Center, ScrollView} from 'native-base';
import {getWidthAndHeight} from '../helpers/utils/utils';
import {RemoteConfigContext} from '../hooks/SessionContext';
import {navigateTo} from '../navigation/MainNavigator';
import {ROUTE_NAMES} from '../navigation/MainNavigator';

/**
 * Este componente no es muy utilizado y posiblemente se desechará en el nuevo UI
 * @param user información del usuario
 * @param setCommand evento cuando selecciona un comando
 * @param show evento para mostrar el menu hacia arriba
 * @returns component
 */

const ChatMenu = ({
  user,
  setCommand,
  show,
  featureFlagEvaluations = false,
  setFeatureFlagMenu = () => {},
  webNomId,
  hideOptionMenu,
}) => {
  const [menu, setMenu] = useState(true);
  const [filteredMenu, setFilteredMenu] = useState();
  const {t} = useTranslation();
  const {width} = getWidthAndHeight();
  const {
    remoteConfig: {webConfig},
  } = useContext(RemoteConfigContext);

  useEffect(() => {
    const menuFilter = webConfig.menu.filter(
      item => item.title !== hideOptionMenu,
    );

    setFilteredMenu(menuFilter);
  }, []);

  /*
    * - [ ]
      - Cerrar sesión
      - Ayuda
      - Registrar estado de ánimo
      - NOM - 035 NOM NV día 1
    *
    * */

  return (
    <ScrollView w={'100%'} flex={1}>
      {menu ? (
        <Box w={'100%'}>
          <HStack
            px={5}
            w={'100%'}
            flex={1}
            alignItems={'flex-start'}
            justifyContent={'space-between'}>
            {(filteredMenu?.length === 0 ? webConfig?.menu : filteredMenu)?.map(
              (item, index) => (
                <Center key={('item-', item?.icon)} w={'1/5'}>
                  <MenuButton
                    key={index}
                    color={colors.tertiary100}
                    icon={item.icon}
                    caption={item.title}
                    onPress={() => {
                      console.log('item?????', item);
                      if (item?.action === 'profile') {
                        setMenu(false);
                        return;
                      }

                      if (item?.title === 'Nom035' && featureFlagEvaluations) {
                        setFeatureFlagMenu(false);
                        navigateTo(ROUTE_NAMES.allTools, {
                          id: webNomId,
                          setFeatureFlagMenu,
                        });
                        return;
                      }

                      setCommand(item.action);
                    }}
                  />
                </Center>
              ),
            )}
          </HStack>
          {/*<HStack mt={5} w={'100%'} flex={1} justifyContent={'space-between'}>*/}
          {/*  <Center w={'1/2'}>*/}
          {/*    <MenuButton*/}
          {/*      color={colors.secondary100}*/}
          {/*      icon="theme"*/}
          {/*      caption={t('chat:chooseTheme')}*/}
          {/*      onPress={() => {*/}
          {/*        // show('200');*/}
          {/*        setMenu(false);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Center>*/}
          {/*  <Center w={'1/2'}>*/}
          {/*    <MenuButton*/}
          {/*      color={colors.secondary100}*/}
          {/*      icon="setting"*/}
          {/*      caption={t('chat:settings')}*/}
          {/*      onPress={() => setCommand('/lcf Ajustes')}*/}
          {/*    />*/}
          {/*  </Center>*/}
          {/*</HStack>*/}
        </Box>
      ) : (
        <MenuChips
          userId={user?.userFB?.uid}
          onMenuButtonClick={command => {
            setMenu(true);
            console.log('commaaa', command);
            setCommand(command);
          }}
          onClickBack={() => setMenu(true)}
        />
      )}
    </ScrollView>
  );
};

ChatMenu.propTypes = {
  user: PropTypes.object.isRequired,
  setCommand: PropTypes.func.isRequired,
  show: PropTypes.func,
};

export default ChatMenu;
