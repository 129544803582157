/**
 * Archivo de configuración de Idioma. Por default Español
 */
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as RNLocalize from 'react-native-localize';

import en from './translations/en';
import es from './translations/es';
// import {findBestAvailableLanguage} from "react-native-localize";

const LANGUAGES = {
  es,
  en,
};

export const LANG_CODES = Object.keys(LANGUAGES);

const LANGUAGE_DETECTOR = {
  type: 'languageDetector',
  async: true,
  detect: callback => {
    AsyncStorage.getItem('user-language', (err, language) => {
      // if error fetching stored data or no language was stored
      // display errors when in DEV mode as console statements
      console.log('🐨', language);
      if (err || !language) {
        if (err) {
          console.error('Error fetching Languages from asyncstorage ', err);
        } else {
          console.info('No language is set, choosing Spanish as fallback');
        }
      }
      let langChoice = language; // todo: aqui es language en vez de es

      if (!language || (language !== 'es' && language !== 'en')) {
        const findBestAvailableLanguage =
          RNLocalize.findBestAvailableLanguage(LANG_CODES);
        langChoice = findBestAvailableLanguage?.languageTag;
      }
      callback(langChoice);
    });
  },
  init: () => {},
  cacheUserLanguage: language => {
    // TODO: avoid this when system choice is enabled
    //AsyncStorage.setItem('user-language', language);
  },
};

i18n
  // detect language
  .use(LANGUAGE_DETECTOR)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    // defaultLocale: 'es-ES',
    compatibilityJSON: 'v3',
    resources: LANGUAGES,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'common',
  });
