import React from 'react';
import {Text, Modal, Button, Center} from 'native-base';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {isWeb} from '../helpers/utils/utils';

/**
 * Modal sobre pausar la evaluación
 * @param showModal if is open
 * @param draftEvaluation evento al dar pausar
 * @param setShowModal Open o close el modal evento
 */

const ModalEvaluationPause = ({showModal, draftEvaluation, setShowModal}) => {
  const {t} = useTranslation();

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      _backdrop={{opacity: 0.65}}>
      <Modal.Content bg={'secondary.100'} borderColor={'primary.5'}>
        <Modal.Body p={5}>
          <Text
            my={3}
            textAlign="center"
            color="primary.100"
            fontSize={'lg'}
            fontWeight={800}>
            {t('evaluations:pauseTitle')}
          </Text>
          <Text fontSize={'lg'} color="primary.100">
            {t('evaluations:pauseDsc')}
          </Text>
        </Modal.Body>
        <Modal.Footer bg={'secondary.100'}>
          <Center
            flex={1}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            alignContent={'space-between'}>
            <Button
              testID="btn-cancel"
              variant={'ghost'}
              _text={{
                fontWeight: 800,
                fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
                color: 'primary.100',
              }}
              _pressed={{bg: 'transparent'}}
              onPress={() => setShowModal(false)}>
              {t('buttons:cancel')}
            </Button>
            <Button
              bg={'primary.100'}
              _text={{
                color: 'secondary.100',
                fontWeight: 800,
                fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
              }}
              testID={'modalE-id'}
              _pressed={{bg: 'tertiary.200'}}
              rounded={'full'}
              onPress={draftEvaluation}>
              {t('buttons:pause')}
            </Button>
          </Center>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

ModalEvaluationPause.propTypes = {
  showModal: PropTypes.bool.isRequired,
  draftEvaluation: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
export default ModalEvaluationPause;
