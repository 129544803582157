import React from 'react';
import {Box, Center, Circle, Image, Modal, Text, View} from 'native-base';
import images from '../../res/images';
import PropTypes from 'prop-types';
import {isWeb} from '../../helpers/utils/utils';

const CoachModal = ({coach, showModal, setShowModal}) => {
  const {meditation} = images;
  const styles = {
    modalContent: {
      borderRadius: 30,
    },
  };

  if (!showModal) return <></>;

  return (
    <Modal
      testID="modal-coach"
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      _backdrop={{opacity: 0.3}}
      size={'lg'}>
      <Modal.Content
        borderColor={'primary.5'}
        style={styles.modalContent}
        w={'90%'}>
        <Modal.CloseButton
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
          testID="close-emotion-desc"
        />

        <Modal.Body>
          <Box flexDirection={'row'}>
            <Center w={'25%'}>
              <Circle size="md">
                <Image
                  testID={'coach-image'}
                  size={'100%'}
                  resizeMode={'cover'}
                  source={
                    coach.therapistPhoto
                      ? {
                          uri: coach.therapistPhoto,
                        }
                      : meditation
                  }
                  alt={'Mindsurf'}
                  style={{borderRadius: 200}}
                />
              </Circle>
            </Center>
            <Box w={'80%'} pl={3}>
              <Text
                textAlign={'left'}
                numberOfLines={2}
                fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
                fontSize={'md'}
                fontWeight={800}
                color={'primary.100'}>
                {coach?.therapistName}
              </Text>
              <Text
                pt={1}
                fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
                textAlign={'left'}
                fontWeight={800}
                fontSize={'sm'}
                opacity={0.5}
                color={'primary.60'}>
                {coach?.approach ? coach?.approach : ''}
              </Text>
            </Box>
          </Box>
          <Text
            frontFamily={'fonts.Quicksand'}
            fontSize={'sm'}
            padding={3}
            color={'tertiary.130'}>
            {coach.therapistDescription}
          </Text>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const CoachDataType = {
  therapistName: PropTypes.string.isRequired,
  therapistDescription: PropTypes.string,
  therapistPhoto: PropTypes.string,
  approach: PropTypes.string,
};

CoachModal.propTypes = {
  coach: PropTypes.objectOf(PropTypes.shape(CoachDataType)).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CoachModal;
