export default {
  buttonContinue: 'Continue',
  buttonSave: 'Save',
  buttonConfirm: 'Confirm',
  buttonCreateAccount: 'Get started',
  buttonLogin: 'Log in',
  buttonNext: 'NEXT',
  buttonRecover: 'Send',
  callCodeLabel: 'CALL ME',
  codeEmailLabel: 'Check your inbox and spam folder.',
  codeSMSLabel: 'We sent a verification code to the number.',
  codeSMSResetLabel:
    'Check your messages. You will receive an SMS to your number.',
  createAccountButton: ' Create an account',
  register: 'Register',
  writeCode: 'Type the code',
  createAccountButtonOr: 'Or create an account',
  youBackTitle: "You're back!",
  createAccountTitle: 'Create an account',
  emailCodeAgain: 'Send code again',
  employeeLoginHelperTitle: 'Having trouble logging in?',
  employeeLoginHelper:
    'If you don’t know your number, please contact your company’s Human Resources. We’ll be waiting for you!',
  forgotPasswordLink: 'Did you forget your password?',
  inputCode: 'Write the code',
  inputCodeDiscount: 'I have business access',
  inputCodeDiscountLabel: 'Write your policy number or code',
  inputCodeHelper: "Didn't receive it?",
  inputCodeLabel: 'If you have problems with the code, write us at ',
  inputCorporateAccess: 'I have corporate access',
  inputEmail: 'Please provide your email.',
  inputEmailInvalid: 'The email is invalid.',
  inputEmployeeNumber: 'Write your employee number.',
  inputEmployeeNumberInvalid: 'The employee number is invalid.',
  inputName: 'Write your full name.',
  inputNameInvalid: 'The name is invalid.',
  inputNumberInvalid: 'The phone number is invalid',
  inputPassword: 'New password',
  inputPasswordLogin: 'Password',
  inputPasswordAgain: 'Confirm your new password.',
  inputPasswordAgainInvalid: 'The password must match.',
  inputPasswordInvalid:
    'It should be at least 8-characters long, including one uppercase, a number, and a special character.',
  inputPhoneHelper: 'You will receive a confirmation code.',
  inputPhoneNumber: 'Phone number.',
  inputPoliceNumber: 'Company insurance number',
  labelLoginEmail: 'login with your email',
  labelLoginEmployee: 'Login with your employee number',
  labelLoginPhone: 'login with your phone number',
  labelSignUpEmail: 'Sign up with your email',
  labelSignUpPhone: 'Sign up with your phone number',
  loginAccount: 'or log in',
  loginAccountLower: 'Log in',
  mail: 'Email',
  newPasswordButton: 'Change',
  newPasswordLabel: 'Create a new password',
  descNewPassword: "Write it down somewhere safe so you don't forget 😉",
  phone: 'Phone',
  recoverLabelEmail:
    'Write down your email so we can send you a recovery code.',
  recoverLabelPhone: 'Type your phone number to receive an SMS recovery code.',
  recoverPassword: "Let's regain your access!",
  titleNewPassword: 'Change password',
  spamLabel: 'or check your spam folder',
  whatsappCodeLabel: 'Receive code through WhatsApp',
  notRegisterPhone: "Perhaps you didn't register with your phone number",
  notRegisterEmail: "Perhaps you didn't register with your email",
  recoverWhitEmail: 'Regain access through email',
  recoverWhitPhone: 'Regain access through SMS',
  wa: 'I have sent you a verification code via Whatsapp. Please give it a minute.\n',
  call: 'I’m getting in touch with you. Please give me a moment.',
  isRegisterDes:
    'Login with your phone number. If you don’t remember your password, you can reset it.',
  isRegisterEmailDes:
    'Login with your email. If you don’t remember your password, you can reset it.',
  isRegisterTitle: 'The info you provided matches an existing account!',
  emailCode: 'I’ve sent a verification code via email to reset your password',
  emailCodeTitle: 'I’ve sent your verification code!',
  smsRecovery: 'I’ve sent a verification code via SMS to reset your password',
  emailRecovery:
    'I’ve sent a verification code via email to reset your password',
  termsAndConditions: {
    accept: 'I agree to ',
    termsAndConditions: 'Mindsurf’s terms and conditions',
    consentOfInteraction: 'Consent to Interaction',
    and: ' and ',
    privacyNotice: 'privacy notice.',
  },
  codeConfirmation: 'You will receive a confirmation code',
  ready: 'It’ll take a few seconds.',
  goodSeeYou: 'So glad to have you here.',
  recoverAccess: 'Reset password',
  wait: 'Wait ',
  sendCodeEmail: 'Check your inbox and spam folder.',
  sendCodePhone: 'You’ll get an SMS text',
  sendCodeEmailModal: 'You will get an email to',
  sendCodePhoneModal: 'Shortly you’ll get an SMS to',
  recoverPass: ' to change your password.',
  sendOtherCodeEmail: 'You will receive another email to',
  sendCodeWha: 'You will receive a Whatsapp message to',
  sendCodeEmailRegister: 'You will receive an email at',
  sendCodePhoneRegister: 'You will receive an SMS at',
  verifyAccount: 'to verify your account.',
  acceptTerms: 'You must accept the terms and conditions.',
  errorB2B:
    "We're sorry, we couldn't create this account. Please contact your company's Human Resources department.",
};
