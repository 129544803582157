export default {
  anonymousTitle: 'Welcome!',
  agreeButton: 'AGREE',
  buttonContinue: 'Continue',
  buttonStart: "Let's start",
  confidenciality1:
    'Before we begin, I want you to know that the best mental health experts have programmed me to explain very effective techniques to take care of you, and that everything you talk to me about will be confidential.',
  confidenciality2:
    'If I believe you are at risk,\n I will get in touch with the emergency contact you specify, alright?',
  confidenciality3: 'I’m here for you!',
  confidencialityTitle: 'Thank you for your trust',
  confidentialityTitle: 'Confidentiality message',
  inputContactEmergencyName: 'Emergency contact',
  inputContactEmergencyPhone: 'Emergency contact number',
  inputProfilingAge: 'Age',
  inputProfilingBirthDate: 'Date of birth.',
  inputProfilingChildren: 'Do you have kids?',
  inputProfilingContactSchedule: 'Choose your preferred time to chat.',
  inputProfilingGender: 'Gender',
  inputProfilingName: 'Name',
  inputProfilingNationality: 'Nationality',
  inputProfilingOccupation: 'Professional activity',
  inputProfilingSalaryRange: 'Salary range',
  inputProfilingWorkDeepOther: 'Could you tell me what that other one is?',
  inputProfilingWorkDep: 'Area',
  inputSelectPlaceholder: 'Choose an option',
  preProfiling2:
    'That’ll help me decide the best way to\n guide you through this adventure!',
  preProfiling: 'I’d love to learn more about you',
  preferNotToSay: 'Prefer not to say',
  profilingLabel: 'Tell me more about yourself',
  omitLogin: 'Start exploring',
  loginButton: 'Log in',
  registerButton: 'Register',
  screen1Text:
    'With Mindsurf you’ll acquire all the tools you need to feel better and reduce the symptoms of stress, anxiety, and more.',
  screen1Title: 'Improve your wellbeing',
  screen2Text:
    'Log your moods and you’ll receive recommendations to manage what you feel.',
  screen2Title: 'Get to know your emotions',
  screen3Text:
    'Learn techniques for daily life through AI therapy available 24/7 or schedule online sessions with therapists.',
  screen3Title: 'Face whatever challenge life presents',
  screenOnboardingTitle: 'Emotional well-being',
  screenOnboardingDesc:
    'Improve your well-being with guided exercises and 24/7 emotional support.',
  selectCareerAccount: 'Accounting',
  selectCareerCS: 'Computer sciences',
  selectCareerFinance: 'Finance',
  selectCareerLog: 'Logistics',
  selectCareerManagement: 'Management',
  selectCareerMarketing: 'Marketing',
  selectCareerOther: 'Other',
  selectCareerProduction: 'Production',
  selectCareerRH: 'HR',
  selectCareerSales: 'Sales',
  selectChildrenNot: 'No',
  selectChildrenYes: 'Yes',
  selectGenderFemale: 'Female',
  selectGenderMale: 'Male',
  selectGenderNon: 'Non binary',
  selectNationality: 'USA',
  selectNationalityOther: 'Other',
  selectOccNone: 'None of the above',
  selectOccStudent: 'Study',
  selectOccWork: 'Work',
  selectSalary11: '$480-$1200',
  selectSalary25: '$1200-$2160',
  selectSalaryLess: 'Less than $480',
  selectSalaryMore: 'Over $2160',
  selectSalaryNot: 'I’d rather not say',
  studyAndWork: 'Study and work',
};
