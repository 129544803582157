import {createContext} from 'react';

export const defaultChat = {
  conversationFlow: '',
  conversation: null,
  sessionID: null,
  messageID: null,
  lastMessage: null,
};
export const ChatContext = createContext(defaultChat);
