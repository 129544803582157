export default {
  children: {
    no: 'No',
    notSpecific: 'Prefer not to say',
    yes: 'Yes',
  },
  gender: {
    binary: 'Binary',
    female: 'Femenine',
    male: 'Male',
    notSpecific: 'Not Specific',
  },
  occupation: {
    none: 'None of the above',
    study: 'Study',
    studyAndWork: 'Study and work',
    work: 'Work',
  },
};
