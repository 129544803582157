export default {
  children: 'Hijos',
  contactAndSupport: 'Contacto y soporte',
  contactEmergencyName: 'Contacto de emergencia',
  contactEmergencyPhone: 'Núm. de contacto de emergencia',
  createAccount: {
    title: 'Crea una cuenta',
    desc: 'Resguarda tu información de manera segura.',
  },
  dathOfBirth: 'Fecha de nacimiento',
  deleteAccountDsc:
    '¿De verdad quieres\neliminar tu cuenta?\nBorraremos todo tu progreso\ny tus datos personales.',
  deleteButton: 'Eliminar',
  edit: 'Completar datos',
  editProfile: 'Editar',
  editProfileDsc: 'Revisa y edita tus datos.',
  faq: 'Preguntas frecuentes',
  gender: 'Género',
  hello: 'Hola, {{name}}',
  helloWithoutName: 'Hola',
  lineOfWork: 'Línea de trabajo',
  logout: 'Cerrar sesión',
  mail: 'Email',
  mailCopy: 'Mail de contacto copiado',
  name: 'Nombre',
  nationality: 'Nacionalidad',
  notificationChange: 'Configuración de notificaciones cambiada',
  notificationTimeZone: 'Configuración de zona horara cambiada',
  notifications: 'Notificaciones',
  notificationsDsc: 'Elige un horario para recibir recordatorios.',
  language: {
    label: 'Idioma de app',
    system: 'Sistema',
    successMessage: 'Idioma guardado.',
  },
  occupation: 'Ocupación',
  phone: 'Celular',
  privacy: 'Aviso de Privacidad',
  profile: 'Perfil',
  subscription: 'Suscripción',
  terms: 'Términos y Condiciones',
  typeUser: {
    B2C: 'Gratis',
    B2C_PREMIUM: 'Premium',
    B2B: 'Empresarial',
    B2B_PREMIUM: 'Empresarial premium',
  },
  emergencyLine: 'Linea de emergencia',
  emergencyLineDesc:
    'Llama a un terapeuta de nuestra red para recibir ayuda psicológica en una crisis. Disponible 24/7.',
  chatEmergency: 'Chat de emergencia',
  chatEmergencyDesc: 'Habla por chat con Mindsurf para tranquilizarte.',
  contactEmergency: 'Contacto de emergencia',
  contactEmergencyDesc: 'Llama a tu contacto de emergencia y pide ayuda.',
  service: 'Servicios de emergencia',
  serviceDesc:
    'Si estás en una situación de peligro, llama al servicio que corresponda.',
  call: 'Llamar',
  chat: 'Chatear',
  newExercise: 'Nuevos ejercicios',
  versionApp: 'Versión de App',
  deleteAccount: 'Eliminar cuenta',
  deleteAccountInfo:
    'Borraremos los datos, historial y análisis que hacemos para ayudarte a mejorar tu bienestar y salud emocional.',
  deleteAccountPhrase:
    'Escribe ELIMINAR MI CUENTA en el campo de texto para confirmar.',
  delete: 'Eliminar',
  thanksTitle: 'Graciar por usar Mindsurf',
  thanksDesc:
    'Nos encantaría que volvieras. Crea una nueva cuenta cuando quieras y disfruta de la app.',
  close: 'Cerrar',
  codeSuscription: 'Código de suscripción',
  codeDesc: 'Permite acceder a un ser querido.',
  writeCode: 'Escribe o pega tu código.',
  unlock: 'Desbloquear',
  codeActived: '¡Qué bonito es compartir! Ahora tienes la versión premium.',
  codeError:
    'Hay un error en el código. Asegúrate de que esté bien e inténtalo de nuevo.',
  day: 'Día',
  month: 'Mes',
  year: 'Año',
  unlockSuscription: 'Desbloquea la versión premium.',
  benefits: 'Revisa tus beneficios.',
  adviceVersionPremium:
    'Tendrá acceso todo el tiempo que tú mantengas la versión premium.',
  adviceVersionPremiumShared:
    'Tendrás acceso todo el tiempo que mantenga la versión premium.',
  shareCodeDesc:
    'Copia este código y envíalo a la otra persona. Deberá usarlo en',
  unlockFree: 'Desbloquea gratis o con descuento.',
  ownerShare: 'Lo compartes con {{name}}. 🙌🏻',
  memberShare: 'Te lo comparte {{name}}. 🙌🏻',
  codeCopy: 'Código copiado',
  shareSubscription: 'Compartir suscripción',
  userName: 'Usuario: {{name}}',
  updateError:
    'Parece que hubo un error al cambiar tu zona horaria; intenta nuevamente.',
  insights: 'Inisghts',
  insightsDesc: 'Ve tus interacciones con el asistente emocional',
};
