import {MESSAGE_TYPES} from '../constants/codeConstants';
import {CHAT_ACTIVITY_TYPES, FEEDBACK_LEVEL, FEEDBACK_TYPES} from '../types';
import {orderMessagesByTimestamp} from './utils/utils';
import {logSimpleEvent} from './analytics/fbAnalytics';

/** Obtener que tipo de mensaje es dependiendo del contenido. */
function getMessageType({bot, content: messageContent, extraData}) {
  // if (messageContent === '') {
  //   return MESSAGE_TYPES.NO_CONTENT;
  // }

  if (!bot) {
    return MESSAGE_TYPES.NO_BOT;
  }

  if (extraData && CHAT_ACTIVITY_TYPES[extraData.activity_type]) {
    return extraData.activity_type;
  }
  const videoRegex = /(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/[^ ]+/;
  const audioRegex = /(http(s?):)([/|.|\w|\s|\-|_|%])*\.(?:mp3|wav)/;
  const imageRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/;

  if (videoRegex.test(messageContent)) {
    return MESSAGE_TYPES.BOT_VIDEO;
  }

  if (audioRegex.test(messageContent)) {
    return MESSAGE_TYPES.BOT_AUDIO;
  }
  if (imageRegex.test(messageContent)) {
    return MESSAGE_TYPES.BOT_IMAGE;
  }
  // si es una recomendación trae el content type dentro del display details
  if (
    extraData &&
    extraData.display_details &&
    extraData.display_details.content_type
  ) {
    const contentMapping = {
      audio: MESSAGE_TYPES.BOT_EXERCISE_AUDIO,
      video: MESSAGE_TYPES.BOT_EXERCISE_VIDEO,
      flow: MESSAGE_TYPES.BOT_EXERCISE_CHAT,
      evaluation_v2: MESSAGE_TYPES.BOT_EVALUATION,
      programa_v2: MESSAGE_TYPES.BOT_DIGITAL_THERAPY,
      therapy: MESSAGE_TYPES.BOT_THERAPY,
    };

    return (
      contentMapping[extraData.display_details.content_type] ||
      MESSAGE_TYPES.BOT_TEXT
    );
  }

  return MESSAGE_TYPES.BOT_TEXT;
}

function getUrlParams(queryString) {
  const regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {};
  let match;
  while ((match = regex.exec(queryString))) {
    params[match[1]] = match[2];
  }
  return params;
}

function getContentUrlConfig({message, messageType}) {
  const urlStart = message.indexOf('https');
  if (urlStart < 0) {
    return {text: message};
  }
  const text = message.slice(0, urlStart).trim();
  const url = message.slice(urlStart).trim();

  const queryString = '?' + url.split('?')[1];
  const urlParams = getUrlParams(queryString);

  return {
    text,
    url,
    duration: parseInt(urlParams.duration) || null,
  };
}

/** Obtener los numeros de mensajes que no se han visto */
function getNoSeenMessages(messages) {
  const noRead = messages?.filter(m => !m.seen);
  return noRead.length;
}

/** Obtener el número de segundos necesarios para leer un texto */
function secsToRead(message, wordsPerMinute = 270, baseSeconds = 2) {
  const wordsLen = message.split(/\s/g).length;
  const secs = Math.round((wordsLen / wordsPerMinute) * 60);
  // return baseSeconds < secs ? secs : baseSeconds;
  return secs;

  // return baseSeconds + Math.round((wordsLen / wordsPerMinute) * 60);
}

// get a litle bit of the message conversation
function getConversationPreview(conversation) {
  // get array with both Y content
  let messages = orderMessagesByTimestamp(conversation)
    .sort((a, b) => {
      // First, compare the 'bot' property of the objects
      if (b.bot !== a.bot) {
        return b.bot - a.bot;
      }
      // If the 'bot' property is the same, compare the 'conversation length' property
      else {
        return b.content?.length - a.content?.length;
      }
    })
    .map(c => ({bot: c.bot, content: c.content}));

  if (!messages.length) return '';
  return messages[0].content;
}

// function for send feedback
function sendEventFeedback({payload, message, conversationFlow, pushState}) {
  const paramsToSend = {
    ...payload,
    step: '3.A Feedback',
    from: {
      screen: 'ChatGenerico',
      component: 'MessageChat',
    },
    conversationFlowName: conversationFlow,
    messageID: message?.extraData?.message_id || message?.id,
    sessionID: message?.extraData?.session_id,
    date: new Date().toISOString(),
    pushState,
  };
  console.log('🎱🎱🎱🎱🎱🎱', JSON.stringify(paramsToSend, null, 2));
  logSimpleEvent('conversation_flow', paramsToSend);
}

function storageFeedbackMessage({
  feedBackType,
  message,
  comment,
  modifyMessageFeedback,
  conversationFlow,
  pushState,
  setShowThanksModal,
}) {
  const currentFeedback = message?.feedback;
  console.log('🎱🎱🎱🎱🎱🎱', currentFeedback, feedBackType, comment);
  if (feedBackType === FEEDBACK_TYPES.COMMENT && currentFeedback.comment) {
    return;
  }
  //Todo: Manejar like y dislike en el mismo mensaje.
  // Caso: le da like : si no tiene dislike manda solo 1
  // Caso: le da dislike y luego like: si tiene like, manda like con -1 y dislike con 1
  // Caso: le da like y luego dislike: si tiene dislike, manda dislike con -1 y like con 1
  // Caso: le da dislike si no tiene like: manda solo 1
  const currentVal = currentFeedback[feedBackType];
  const payload = {
    type: feedBackType,
    level: FEEDBACK_LEVEL.MESSAGE,
    comment: comment ? comment : null,
    value: currentVal ? -1 : 1,
  };
  sendEventFeedback({payload, message, conversationFlow, pushState});
  const newMessage = {
    ...message,
    feedback: {
      ...currentFeedback,
      [feedBackType]: true,
    },
  };
  modifyMessageFeedback(newMessage);

  const oppositeFeedbackType =
    feedBackType === FEEDBACK_TYPES.LIKE
      ? FEEDBACK_TYPES.DISLIKE
      : FEEDBACK_TYPES.LIKE;

  if (message?.feedback[oppositeFeedbackType]) {
    const oppositePayload = {
      ...payload,
      value: -1,
      type: oppositeFeedbackType,
    };
    sendEventFeedback({
      payload: oppositePayload,
      message,
      conversationFlow,
      pushState,
    });

    const newMessage = {
      ...message,
      feedback: {
        ...currentFeedback,
        like: false,
        dislike: false,
        [feedBackType]: !currentVal,
      },
    };
    modifyMessageFeedback(newMessage);
  }

  if (feedBackType === FEEDBACK_TYPES.COMMENT) {
    const newMessage = {
      ...message,
      feedback: {
        ...currentFeedback,
        comment: true,
      },
    };
    modifyMessageFeedback(newMessage);
    setShowThanksModal(true);
  }
}

export {
  getMessageType,
  getContentUrlConfig,
  getNoSeenMessages,
  secsToRead,
  getConversationPreview,
  sendEventFeedback,
  storageFeedbackMessage,
};
