/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Center, Text, View, VStack} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import FilterButton from '../../components/Filter/FilterButton';
import {useQuery} from '@apollo/client';
import {GET_DISPLAY_DETAIL_CONTENT_TYPE_PAGINATION} from '../../graphql/queries/displayDetails';
import {useTranslation} from 'react-i18next';
import {
  logMainScreen,
  logRudderEvent,
} from '../../helpers/analytics/fbAnalytics';
import {PushStateContext} from '../../hooks/SessionContext';
import BackgroundGradient from '../../components/Background/BackgroundGradient';
import NavigateBar from '../../components/NavigateBar';
import {useNavigation, useRoute} from '@react-navigation/native';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';
import Loading from '../../components/Loading';
import BoxExcercise from '../../components/BoxExcercise';
import {Dimensions, FlatList} from 'react-native';
import {ROUTE_NAMES} from '../../navigation/MainNavigator';

/**
 * Screen que muestra todos los ejercicios
 */
// TODO: Falta resolver bug de paginacion adicional al cargar los primeros ejercicios
// TODO: Falta optimizar las pruebas
export default function AllTools({}) {
  const {t, i18n} = useTranslation();
  const route = useRoute();
  const navigation = useNavigation();
  const {height} = getWidthAndHeight();
  const [lang, setLang] = useState(i18n.language);
  const {pushState} = useContext(PushStateContext);
  const id = route?.params?.id;
  /*Variables para pagginación*/
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [skipQuery, setSkipQuery] = useState(false);

  const [isDataCharged, setIsDataCharged] = useState(false);

  const [filterData, setFilterData] = useState({
    id,
  });
  const [filterNamesArray, setFilterNamesArray] = useState([]);
  const [exercises, setExercises] = useState(null);
  const [categoryInfo, setCategoryInfo] = useState({});
  const [dimensions, setDimensions] = useState(getWidthAndHeight());
  const [numColumnsWeb, setNumColumnsWeb] = useState(6);
  const [firstTime, setFirstTime] = useState(true);

  const [languages, setLanguages] = useState([
    {
      contentTypesMapEs: {
        flow: 'Conversaciones',
        audio: 'Audio',
        evaluation_v2: 'Evaluaciones',
        video: 'Video',
        programa_v2_activity: 'Actividad',
        programa_v2: 'Terapia Digital',
        article: 'Artículo',
      },
    },
    {
      contentTypesMapEn: {
        flow: 'Conversations',
        audio: 'Audio',
        evaluation_v2: 'Evaluations',
        video: 'Video',
        programa_v2_activity: 'Activity',
        programa_v2: 'Digital Therapy',
        article: 'Article',
      },
    },
  ]);

  useEffect(() => {
    logMainScreen('all_tools', pushState);
    if (isWeb()) {
      function updateDimensions() {
        setDimensions(getWidthAndHeight());
      }

      Dimensions.addEventListener('change', updateDimensions);
      return () => {
        Dimensions.removeEventListener('change', updateDimensions);
      };
    }
  }, []);

  const calculateNumColumns = width => {
    if (width >= 1150) return 6;
    if (width >= 1000) return 5;
    if (width >= 850) return 4;
    if (width >= 700) return 3;
    return 2;
  };

  useEffect(() => {
    if (isWeb()) {
      setNumColumnsWeb(calculateNumColumns(dimensions?.width));
    }
  }, [dimensions]);

  const {data: displayDetailGroupData, fetchMore} = useQuery(
    GET_DISPLAY_DETAIL_CONTENT_TYPE_PAGINATION,
    {
      variables: {...filterData, first: 8},
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: skipQuery || !id,
      onCompleted: completeQuery,
    },
  );

  function completeQuery() {
    console.log('FilterData: ', filterData);
    setSkipQuery(true);
  }

  function loadMoreData() {
    if (!hasNextPage) {
      return;
    }
    console.log('Load more data');
    setLoadingMore(true);
    fetchMore({
      variables: {
        first: 6,
        after: endCursor,
      },
    }).then(async response => {
      setLoadingMore(false);
      addMoreData(response?.data?.displayDetailGroup?.displayDetailsPagination);
    });
  }

  function addMoreData(displayDetailsPagination) {
    const {pageInfo, edges} = displayDetailsPagination;
    setEndCursor(pageInfo?.endCursor);
    setHasNextPage(pageInfo?.hasNextPage);
    const content = edges?.map(item => {
      return item?.node;
    });

    setExercises(exercises?.length > 0 ? [...exercises, ...content] : content);
    setIsDataCharged(true);
  }

  useEffect(() => {
    const displayDetailGroup = displayDetailGroupData?.displayDetailGroup;
    if (displayDetailGroup && firstTime) {
      addMoreData(displayDetailGroup?.displayDetailsPagination);
      setFirstTime(false);
      const {name, image, featured, isFavorite} = displayDetailGroup;
      setCategoryInfo({
        name,
        image,
        featured,
        isFavorite,
      });

      setLang(i18n.language);
      const contentTypes = [
        ...new Set(
          displayDetailGroup?.contentTypes?.map(item => {
            return lang === 'es'
              ? languages?.[0]?.contentTypesMapEs?.[item]
              : languages?.[1]?.contentTypesMapEn?.[item];
          }),
        ),
      ];
      setFilterNamesArray(contentTypes);
    }
  }, [displayDetailGroupData, firstTime]);

  const renderItem = ({item}) => {
    return (
      <Box
        justifyContent={isWeb() ? 'center' : 'flex-start'}
        testID="list-excercise-card"
        flexWrap={'wrap'}
        flexDir={'row'}>
        <Center my={2}>
          <BoxExcercise
            exercise={item}
            parentScreen={'AllTools'}
            parentIcon={
              categoryInfo?.featured ? item?.group?.image : categoryInfo?.image
            }
            parent={{
              id,
              name: categoryInfo?.name,
            }}
          />
        </Center>
      </Box>
    );
  };

  return (
    <BackgroundGradient scroll={false} padding>
      <Box w={'100%'} pt={isWeb() ? 20 : 0}>
        <NavigateBar
          backEvent={() => {
            navigation?.navigate(ROUTE_NAMES.home, {screen: 'Herramientas'});
          }}
          headerTitle={categoryInfo?.name}
          textAligned="left"
        />
      </Box>

      <Box w={'100%'} mt={5}>
        {!isDataCharged ? (
          <Box height={dimensions?.height * 0.85}>
            <Center>
              <Loading />
            </Center>
          </Box>
        ) : exercises?.length === 0 ? (
          <Box
            px={5}
            flexDir={'row'}
            alignContent={'center'}
            justifyContent={'center'}
            w={'100%'}>
            <Text w={'90%'} fontSize={'lg'} mt={5}>
              {categoryInfo?.isFavorite
                ? t('exercises:noFav')
                : t('exercises:noDD')}
            </Text>
          </Box>
        ) : (
          isDataCharged && (
            <VStack alignContent={'center'}>
              <FilterButton
                activeIndex={null}
                isFilterTypes={true}
                filter={filterNamesArray}
                setFilter={async f => {
                  setSkipQuery(false);
                  setExercises(null);
                  setFirstTime(true);
                  setFilterData({id, contentType: f});
                  await logRudderEvent({
                    name: 'exercises',
                    step: '1.A ClickFilter',
                    screen: 'Tools',
                    extraFrom: {
                      component: 'Tools',
                    },
                    extraProperties: {
                      filter: f,
                      type: 'contentType',
                    },
                    pushState,
                  });
                }}
              />
              <View w={'100%'} h={height * 0.8}>
                <Center>
                  <FlatList
                    w={'100%'}
                    style={{paddingHorizontal: 25}}
                    data={exercises}
                    keyExtractor={item => item.id}
                    onEndReached={loadMoreData}
                    onEndReachedThreshold={0.2}
                    renderItem={renderItem}
                    ListEmptyComponent={<Loading />}
                    refreshing
                    bounces={false}
                    numColumns={isWeb() ? numColumnsWeb : 2}
                    key={numColumnsWeb}
                  />
                </Center>
                {loadingMore && (
                  <Box position={'absolute'} left={'40%'} bottom={5}>
                    <Center>
                      <Loading />
                    </Center>
                  </Box>
                )}
              </View>
            </VStack>
          )
        )}
      </Box>
    </BackgroundGradient>
  );
}
