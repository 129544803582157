export default {
  bannerDescription: 'Learn from the experts.',
  bannerTitle: 'Live sessions',
  buttonJoinNowEn: 'Join live session',
  buttonSeeRecording: 'See Recording',
  buttonSeeRecordingShort: 'See',
  buttonViewAll: 'See history',
  title: 'Live sessions',
  titlePastSessions: 'Past sessions',
  today: `Today at {{time}} Central Time (CT)`,
  timeFromTo: `{{fromHours}} to {{endHours}} hrs`,
  dateTime: '{{date}} at {{time}} Central time (CT)',
  live: 'LIVE',
  liveNow: 'LIVE NOW',
  inThisMoment: 'Right now',
  nextSessions: 'Next sessions',
  liveNotReadyTitle: "The event hasn't started yet!",
  liveNotReady: 'Check the start time and connect when it begins.',
  topics: 'Topics',
  months: 'Month',
  allTopics: 'All',
  allMonths: 'All',
  notFound:
    'We don’t have any sessions on that topic for now. Come  back soon.',
  calendar: 'Add to calendar',
  needAccess:
    'To make the most of all features, we need access to your calendar.',
  saveSuccess: 'Live successfully added to your calendar.',
};
