/**
 * Componente que muestra el tema que se puede seleccionar
 * Params en los prorotypes
 */

import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon/Icon';
import {Center, Pressable, Text} from 'native-base';

const MenuButton = ({onPress, icon, color, caption}) => {
  return (
    <Pressable testID={'menu-button'} onPress={onPress}>
      <Center mx={5}>
        <Icon name={icon} size={30} color={color} />
        <Text fontSize={'xl'}>{caption}</Text>
      </Center>
    </Pressable>
  );
};

MenuButton.prototypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default MenuButton;
