import React from 'react';
import {Center} from 'native-base';
import PropTypes from 'prop-types';
import Like from '../../res/svgs/like.svg';

/**
 * Componente  LikeIcon normal
 */

const LikeIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <Like width={h} height={w} fill={colour} />
    </Center>
  );
};

LikeIcon.propTypes = {
  colour: PropTypes.string,
};
export default LikeIcon;
