import React from 'react';
import PropTypes from 'prop-types';
import ChatMessage from '../../res/svgs/chatMessage.svg';
import {Center} from 'native-base';

/**
 * Componente  MessageChatIcon normal
 */

const ChatMessageIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <ChatMessage width={h} height={w} fill={colour} />
    </Center>
  );
};

ChatMessageIcon.propTypes = {
  colour: PropTypes.string,
};
export default ChatMessageIcon;
