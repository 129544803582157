/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import QuestionEvaluation from '../../components/QuestionEvaluation';
import {Box, HStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigation, useRoute} from '@react-navigation/native';

import {
  CREATE_EVALUATION_ABSENCE,
  DRAFT_EVALUATION,
  GET_EVALUATION_BY_CODE,
  STORAGE_EVALUATION,
} from '../../graphql/queries/evaluations';
import {useMutation, useQuery} from '@apollo/client';
import Loading from '../../components/Loading';
import PrimaryButton from '../../components/PrimaryButton';
import CircleButtonIcon from '../../components/CircleButtonIcon';
import ModalEvaluationPause from '../../components/ModalEvaluationPause';
import ModalEvaluationDone from '../../components/ModalEvaluationDone';
import {
  logEvent,
  logMainScreen,
  logRudderEvent,
} from '../../helpers/analytics/fbAnalytics';
import {colors} from '../../styles/colors';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';

import * as _ from 'lodash';
import {PushStateContext} from '../../hooks/SessionContext';
import CardTime from '../../components/Program/CardTime';
import BackgroundGradient from '../../components/Background/BackgroundGradient';
import NavigateBar from '../../components/NavigateBar';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';
import {navigateTo} from '../../navigation/MainNavigator';
import {EVALUATION_ANSWERS_TYPES} from '../../constants/codeConstants';
import {ROUTE_NAMES} from '../../navigation/MainNavigator';

/**
 * Screen de Evaluation
 */

/*
 Tengo un arreglo de preguntas con sus respuestas, pero cuando selecciones una reaspuesta puedo:
 1. modificar el estado de la pregunta actual y meterle la respuesta o
  2. Crear un nuevo arreglo con la pregunta modificada y las demas igual
  el problema es  que hay respuestas que tienen skip, eso significa que al darle skip voy a saltarme a
  la siguiente en el arreglo de preguntas que tenga otro codigo de seccion.
  3. Si la pregunta tiene skip, tengo que buscar la siguiente pregunta que tenga el codigo de seccion
  y ponerla como current. pero si le doy atras no puedo regresar a la pregunta dentro del arreglo
  de preguntas, tengo que buscarla en el arreglo de preguntas y ponerla como current.
  4. Si la pregunta no tiene skip, solo tengo que buscar la siguiente pregunta y ponerla como current.
  5. Si la pregunta es la ultima, tengo que ponerla como current y mostrar el boton de finalizar.

  no se si poner dos arreglos, uno con las preguntas y otro con las preguntas que ya respondi
  o si poner un arreglo con las preguntas y un indice que me diga cual es la pregunta actual.


* */

export default function Evaluation({}) {
  const {t} = useTranslation();
  const route = useRoute();
  const navigation = useNavigation();
  const {
    contentUrl,
    id: idEvaluation,
    from,
    title: titleEvaluation,
    categoryID,
    time,
    screenFrom,
  } = route.params;
  const [evaluationState, setEvaluationState] = useState(null);

  const [evaluation, setEvaluation] = useState(null);
  const [title, setTitle] = useState();
  const [question, setQuestion] = useState(null);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [loadingStorage, setLoadingStorage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDone, setShowModalDone] = useState(false);
  // const [isDraft, setIsDraft] = useState(false);
  // const [lastQuestionAnswered, setLastQuestionAnswered] = useState(null);
  const {pushState} = useContext(PushStateContext);
  const {height} = getWidthAndHeight();
  const [lastResponseIndex, setLastResponseIndex] = useState(-1);

  /* queries & mutations  */
  const {data, loading, error} = useQuery(GET_EVALUATION_BY_CODE, {
    variables: {
      code: contentUrl,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !contentUrl,
  });

  const [createEvaluationAnswer] = useMutation(STORAGE_EVALUATION, {
    onError: callbackError,
    onCompleted: callBackSuccess,
  });

  const [createUpdateDraftEvaluationAnswer] = useMutation(DRAFT_EVALUATION, {
    onError: callbackError,
    onCompleted: draftSuccess,
  });

  const [createEvaluationAbsence] = useMutation(CREATE_EVALUATION_ABSENCE, {
    onError: callbackError,
    onCompleted: callBackSuccess,
  });

  // Use effects
  useEffect(() => {
    if (data) {
      const {evaluation, draftEvaluationAnswer} = data;

      let sections = {};
      for (const s of evaluation.sections) {
        sections[s.code] = [];
        for (const q of s.questions) {
          sections[s.code].push({
            text: q.text,
            answers: q.answers.map((a, i) => ({
              text: a.text,
              id: a.id,
              action: a.action,
            })),
            id: q.id,
            answer: null,
          });
        }
      }

      let state = {};

      if (draftEvaluationAnswer) {
        // obtener  seccion en donde se encuentra el id drafExample.currentQuestion
        const section = sections[draftEvaluationAnswer?.lastSectionId];
        const currentIndex = section?.findIndex(
          q => q.id === draftEvaluationAnswer?.lastQuestionId,
        );

        state = {
          sections,
          currentSection: draftEvaluationAnswer?.lastSectionId,
          currentIndex,
          userResponses: JSON.parse(draftEvaluationAnswer?.draft),
        };
        // Nota: por tiempo no puedo probar pero esto es un hotfix (necesitamos pruebas)
        setLastResponseIndex(
          (JSON.parse(draftEvaluationAnswer?.draft)?.length || 1) - 1,
        );
      } else {
        const [firstSectionKey] = Object.entries(sections).find(() => true);
        state = {
          sections,
          currentSection: firstSectionKey,
          currentIndex: 0,
          userResponses: [],
        };
      }

      setEvaluationState(state);
      setEvaluation(evaluation);

      setTitle(evaluation?.title);
    }
  }, [data]);

  useEffect(() => {
    logMainScreen('evaluation', pushState);
  }, []);

  function getCurrentQuestion() {
    const currentSection = evaluationState?.currentSection;
    const currentIndex = evaluationState?.currentIndex;
    // add AnswerSelected:
    // filtrar de userResponses y si está ponerla
    let question = evaluationState?.sections[currentSection][currentIndex];
    const response = evaluationState.userResponses.find(
      el => el.id === question?.id,
    );
    if (response) {
      question = {
        ...question,
        answerSelected: response.answer,
      };
    }

    return question;
    //return evaluationState.sections[currentSection][currentIndex];
  }

  const optionMenu = [{label: t('evaluations:pauseSession'), event: pause}];

  function pause() {
    console.log('pausado');
    openPause();
  }

  function eventBack() {
    setIsLastQuestion(false);
    if (getIsFirstQuestion()) {
      // Me regreso completamente al Home
      if (!idEvaluation) {
        navigation.navigate(ROUTE_NAMES.home);
        return;
      }

      if (isWeb()) {
        navigateTo(ROUTE_NAMES.evaluationIntro, {
          id: idEvaluation,
        });
        return;
      }

      navigation.navigate(ROUTE_NAMES.evaluation, {
        id: idEvaluation,
      });
      return;
    }
    goBackQuestion();
  }

  function getIsFirstQuestion() {
    // checar si es la pregunta 1 de la seccion 1
    const section = evaluationState?.sections[evaluationState?.currentSection];
    const currentIndex = evaluationState.currentIndex;
    const [firstSectionKey] = Object.entries(evaluationState?.sections).find(
      () => true,
    );

    return (
      (currentIndex === 0 &&
        evaluationState.currentSection === firstSectionKey) ||
      lastResponseIndex === -1
    );
  }

  function handleBack() {
    navigation.goBack();
  }

  function openPause() {
    setShowModal(true);
  }

  async function storeAnswers() {
    const answers = evaluationState?.userResponses.map(q => ({
      questionId: q.id,
      answerId: q.answer,
    }));
    setLoadingStorage(true);

    await createEvaluationAnswer({
      variables: {
        answers,
        code: evaluation.code,
      },
    });
  }

  function getBack() {
    setShowModalDone(false);
    if (isWeb()) {
      navigateTo(ROUTE_NAMES.allTools, {
        id: categoryID,
      });
      return;
    }
    navigateTo('goBack');
  }

  async function callBackSuccess(result) {
    const {testResult} =
      result?.createEvaluationAnswer || result?.createEvaluationAbsence;
    //console.log('result', JSON.stringify(result, null, ' '));
    await logEvent('FinishEvaluation', {
      evaluation: evaluation.title,
      points: testResult?.points,
      diagnoseLevel: testResult?.diagnoseLevel,
    });
    setLoadingStorage(false);
    if (testResult?.explanations?.length === 0) {
      // independientemente web o no, hacemos esto.
      console.log('categoryID', categoryID);
      setShowModalDone(true);
      return;
    }

    logRudderEvent({
      name: 'digital-therapy',
      step: '1.B see_result',
      screen: 'Evaluation',
      extraFrom: {
        component: 'ButtonSeeResult',
      },
      extraProperties: {
        therapy_name: title,
        screenFrom,
      },
      pushState,
    });

    navigateTo(ROUTE_NAMES.reportEvaluation, {
      evaluation: {
        id: evaluation?.id,
        code: evaluation?.code,
        title: evaluation?.title,
      },
      testResult,
      from: from,
      code: contentUrl,
      screenFrom,
    });
  }

  function callbackError(d) {
    setLoadingStorage(false);
    setShowModal(false);
    reportError('graphql', data, 'STORAGE_EVALUATION', error);
    console.log('>>>ERROR', d);
  }

  async function draftSuccess(d) {
    setShowModal(false);
    if (!d?.createUpdateDraftEvaluationAnswer?.evaluationAnswer) {
      return;
    }
    const {code} = d?.createUpdateDraftEvaluationAnswer?.evaluationAnswer;
    await logEvent('PauseEvaluation', {
      evaluation: evaluation.title,
      code,
      question,
    });
    console.log('_____', JSON.stringify(d, null, ' '));
  }

  function handleNext() {
    const section = evaluationState?.sections[evaluationState?.currentSection];
    const currentIndex = evaluationState.currentIndex;
    setLastResponseIndex(last => (isLastQuestion ? last : last + 1));

    if (currentIndex + 1 === section.length) {
      const sectionKeys = Object.keys(evaluationState?.sections);
      const currentIndex = sectionKeys.indexOf(evaluationState.currentSection);
      const nextSection = sectionKeys[currentIndex + 1];
      setEvaluationState({
        ...evaluationState,
        currentSection: nextSection,
        currentIndex: 0,
      });
    } else {
      setEvaluationState({
        ...evaluationState,
        currentIndex: currentIndex + 1,
      });
    }
  }

  function getNewResponses(newAnswer) {
    // 1. Si es una preg SKIP_FACTOR, remueve todas las de esa seccion del arreglo de userResponses
    // si no es simplemente la agrega desues de la ultima
    if (newAnswer?.action === EVALUATION_ANSWERS_TYPES.SKIP_FACTOR) {
      // const section = evaluationState.sections[evaluationState?.currentSection];
      const responses = evaluationState.userResponses;
      const filteredResponses = responses.filter(
        el => el.section !== evaluationState?.currentSection,
      );
      const indexToAdd = responses?.findIndex(
        el => el.section === evaluationState?.currentSection,
      );
      filteredResponses.splice(
        indexToAdd !== -1 ? indexToAdd : filteredResponses.length,
        0,
        {
          id: newAnswer.id,
          answer: newAnswer.answerId,
          section: evaluationState?.currentSection,
        },
      );
      return filteredResponses;
    }

    const indexAnswer = evaluationState?.userResponses?.findIndex(
      el => el.id === newAnswer.id,
    );
    if (indexAnswer !== -1) {
      const newResponses = evaluationState.userResponses;
      newResponses[indexAnswer].answer = newAnswer.answerId;
      return [...newResponses];
    }
    return [
      ...evaluationState.userResponses,
      {
        id: newAnswer.id,
        answer: newAnswer.answerId,
        section: evaluationState.currentSection,
      },
    ];
  }

  async function handleAnswer(newAnswer, next = false) {
    // si trae FINISH_WITH_ABSENCE hacemos mutacion de guardado y mandamos a reporte
    if (newAnswer?.action === EVALUATION_ANSWERS_TYPES.FINISH_WITH_ABSENCE) {
      // Hacer mutación de guardado y mandar a reporte
      // await storeAnswers();
      createEvaluationAbsence({
        variables: {
          code: evaluation.code,
        },
      });
      return;
    }
    const section = evaluationState?.sections[evaluationState?.currentSection];
    const newQuestionIndex =
      newAnswer?.action === EVALUATION_ANSWERS_TYPES.SKIP_FACTOR
        ? 0
        : evaluationState.currentIndex + 1;

    const userResponses = getNewResponses(newAnswer);
    setLastResponseIndex(last => (isLastQuestion ? last : last + 1));

    if (newAnswer?.action === EVALUATION_ANSWERS_TYPES.SKIP_FACTOR) {
      // filtrar las userResponse con el sectionID y borrarlos del user response
      // poner todas las answer de cada question en null
      section.forEach(q => (q.answer = null)); //NO TODO
    }
    const questionInArray = getQuestionByKey(section, newAnswer.id, 'id');
    questionInArray.answer = newAnswer.answerId;
    setEvaluationState({
      ...evaluationState,
      sections: {
        ...evaluationState?.sections,
        [evaluationState?.currentSection]: section,
      },
      userResponses,
    });

    // checar si la respuesta que puso tiene un SKIP_FACTOR
    if (newAnswer?.action === 'SKIP_FACTOR') {
      // poner como currentSection l asiguiente sección
      const sectionKeys = Object.keys(evaluationState?.sections);
      const currentIndex = sectionKeys.indexOf(evaluationState.currentSection);
      // si es la ultima seccón poner como lastQuestion
      if (currentIndex + 1 === sectionKeys.length) {
        setIsLastQuestion(true);
        setLastResponseIndex(lastResponseIndex);
        return;
      }
      const nextSection = sectionKeys[currentIndex + 1];
      setEvaluationState({
        ...evaluationState,
        currentSection: nextSection,
        currentIndex: 0,
        userResponses,
      });
    } else {
      // poner como currentIndex el siguiente de la seccion,
      // si es la ultima pregunta, poner el primero de la siguiente secicon
      const currentIndex = evaluationState.currentIndex;
      if (currentIndex + 1 === section.length) {
        const sectionKeys = Object.keys(evaluationState?.sections);
        const currentIndex = sectionKeys.indexOf(
          evaluationState.currentSection,
        );
        // si es la ultima seccón poner como lastQuestion
        if (currentIndex + 1 === sectionKeys.length) {
          setIsLastQuestion(true);
          setLastResponseIndex(lastResponseIndex);
          return;
        }
        const nextSection = sectionKeys[currentIndex + 1];
        setEvaluationState({
          ...evaluationState,
          currentSection: nextSection,
          currentIndex: 0,
          userResponses,
        });
      } else {
        setEvaluationState({
          ...evaluationState,
          currentIndex: currentIndex + 1,
          userResponses,
        });
      }
    }
  }

  useEffect(() => {
    if (evaluationState?.userResponses.length > 0) {
      draftEvaluation();
    }
  }, [evaluationState?.userResponses]);

  function goBackQuestion() {
    // update evaluationState currentIndex - 1
    // si es el primero de la sección, poner el ultimo de la anterior
    const lastResponse = evaluationState?.userResponses[lastResponseIndex];
    const currentIndex = evaluationState?.sections[
      lastResponse?.section
    ]?.findIndex(question => question?.id === lastResponse?.id);
    const currentSection = lastResponse?.section;
    // const lastQuestion = evaluationState.sections[lastResponse.section][lastQuestionIndexSection];

    // const currentIndex = evaluationState.currentIndex;
    // if (currentIndex === 0) {
    // const sectionKeys = Object.keys(evaluationState.sections);
    // const currentSection = sectionKeys.indexOf(
    //   evaluationState.currentSection,
    // );
    // const lastSection = sectionKeys[currentSection - 1];
    // const lastSection = evaluationState.sections[currentSection]
    //
    // const lastSectionQuestions =
    //   evaluationState.sections[lastSection].length - 1;
    // // get last answer with lastSection in userResponse
    // const lastResponseIndex = evaluationState.userResponses.findIndex(
    //   el => el.section === lastSection,
    // );
    setEvaluationState({
      ...evaluationState,
      currentSection: currentSection,
      currentIndex: currentIndex,
    });
    setLastResponseIndex(last => last - 1);
    // } else {
    //   setEvaluationState({
    //     ...evaluationState,
    //     currentIndex: currentIndex - 1,
    //   });
    // }
  }

  function draftEvaluation(out = false) {
    // todo validar si hay preguntas sin responder
    if (!evaluationState || !evaluationState.userResponses) {
      return;
    }
    //console.log('ev', evaluationState);
    const input = {
      code: evaluation.code,
      lastSectionId: evaluationState?.currentSection,
      lastQuestionId: getCurrentQuestion().id,
      draft: JSON.stringify(evaluationState?.userResponses),
    };
    //console.log('variables', input);
    createUpdateDraftEvaluationAnswer({
      variables: {
        input,
      },
    });
    if (out) {
      setShowModal(false);
      if (isWeb()) {
        console.log('categoryID', categoryID);
        navigateTo(ROUTE_NAMES.allTools, {
          id: categoryID,
        });
        return;
      }

      navigation.navigate(ROUTE_NAMES.evaluation, {
        id: idEvaluation,
      });
    }
  }

  function getQuestionByKey(array, key, keyName) {
    return _.find(array, [keyName, key]);
  }

  // function updateElementInArray(array, element, keyName) {
  //   return array.map(obj => {
  //     return obj[keyName] === element[keyName] ? element : obj;
  //   });
  // }

  function getIndexElementInArray(array, key, keyName) {
    return _.findIndex(array, [keyName, key]);
  }

  // function getLastQuestionAnswered(array, keyName) {
  //   return _.findLastIndex(array, keyName);
  // }

  function isNextQuestionAnswered() {
    //buscar la siguiente pregunta y checar si esta en userResponses
    const section = evaluationState?.sections[evaluationState?.currentSection];
    if (section.length === evaluationState.currentIndex + 1) {
      // es la ultima pregunta, tenemos que buscar la siguiente sección
      const sectionKeys = Object.keys(evaluationState?.sections);
      const currentIndex = sectionKeys.indexOf(evaluationState.currentSection);
      if (currentIndex + 1 === sectionKeys.length) {
        // es la ultima sección
        return false;
      }
      const nextSection = sectionKeys[currentIndex + 1];
      const nextSectionQuestions = evaluationState?.sections[nextSection][0];
      const response = evaluationState.userResponses.find(
        el => el.id === nextSectionQuestions.id,
      );
      // si existe entonces true, si no false
      return response !== undefined;
    }
    // checar la sig
    const nextQuestion = section[evaluationState.currentIndex + 1];
    //buscar esa question en el arreglo de userRespnses
    const response = evaluationState.userResponses.find(
      el => el.id === nextQuestion.id,
    );
    // si existe entonces true, si no false
    return response !== undefined;
  }

  return (
    <BackgroundGradient bgColor={'white'}>
      <Box pt={isWeb() ? 20 : 0} flex={1}>
        <Box>
          <NavigateBar
            backEvent={handleBack}
            headerTitle={
              isWeb()
                ? title
                : t('evaluations:evaluationHeader', {title: title})
            }
            optionEvent={pause}
          />
        </Box>
        <Box bg={'secondary.100'}>
          {!evaluationState ? (
            <Box h={height * 0.8}>
              <Loading />
            </Box>
          ) : (
            <Box bg={'secondary.100'}>
              <CardTime title={t('evaluations:titleCardTime')} time={time} />
              <QuestionEvaluation
                question={getCurrentQuestion()}
                index={lastResponseIndex + 1}
                getAnswer={handleAnswer}
                therapyTitle={title}
                screenFrom={screenFrom}
              />
              {isLastQuestion ? (
                <Box mt={10} px={10}>
                  <PrimaryButton
                    onPress={storeAnswers}
                    testId={'finish-evaluations'}
                    extraParams={{
                      mb: 5,
                      isLoading: loadingStorage,
                    }}>
                    {t(isWeb() ? 'evaluations:done' : 'evaluations:finish')}
                  </PrimaryButton>
                </Box>
              ) : (
                <HStack justifyContent="center" py={'10%'}>
                  <CircleButtonIcon
                    iconColor={
                      evaluationState?.userResponses.length === 0 ||
                      lastResponseIndex === -1
                        ? colors.primary10
                        : colors.primary100
                    }
                    testId={'back-evaluations'}
                    iconName={'leftcircleo'}
                    extraParams={{
                      isDisabled:
                        evaluationState?.userResponses.length === 0 ||
                        lastResponseIndex === -1,
                    }}
                    onPress={eventBack}
                  />
                  <CircleButtonIcon
                    iconColor={
                      !isNextQuestionAnswered()
                        ? colors.primary10
                        : colors.primary100
                    }
                    testId={'next-evaluations'}
                    iconName={'rightcircleo'}
                    extraParams={{
                      isDisabled: !isNextQuestionAnswered(),
                    }}
                    onPress={handleNext}
                  />
                </HStack>
              )}
              <ModalEvaluationPause
                showModal={showModal}
                setShowModal={setShowModal}
                draftEvaluation={() => draftEvaluation(true)}
              />
              <ModalEvaluationDone
                showModal={showModalDone}
                setShowModal={setShowModalDone}
                onClose={getBack}
              />
            </Box>
          )}
        </Box>
      </Box>
    </BackgroundGradient>
  );
}
