export default {
  activeFree: 'You are currently on a free plan.',
  buttonToPlans: 'See wellness packages',
  recovery: 'I want to recover it',
  recoverTitle: 'The premium version awaits you.',
  freeFinished: 'Your free trial for the unlimited plan has expired.',
  title: 'See wellness packages',
  trial: 'You have $dias  days left in your free trial for the unlimited plan.',
  currentPlan: 'Current plan',
  iOSCancelPlanTitle: 'You can cancel your subscription in your app Settings.',
  iOSCancelPlanStep1: '1. Open the Settings section in your app',
  iOSCancelPlanStep2: '2. Tap on your name',
  iOSCancelPlanStep3: '3. Tap on "Subscriptions".',
  iOSCancelPlanStep4: '4. Tap on the subscription you want to manage.',
  iOSCancelPlanStep5: '5. Tap on "Cancel subscription".',
  androidCancelPlanTitle:
    'You can cancel your subscription in the Google Play Store app.',
  androidCancelPlanStep1: '1. Open your Google Play Store app.',
  androidCancelPlanStep2: '2. Tap on the menu at the top-right corner.',
  androidCancelPlanStep3:
    '3. Tap on Payments and Subscriptions > Subscriptions',
  androidCancelPlanStep4: '4. Tap on the subscription you want to manage.',
  androidCancelPlanStep5: '5. Tap on "Cancel subscription".',
  androidCancelPlanStep6: '6. Follow the instructions',
  manualCancel:
    'You can cancel your subscription by contacting our Support email.',
  close: 'Close',
  successPayment: 'Successful payment!',
  successPaymentMessage:
    'Thank you for your purchase! You can cancel your subscription at any time.',
  monthly: '{{price}} a month',
  yearly: '{{price}} annual',
  secureApple: 'You can cancel anytime. \nSecured through the App Store.',
  secureAndroid: 'You can cancel anytime.\n Guaranteed by Google Play.',
  subscriptionTitle: 'Subscription',
  cancelSubscription: 'Cancel subscription',
  versionPremium: 'Premium Version',
  versionDesc: 'You and the person you choose enjoy all these benefits:',
  cancelDesc: 'If you cancel, you won’t be able to…',
  continueCancel: 'Continue with the cancellation',
  instructionsCancel: 'Instructions to cancel',
  unlimitedAccess:
    'Unlimited access to all exercises, live sessions, and digital therapy at your pace.',
  psychologicalHelp:
    '24/7 psychological help in emotional crises through a support phone line.',
  personalized:
    'Personalized recommendations through the analysis of your emotional diary with artificial intelligence.',
  emotionalSupport: 'Emotional support in monthly group support sessions.',
  accessContent:
    'Access content whenever you want nor advance in your digital therapy at your own rhythm.',
  psychologicalAssistance:
    'Ask for psychological assistance through our support line in case of an emotional crisis.',
  personalizedRecommendations:
    'Receive personalized recommendations of exercises nor have access to the group support sessions.',
  shareSubscription:
    'Share your subscription with another person, who will also lose their benefits.',
  step1Android: 'Open the Google Play app',
  step2Android: 'Go to your profile on the top right corner',
  step3Android: 'Tap on Payments and subscriptions > Subscriptions',
  step4Android: 'Select your Mindsurf subscription',
  step5Android: 'Tap on Cancel subscription',
  step1Ios: 'Go to settings',
  step2Ios: 'Tap on your name',
  step3Ios: 'Tap on subscriptions',
  step4Ios: 'Select your Minsurf subscription',
  step5Ios: 'Tap on Cancel subscription',
  actualPlan: 'Currently, you have:',
  dateExpiration: 'Expiration date:',
};
