import React from 'react';
import {Text, Center, Input} from 'native-base';
import PropTypes from 'prop-types';

/**
 * Componente  CodeField.web normal
 */
const CELL_COUNT = 6;

const CodeField = ({value, setValue}) => {
  // Input number with only 6 digits
  return (
    <Center>
      <Input
        keyboardType="numeric"
        value={value}
        onChangeText={setValue}
        maxLength={CELL_COUNT}
        placeholder="000000"
        textAlign="center"
        fontSize={30}
        fontFamily={'Quicksand'}
        fontWeight="bold"
        color="black"
        bg={'secondary.100'}
        _focus={{bg: 'secondary.100'}}
      />
    </Center>
  );
};

CodeField.propTypes = {
  text: PropTypes.string,
};
export default CodeField;
