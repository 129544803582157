import {scale} from 'react-native-size-matters';
import {colors} from './colors';
import {fonts} from './fonts';

export const chat = {
  keyboardContainer: {
    backgroundColor: colors.white,
    fontFamily: fonts.quicksand,
    flex: 1,
    flexDirection: 'column',
  },
  backgroundDefault: {
    backgroundColor: colors.white,
  },
  slidingContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 20,
  },
  topSlideHandler: {
    // height: scale(5),
    width: 100,
    backgroundColor: colors.black60,
    borderRadius: 10,
  },
  division: {
    marginTop: 10,
    height: 5,
    borderRadius: 10,
    backgroundColor: colors.black5,
    color: colors.tertiary5,
  },
  menuContainer: {
    flexDirection: 'row',
    marginTop: 15,
    justifyContent: 'space-evenly',
    fontFamily: fonts.quicksand,
  },
  containerTextForSettings: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSettings: {
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    fontWeight: '500',
    color: colors.primary100,
  },
  chipDisabled: {
    backgroundColor: colors.secondary5,
    borderColor: colors.secondary5,
  },
  imageModalHeader: {
    position: 'absolute',
    right: 40,
    top: 40,
    zIndex: 2,
    backgroundColor: 'black',
    borderRadius: 40,
    padding: 5,
    opacity: 0.7,
  },
  message: {
    fontFamily: fonts.quicksand,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 20,
    marginVertical: 5,
    fontSize: '15@ms',
    maxWidth: '100%',
    color: colors.tertiary1,
  },
  bot: {
    alignSelf: 'flex-start',
    backgroundColor: colors.primary100,
    color: colors.secondary100,
    borderBottomLeftRadius: 0,
    marginLeft: 5,
  },
  text: {
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    color: colors.white,
    marginVertical: 5,
    fontWeight: 500,
  },
  time: {
    color: colors.tertiary100,
  },
  multimediaSize: {
    marginBottom: '10@msr',
    width: 280,
    height: 180,
    alignSelf: 'center',
  },
  imageModalImage: {
    width: '100%',
    height: '100%',
    maxWidth: '90%',
    maxHeight: '90%',
    alignSelf: 'center',
    marginTop: 40,
  },
  imageViewer: {
    backgroundColor: colors.primary100,
    paddingTop: 100,
    justifyContent: 'center',
  },
  buttonMenuContainer: {
    fontFamily: fonts.quicksand,
    flexShrink: 1,
    alignItems: 'center',
    width: scale(190),
    paddingHorizontal: '20@msr',
    paddingVertical: '10@msr',
    marginBottom: '20@msr',
  },
  textButtonMenuContainer: {
    textAlign: 'center',
    fontSize: '13@msr',
  },
  container: {
    backgroundColor: colors.white,
    flex: 1,
  },
  margin20: {
    margin: 20,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  goBackIcon: {
    fontSize: '30@ms',
    color: colors.primary100,
    paddingLeft: '15@ms',
  },
  chipsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chip: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    fontSize: 14,
    marginVertical: 5,
    marginLeft: 10,
    borderRadius: 20,
  },

  chipMenuText: {
    color: colors.tertiary5,
    fontSize: '11@ms',
    fontWeight: '500',
    fontFamily: fonts.quicksand,
  },
  user: {
    alignSelf: 'flex-end',
    backgroundColor: colors.secondary600,
    borderBottomRightRadius: 0,
    marginRight: 15,
  },
  messageText: {
    color: colors.primary100,
    fontWeight: '800',
    fontFamily: fonts.quicksand,
    fontSize: '15@ms',
  },
  newMessageAlertText: {
    fontFamily: fonts.quicksand,
    fontSize: '12@ms',
    color: colors.black100,
    backgroundColor: colors.tertiary5,
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 30,
    fontWeight: 'bold',
  },
  backgroundButton: {
    marginLeft: 5,
    backgroundColor: colors.primary100,
    justifyContent: 'center',
    alignSelf: 'center',
    paddingVertical: 5,
    paddingHorizontal: 25,
    borderRadius: 50,
    borderColor: colors.primary100,
    borderWidth: 2,
  },
  textButton: {
    color: colors.white,
  },
  chipText: {
    //color: colors.primary100,
    fontFamily: fonts.quicksand,
  },
  newMessageAlert: {
    backgroundColor: colors.black60,
    opacity: 0.6,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 0,
  },
  messageWithVideo: {
    fontFamily: fonts.quicksand,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 20,
    marginVertical: 5,
    fontSize: '16@ms',
    maxWidth: '98%',
  },
  fullWidthPreview: {
    height: scale(200),
    width: scale(300),
    borderRadius: 20,
  },
  preview: {
    width: 250,
    height: 150,
    borderRadius: 20,
  },
  play: {
    opacity: 0.75,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  opacity: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.3,
    backgroundColor: colors.black,
    borderRadius: 8,
  },
  title: {
    fontFamily: fonts.quicksand,
    color: colors.tertiary5,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: '20@msr',
  },
  flat: {
    backgroundColor: colors.white,
    paddingHorizontal: 5,
    borderRadius: 20,
    // overflow: 'hidden',
  },

  fullWidthContainer: {
    fontFamily: fonts.quicksand,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipMenu: {
    backgroundColor: colors.primary100,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderColor: colors.primary100,
    borderWidth: 2,
    marginHorizontal: 10,
    marginVertical: 5,
    borderRadius: 20,
  },
  typing: {
    alignSelf: 'flex-start',
    backgroundColor: colors.white,
    borderBottomLeftRadius: 0,
    paddingVertical: 5,
    paddingHorizontal: 2,
    borderRadius: 20,
    marginVertical: 5,
    marginBottom: 10,
  },
};
