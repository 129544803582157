import {useQuery} from '@apollo/client';
import {Box, Center, Text, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GET_USER_REPOSITORY_BY_TEMPLATE_ID} from '../../graphql/queries/programs';
import Loading from '../Loading';

export default function AgreementChat({extraData}) {
  const {t} = useTranslation();
  const [userData, setUserData] = useState(null);

  const {template_user_repository_in_id: templateUserRepoId} = extraData || {};

  /* Graphql */
  const {
    data: queryData,
    loading,
    // error,
  } = useQuery(GET_USER_REPOSITORY_BY_TEMPLATE_ID, {
    variables: {
      templateUserRepoId,
    },
    skip: !templateUserRepoId,
  });

  let selectedStyle = {
    bg: 'secondary.500',
    borderWidth: '1',
    borderRadius: 'full',
    h: 46,
    w: 200,
    px: 2,
  };

  useEffect(() => {
    if (queryData?.userRepository?.data) {
      setUserData(JSON.parse(queryData.userRepository.data));
    }
  }, [queryData]);

  if (loading || !userData) {
    return (
      <Box my={5}>
        <Loading />
      </Box>
    );
  }

  const {userList} = userData;

  return (
    <>
      <Box mx={3} mt={7} py={5} bg={'primary.600'} rounded={'xl'}>
        <VStack space={2} justifyContent="flex-start">
          <Center>
            <Text
              paddingLeft={3}
              // py={4}
              color="tertiary.500"
              fontSize={'2xl'}
              fontWeight={800}>
              {/*{instructions?.STEP_1?.label}*/}
              {t('program:agreement.widget.title')}
            </Text>
          </Center>

          {/* Button list */}
          <Center my={5}>
            {userList.selected.map((item, i) => (
              <Center
                my={1}
                testID={'user-selected-id-' + i}
                key={`user-selected-id-${i}`}
                {...selectedStyle}>
                <Text mx={4} color="light.50">
                  {item}
                </Text>
              </Center>
            ))}
          </Center>
        </VStack>
      </Box>
    </>
  );
}
