import React from 'react';
import PropTypes from 'prop-types';
import Sheet from 'react-modal-sheet';

/**
 * Componente  SlidingPanel.web normal
 */
const initialSnap = 1; // Initial snap point when sheet is opened
const SlidingPanel = ({
  isOpen,
  onClose,
  children,
  snapPoints,
  disableDrag = false,
  featureFlagMenu = false,
}) => {
  return (
    <Sheet
      snapPoints={snapPoints}
      initialSnap={initialSnap}
      isOpen={isOpen}
      onClose={() => onClose()}
      disableDrag={disableDrag}>
      <Sheet.Container style={featureFlagMenu ? {boxShadow: 'none'} : {}}>
        <Sheet.Header />
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};

SlidingPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default SlidingPanel;
