import {Center} from 'native-base';
import FavEmpty from '../../res/svgs/favempty.svg';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente  BackIcon normal
 */

const FavIconEmpty = ({colour = 'white', h = 20, w = 20}) => {
  return (
    <Center pr={2} pl={2}>
      <FavEmpty width={h} height={w} fill={colour} />
    </Center>
  );
};

FavIconEmpty.propTypes = {
  colour: PropTypes.string,
};
export default FavIconEmpty;
