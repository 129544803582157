import {Center} from 'native-base';
import EyeClose from '../../res/svgs/eyeClose.svg';
import React from 'react';
import PropTypes from 'prop-types';

const EyeCloseIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <EyeClose width={h} height={w} fill={colour} />
    </Center>
  );
};

EyeCloseIcon.propTypes = {
  colour: PropTypes.string,
};
export default EyeCloseIcon;
