import {ApolloProvider} from '@apollo/client';
import {NativeBaseProvider, StatusBar} from 'native-base';
import React from 'react';
import {I18nextProvider} from 'react-i18next';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import {ChatContext} from './hooks/ChatContext';
import {
  BoxAssistantContext,
  ChatPersistentContext,
  DeviceInfoContext,
  ExtraInfoContext,
  FeatureFlagsContext,
  NotificationContext,
  PushStateContext,
  RemoteConfigContext,
  SessionContext,
  UserContext,
} from './hooks/SessionContext';

/**
 * Metodo que engloba todos los contextos que tiene la app.
 * Estos contextos se comparten siempre en la App y pueden ser consultados en cualquier
 * pantalla y/o componente de React.
 * @param user Datos del usuario: {suscriptionStatus, user}
 * @param session Si está logueado {onboarding, authenticated}
 * @param remoteConfig Los datos que nos traemos de remoteConfig
 * @param theme Configuraciones de Native Base
 * @param error Dropdown que muestra los errores de la app.
 * @param apollo Configuraciones de graphql
 * @param deviceInfo Información física del dispositivo
 * @param extraInfo Info extra de variables globales (por ahora si se está reproduciendo un audio)
 * @param featureFlags feature Flags para activar/desactivar funcionalidades
 * @param children El componente de la app.
 * @returns Componente con todos los contextos incluidos
 */
const CombineContexts = ({
  user,
  session,
  remoteConfig,
  theme,
  notificationMessage,
  apollo,
  deviceInfo,
  extraInfo,
  children,
  featureFlags,
  pushState,
  i18n,
  chatPersistent,
  boxAssistant,
  chatState,
}) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider {...apollo}>
        <NotificationContext.Provider value={{...notificationMessage}}>
          <UserContext.Provider value={{...user}}>
            <SessionContext.Provider value={{...session}}>
              <RemoteConfigContext.Provider value={{remoteConfig}}>
                <DeviceInfoContext.Provider value={{deviceInfo}}>
                  <FeatureFlagsContext.Provider value={{featureFlags}}>
                    <ExtraInfoContext.Provider value={{...extraInfo}}>
                      <PushStateContext.Provider value={{...pushState}}>
                        <ChatPersistentContext.Provider
                          value={{...chatPersistent}}>
                          <BoxAssistantContext.Provider
                            value={{...boxAssistant}}>
                            <ChatContext.Provider value={{...chatState}}>
                              <SafeAreaProvider
                                initialMetrics={initialWindowMetrics}>
                                <StatusBar hidden={true} />
                                <NativeBaseProvider theme={theme}>
                                  {children}
                                </NativeBaseProvider>
                              </SafeAreaProvider>
                            </ChatContext.Provider>
                          </BoxAssistantContext.Provider>
                        </ChatPersistentContext.Provider>
                      </PushStateContext.Provider>
                    </ExtraInfoContext.Provider>
                  </FeatureFlagsContext.Provider>
                </DeviceInfoContext.Provider>
              </RemoteConfigContext.Provider>
            </SessionContext.Provider>
          </UserContext.Provider>
        </NotificationContext.Provider>
      </ApolloProvider>
    </I18nextProvider>
  );
};

export default CombineContexts;
