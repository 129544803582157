import {Box, Image} from 'native-base';
import React from 'react';
import PrimaryButton from '../../PrimaryButton';
import images from '../../../res/images';

const FinishButton = ({onFinishConversation = () => {}, labelText}) => {
  const {divisor} = images;

  return (
    <>
      <Box m={5}>
        <Image source={divisor} resizeMode={'contain'} alt={'divisor'} />
        <PrimaryButton
          extraParams={{
            mb: 5,
          }}
          testId={'finish-exercise-button'}
          onPress={onFinishConversation}>
          {labelText}
        </PrimaryButton>
      </Box>
    </>
  );
};

export default FinishButton;
