import React from 'react';
import { FaChevronDown, FaRegStar, FaStar } from "react-icons/fa";

/**
 * Componente  Icon.mobile normal
 */

const Icon = ({name, size, style, color, testID}) => {
  const styles = {
    fontSize: size >= 30 && size <= 50 ? '3rem' : size,
    ...style,
  };
  const props = {
    style: styles,
    color,
    testID,
  };


  switch (name) {
    case 'chevron-down':
      return <FaChevronDown {...props} />;
    case 'star':
      return <FaRegStar {...props} />;
    case 'star-o':
      return  <FaStar {...props} />;
    default:
      return <FaChevronDown {...props} />;
  }

};


export default Icon;
