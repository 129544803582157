export default {
  bannerDescription: 'Aprende de la mano de los expertos.',
  bannerTitle: 'Sesiones en vivo',
  buttonJoinNow: 'Entrar a la sesión en vivo',
  buttonSeeRecording: 'Ver',
  buttonSeeRecordingShort: 'Ver',
  buttonViewAll: 'Ver todo',
  title: 'Sesiones en vivo',
  titlePastSessions: 'Sesiones pasadas',
  today: `Hoy {{time}}`,
  timeFromTo: `{{fromHours}} a {{endHours}} hrs`,
  dateTime: '{{date}} a las {{time}}',
  live: 'EN VIVO',
  liveNow: 'EN VIVO AHORA',
  inThisMoment: 'En este momento',
  nextSessions: 'Próximas sesiones',
  liveNotReadyTitle: '¡Aún no iniciamos!',
  liveNotReady: 'Revisa la hora de inicio y conéctate cuando comience.',
  topics: 'Temas',
  months: 'Mes',
  allTopics: 'Todos',
  allMonths: 'Todos',
  notFound: 'Por el momento no tenemos sesiones sobre ese tema. Regresa pronto',
  calendar: 'Agregar al calendario',
  needAccess:
    'Para aprovechar al máximo todas las funciones, necesitamos acceso a tu calendario.',
  saveSuccess: 'Live añadido a tu calendario con éxito.',
};
