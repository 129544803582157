import React from 'react';
import {Spinner, VStack, Center} from 'native-base';

/**
 * Screen de Loading
 */

export default function Loading({h = 10}) {
  return (
    <Center px="3" h={h} flex={1}>
      <Spinner color="primary.100" size="lg" testID="textId" />
    </Center>
  );
}
