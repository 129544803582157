import {Button, Center} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import {isWeb} from '../helpers/utils/defaultUtils';

/**
 * Botón mindful color azul con las medidas y estilos
 *
 * @param extraParams si el botón necesita parámetros extra (acepta parámetros de native base)
 * @param testId
 * @param variant variante de native base
 * @param onPress evento clic
 * @param children lo que va dentro del botón
 */

const PrimaryButton = ({
  extraParams = {},
  testId,
  variant = 'primary',
  onPress,
  children,
}) => {
  return (
    <Center>
      <Button
        testID={testId}
        px={8}
        py={4}
        minW={'100%'}
        //minH={47}
        bg={'primary.100'}
        _pressed={{bg: 'primary.200'}}
        variant={variant}
        _text={{
          fontSize: 'md',
          fontWeight: 800,
          fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
        }}
        {...extraParams}
        onPress={() => onPress()}>
        {children}
      </Button>
    </Center>
  );
};

PrimaryButton.propTypes = {
  extraParams: PropTypes.object,
  testId: PropTypes.string,
  variant: PropTypes.string,
  onPress: PropTypes.func,
  children: PropTypes.node,
};
export default PrimaryButton;
