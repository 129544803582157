import React from 'react';
import {colors} from '../../styles/colors';
import {HStack, Image, Text} from 'native-base';
import {isWeb} from '../../helpers/utils/utils';

const ExerciseGroupBadge = ({exerciseGroup}) => {
  return (
    <HStack
      bg={'secondary.30'}
      rounded={'full'}
      alignItems={'center'}
      px={5}
      py={2}>
      {exerciseGroup?.image && (
        <Image
          testID={'display-group-image'}
          source={{
            uri: exerciseGroup.image.cdnUrl,
          }}
          alt={exerciseGroup.name}
          h={7}
          w={7}
        />
      )}
      <Text
        ml={2}
        fontWeight={'800'}
        fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
        fontSize={'md'}>
        {exerciseGroup?.name}
      </Text>
    </HStack>
  );
};

export default ExerciseGroupBadge;
