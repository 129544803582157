export default {
  children: {
    no: 'No',
    notSpecific: 'Prefiero no decirlo',
    yes: 'Sí',
  },
  gender: {
    binary: 'Binario',
    female: 'Femenino',
    male: 'Masculino',
    notSpecific: 'Prefiero no decir',
  },
  occupation: {
    none: 'Ninguna de las dos',
    study: 'Estudio',
    studyAndWork: 'Estudio y trabajo',
    work: 'Trabajo',
  },
};
