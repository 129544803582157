import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {View} from 'react-native';
import {createMarkdownStyles} from '../../styles/base';

/**
 * Componente  MarkdownView.web normal
 */

const MarkdownView = ({children, testID}) => {
  const markdownStyles = createMarkdownStyles();

  return (
    <View testID={testID} style={{...markdownStyles, fontSize: 16}}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </View>
  );
};

MarkdownView.propTypes = {
  text: PropTypes.string,
};
export default MarkdownView;
