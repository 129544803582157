/**
 interfaces para Programas
 */

export const ACTIVITY_TYPES = {
  CHAT: 'Chat',
  LIST_FILL_AND_CHOOSE: 'ListFillAndChoose',
  AGREEMENT_COMPONENT: 'AgreementComponent',
  AGREEMENT_AND_EMOTIONS: 'AgreementAndEmotions',
  CHOOSE_IMAGE_COMPONENT: 'ChooseImageComponent',
  JOURNAL_LIST_COMPONENT: 'JournalListComponent',
  EMOTIONAL_ENTRY_COMPONENT: 'EmotionalEntryComponent',
  CHOOSE_BUTTON_STATIC_LIST: 'ChooseButtonStaticList',
  TEXT_AREA_LIST: 'TextAreaList',
  CHECK_STATIC_LIST: 'CheckStaticList',
  TIMER_WITH_CHECK_LIST: 'TimerWithCheckList',
  LIST_FILL_SEPARATED: 'ListFillSeparated',
  LIST_SIMPLE: 'ListSimple',
  FORM: 'Form',
  SLIDER: 'Slider',
  SOS_ACTIVITY: 'SOS_ACTIVITY',
  RECORD_SOUND: 'RecordSound',
  CHECKBOX_USER_INPUT: 'CheckboxUserInput',
  FORM_WITH_RECORD_SOUND: 'FormWithRecordSound',
  DEPRESSION_DAILY: 'DepressionDaily',
  MULTI_ENTRY_DROPDOWN: 'MultiEntryDropdown',
  MARKDOWN: 'Markdown',
};

export const ACHIEVEMENT_TYPES_AVAILABLE = {
  LIST_FILL_AND_CHOOSE: 'ListFillAndChoose',
  AGREEMENT_COMPONENT: 'AgreementComponent',
  AGREEMENT_AND_EMOTIONS: 'AgreementAndEmotions',
  CHOOSE_IMAGE_COMPONENT: 'ChooseImageComponent',
  EMOTIONAL_ENTRY_COMPONENT: 'EmotionalEntryComponent',
  CHOOSE_BUTTON_STATIC_LIST: 'ChooseButtonStaticList',
  TIMER_WITH_CHECK_LIST: 'TimerWithCheckList',
  LIST_FILL_SEPARATED: 'ListFillSeparated',
  LIST_SIMPLE: 'ListSimple',
  FORM: 'Form',
  RECORD_SOUND: 'RecordSound',
  CHECKBOX_USER_INPUT: 'CheckboxUserInput',
  FORM_WITH_RECORD_SOUND: 'FormWithRecordSound',
};

export const CHAT_ACTIVITY_TYPES = {
  AGREEMENT_SINGLE_VIEW: 'AgreementSingleView',
  USER_IMAGE_VIEW: 'UserImageView',
  QUOTE: 'Quote',
  BADGE: 'Badge',
};

// like, dislike, comment, abandono de conversación, 😐, 👎, ❤
export const FEEDBACK_TYPES = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  COMMENT: 'comment',
  DESERTION: 'desertion',
  NEUTRAL: 'neutral',
  EMOJI_DISLIKE: 'emojiDislike',
  EMOJI_LOVE: 'emojiLove',
};
// level of feedback
export const FEEDBACK_LEVEL = {
  PROGRAM_SESSION: 'PROGRAM_SESSION',
  MESSAGE: 'MESSAGE',
  EXERCISE: 'EXERCISE',
  COMPLETE_CONVERSATION: 'COMPLETE_CONVERSATION',
};

export const PROGRAM_STATUS_TYPES = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_FINAL_EVALUATION: 'COMPLETED_WITH_FINAL_EVALUATION',
};

export const MULTIMEDIA_TYPES = {
  AUDIO: 'AUDIO',
  EMOTIONS: 'EMOTIONS',
};

export const FIELD_TYPES = {
  TEXT: 'INPUT',
  TEXT_AREA: 'TEXT',
  PHOTO: 'PHOTO',
  CHOOSE_BUTTON_STATIC_LIST_2: 'CHOOSEBUTTONSTATICLIST2',
  SELECT: 'SELECT',
  LIST_SIMPLE: 'LISTSIMPLE',
  SCORE_LIST: 'SCORELIST',
};

export const ACHIEVEMENT_TYPES = {
  ACHIEVEMENT: 'Achievement',
  BADGE: 'Badge',
};

interface session {
  id: number;
  title: string;
  enable: Boolean;
  finished: Boolean;
  results: Object;
  activities: activity[];
}

interface activity {
  id: number;
  order: number;
  internalName: string;
  userRepositoryCondition: {
    name: String,
    exists: boolean,
  };
  exercise:
    | Chat[]
    | listFillandChoose[]
    | Agreement[]
    | AgreementAndEmotions[]
    | ChooseImage[]
    | JournalList[]
    | EmotionalDiaryEntry[]
    | ChooseButtonStaticList[]
    | TextAreaList[]
    | CheckStaticList[]
    | ListFillSeparated[]
    | ListSimple[];
}

interface ConversationMessage {
  content:
    | string
    | {
        image: String,
        markdown: string,
      };
  contentType: string;
  format: string;
}

interface Chat {
  id: string;
  title: string;
  conversationFlow: string;
  type: string; //Chat
  instructions: string;
  conversationMessages: ConversationMessage[];
}

interface CategoryListFillAndChoose {
  id: string;
  labels: {
    inProgress: string,
    preview: string,
    result: string,
  };
  instructions: String;
  max: number;
  min: number;
}

interface listFillandChoose {
  id: string;
  title: string; // maybe result?
  type: string; //listFillandChoose
  userRepositoryOutName: string; //Depresión: Actividades en tu día
  userRepositoryOut: number;
  categories: CategoryListFillAndChoose[];
  instructions: {
    start: string,
    previewFase2: string,
    result: string,
  };
  validation: string;
}

interface Agreement {
  id: string;
  title: string; // maybe result?
  type: string; //AgreementComponent
  userRepositoryInName: string; // Depresión: Actividades en tu día
  userRepositoryOutName: string; //Depresión: Actividades en tu día
  userRepositoryOut: Object;
  instructions: {
    start: string,
    result: string,
  };
  maxToChoose: number;
  minToChoose: number;
}

interface AgreementAndEmotions {
  id: number;
  title: {
    start: string, // Al inicio de la lista
    result: string, // Cuando acabó y quiere ver el resultado
  };
  type: string; //AgreementAndEmotions
  instructions: {
    start: string, // Al inicio de la lista
    activityn: string, // Dependiendo del numero de actividades. (2)
    result: string, // Cuando acabó y quiere ver el resultado
  };
  userRepositoryInName: string; // Depresión: Pacto Actividades en tu día
  userRepositoryOutName: string; //Depresion: Emociones en tus actividades
  userRepositoryOut: Object;
}

interface ChooseImage {
  id: number;
  title: {
    start: string, // Al inicio de la lista
    result: string, // Cuando acabó y quiere ver el resultado
  };
  type: string; //ChooseImageComponent
  instructions: {
    start: string, // Al inicio de la lista
    activityn: string, // Dependiendo del numero de actividades. (2)
    result: string, // Cuando acabó y quiere ver el resultado
  };
  userRepositoryOutName: string; //Depresión: Tu outfit favorito
  userRepositoryOut: Object;
  required: Boolean;
}

interface JournalList {
  id: number;
  title: string;
  type: string; //JournalListComponent
  instructions: string;
  userRepositoryOutName: string;
  userRepositoryOut: Object;
  userRepositoryInName: string;
  userAchievementName: {
    name: String,
    userRepositoryInName: String,
  };
}

interface EmotionalDiaryEntry {
  id: number;
  title: string;
  type: string; //EmotionalEntryComponent
  instructions: string;
  userRepositoryOutName: string;
  userRepositoryOut: Object;
  userAchievementName: {
    name: String,
    userRepositoryInName: String,
  };
}

interface ChooseButtonStaticList {
  id: number;
  title: string;
  type: string; //EmotionalEntryComponent
  userRepositoryOutName: string;
  userRepositoryOut: Object;
  elements: [
    {
      value: number,
      label: string,
    },
  ];
}

interface TextAreaList {
  id: number;
  title: string;
  type: string; //JournalListComponent
  instructions: string;
  userRepositoryOutName: string;
  userRepositoryOut: number;
  categories: CategoryListFillAndChoose[];
  instructions: {
    start: string,
    previewFase2: string,
    result: string,
  };
  validation: string;
}

interface CheckStaticList {
  id: number;
  title: string;
  type: string; //JournalListComponent
  instructions: string;
  userRepositoryOutName: string;
  userRepositoryOut: Object;
  elements: [
    {
      value: number,
      label: string,
    },
  ];
}

interface ListFillSeparated {
  id: string;
  title: string; // maybe result?
  type: string; //listFillandChoose
  userRepositoryOutName: string; //Depresión: Actividades en tu día
  userRepositoryOut: number;
  categories: CategoryListFillAndChoose[];
  instructions: {
    start: string,
    previewFase2: string,
    result: string,
  };
  validation: string;
}

interface ListSimple {
  id: string;
  title: string; // maybe result?
  type: string; //listFillandChoose
  userRepositoryOutName: string; //Depresión: Actividades en tu día
  userRepositoryOut: number;
  instructions: {
    start: string,
    previewFase2: string,
    result: string,
  };
  validation: string;
  min: number;
  max: number;
}

//

interface AgreementComponentFase2 {
  title: string;
  instructions: string;
  textBeforeName: string; // marckdown
  textAfterName: string;
  textBeforeSignature: string;
  elementsToChoose: ListComponent;
  maxToChoose: number;
}
