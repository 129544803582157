import {Text, Box, Center, TextArea} from 'native-base';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TouchableOpacity} from 'react-native';
import {colors} from '../../styles/colors';
import CommentIcon from '../icons/CommentIcon';
import PrimarySmallButton from '../PrimarySmallButton';
import IconFont from '../Icon/IconFont';

const RatingExercise = ({
  sendFeedback = () => {},
  testID = 'feedback-card',
  isMessage,
}) => {
  const {t, i18n} = useTranslation();
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState();

  const handleStarPress = index => {
    setRating(index);
  };

  function handleTextChange(text) {
    setComment(text);
  }

  return (
    <Box
      testID={testID}
      bgColor={isMessage ? 'transparent' : 'secondary.100'}
      mt={5}
      p={2}>
      <Box px={4} my={2}>
        <Text fontWeight={800} fontSize={'md'} textAlign={'center'}>
          {t('exercises:titleRating')}
        </Text>
        <Center>
          <Box mt={3} flexDir={'row'}>
            {[1, 2, 3, 4, 5].map(index => (
              <TouchableOpacity
                testID={`star-${index}`}
                key={index}
                onPress={() => handleStarPress(index)}>
                <Center mx={1}>
                  <IconFont
                    testID={`rating-${index}`}
                    name={index <= rating ? 'star' : 'star-o'}
                    size={30}
                    style={{
                      color:
                        index <= rating
                          ? colors.yellow
                          : isMessage
                          ? colors.primary10
                          : colors.primary70,
                    }}
                  />
                </Center>
              </TouchableOpacity>
            ))}
          </Box>
          <Text mt={2} color={'tertiary.100'} fontWeight={800}>
            {t(`exercises:rating${rating}`)}
          </Text>
        </Center>
      </Box>

      <Box testID="textarea-comment" mt={2}>
        <Text fontWeight={800}> {t('exercises:textareaRating')}</Text>
        <TextArea
          testID="feedback-input"
          borderRadius={'2xl'}
          bg={'white'}
          _focus={{bg: '#fff', borderColor: 'secondary.20'}}
          borderColor={'secondary.20'}
          borderWidth={1}
          numberOfLines={10}
          onChangeText={text => {
            handleTextChange(text);
          }}
        />
      </Box>
      <PrimarySmallButton
        testId={'button-send'}
        onPress={() => {
          sendFeedback(rating, comment);
        }}
        extraParams={{mt: 5}}>
        {t('exercises:sendRating')}
      </PrimarySmallButton>
    </Box>
  );
};

export default RatingExercise;
