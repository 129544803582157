/* eslint-disable react-hooks/exhaustive-deps */
import Slider from '@react-native-community/slider';
import {Box, Center, HStack, Text, View} from 'native-base';
import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable} from 'react-native';
import LinkPreview from 'react-native-link-preview';
//import YoutubePlayer from 'react-native-youtube-iframe';
import {MESSAGE_TYPES} from '../../../constants/codeConstants';
import {colors} from '../../../styles/colors';
import AnimatableBox from '../../AnimatableBox/AnimatableBox';
import Icon from '../../Icon/Icon';
import VideoComponent from '../../Video/VideoComponent';
import FeedbackCard from '../FeedbackCard';
import {reportError} from '../../../helpers/crashlytics/fbCrashlytics';

/*
 * Este componente va a soportar que le llegue un video de YT o de vimeo tanto publico como privado.
 * */

const VideoMessage = forwardRef((props, ref) => {
  const {content, id, type, extraData, onStartVideo, onFinishVideo} =
    props || {};
  const {t} = useTranslation();
  const ytUrl = getVideoUrl(content);
  const idYT = type === MESSAGE_TYPES.BOT_VIDEO ? getVideoId(content) : null;
  const [title, setTitle] = useState('');
  const [vimeoURL, setVimeoURL] = useState(null);
  const videoRef = useRef(null);
  const [playOrPauseIcon, setPlayorPauseIcon] = useState('playcircleo');
  const [paused, setPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false);
  const [idVideo, setIdVideo] = useState(null);

  useEffect(() => {
    if (type === MESSAGE_TYPES.BOT_VIDEO && ytUrl !== '') {
      getTitle(ytUrl).then();
      return;
    }
    if (
      extraData &&
      extraData?.display_details &&
      extraData?.display_details?.multimedia
    ) {
      // get title and id by multimedia
      const {description, multimedia} = extraData?.display_details;
      setTitle(description);
      setVimeoURL(multimedia.playUrl);
    }
  }, [ytUrl]);

  /** Obtener URL de YT que está en el mensaje */
  function getVideoUrl(message) {
    const urlStart = message?.indexOf('https');
    let url = message?.substring(urlStart);
    url = url?.replace(/\\/g, '');
    return url;
  }

  /**  obtener Id del video que está en la url*/
  function getVideoId(mensaje) {
    const msge = mensaje?.replace(/\\/g, '');
    if (msge?.indexOf('youtube') !== -1) {
      const start = msge?.indexOf('v=');
      const end = msge?.indexOf('&', start);
      return msge?.substring(start + 2, end);
    }
    const idStart = msge?.lastIndexOf('/');
    return msge?.substring(idStart + 1);
  }

  /* obtener imagen previa y titulo */
  async function getTitle(videoUrl) {
    try {
      const {title: previewTitle} = await LinkPreview.getPreview(videoUrl);
      setTitle(previewTitle);
    } catch (e) {
      console.log('error', e);
      reportError('VideoMessage.web', {}, 'getTitle', e);
    }
  }

  const handlePlay = () => {
    setPaused(false);
    setPlayorPauseIcon('pause');
  };

  const handlePause = () => {
    setPaused(true);
    setPlayorPauseIcon('playcircleo');
  };

  const handleSeek = time => {
    videoRef?.current?.seek(time);
  };

  const handleProgress = progress => {
    setCurrentTime(progress?.currentTime);
    let progressTrack = duration / 3;
    progressTrack = progressTrack * 2;
    if (progress?.currentTime >= progressTrack) {
      onFinishVideo(extraData?.display_details);
      setIsVisibleFeedback(true);
    }
  };

  const handleLoad = data => {
    setDuration(data?.duration);
  };

  const handleEnd = () => {
    setPaused(true);
    setPlayorPauseIcon('playcircleo');
    setCurrentTime(0);
    videoRef?.current?.seek(0);
  };

  return (
    <AnimatableBox key={'video-message-' + id} ref={ref}>
      <Box testID={'bot-video'} borderRadius={20} bg={'linen.10'} p={'6%'}>
        <Text
          color={'tertiary.100'}
          fontWeight={800}
          textAlign={'right'}
          alignSelf={'flex-end'}>
          {t('exercises:video')}
        </Text>

        <HStack justifyContent={'space-between'}>
          <Text color={'primary.100'} fontSize={'md'} fontWeight={800}>
            {title}
          </Text>

          {/* type === MESSAGE_TYPES.BOT_EXERCISE_VIDEO && <Text>Pa guardar</Text> */}
        </HStack>

        <Box flex={1} h={'100%'}>
          {type === MESSAGE_TYPES.BOT_EXERCISE_VIDEO && (
            <Box mb={3} borderRadius={20}>
              <View borderRadius={20} w={'100%'} mt={3}>
                <VideoComponent
                  ref={videoRef}
                  url={vimeoURL}
                  paused={paused}
                  handleProgress={handleProgress}
                  handleLoad={handleLoad}
                  handleEnd={handleEnd}
                />
              </View>
              <Center
                zIndex={1}
                position={'absolute'}
                bottom={0}
                flexDirection={'row'}
                py={3}
                px={3}
                w={'100%'}
                borderRadius={20}
                bg={'transparent'}>
                <Center w={'20%'}>
                  <Pressable onPress={paused ? handlePlay : handlePause}>
                    <Icon name={playOrPauseIcon} size={40} color="#fff" />
                  </Pressable>
                </Center>
                <Center w={'80%'}>
                  <Box pr={3} alignItems="baseline" w={'100%'}>
                    <Slider
                      style={{width: '100%'}}
                      minimumValue={0}
                      maximumValue={duration}
                      minimumTrackTintColor={colors.secondary100}
                      maximumTrackTintColor={colors.secondary100}
                      thumbTintColor={colors.secondary100}
                      value={currentTime}
                      onSlidingComplete={handleSeek}
                    />
                  </Box>
                </Center>
              </Center>
            </Box>
          )}
          {isVisibleFeedback && (
            <FeedbackCard
              content={content}
              contentType={'video'}
              id={idVideo}
              isVisible={isVisibleFeedback}
            />
          )}
        </Box>
      </Box>
    </AnimatableBox>
  );
});

VideoMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default VideoMessage;
