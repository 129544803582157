import React from 'react';
import {ScrollView} from 'native-base';

/**
 * Componente  KeyboardContainer.native normal
 */

const KeyboardContainer = ({children}) => {
  return <ScrollView>{children}</ScrollView>;
};

export default KeyboardContainer;
