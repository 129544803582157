/* istanbul ignore file */
import {extendTheme} from 'native-base';

/*
 * Colores base:
 * primary:    #000000
 * secondary:  #ffffff
 * tertiary:   #777194
 * linen:      #FCECE0
 * baby blue:  #AFE3FF
 * violet:     #AD8EFF
 *
 * */

export const theme = extendTheme({
  colors: {
    bg: {
      100: '#ffffff',
    },
    primary: {
      5: '#333333',
      10: '#666666',
      20: '#999999',
      40: '#0000004d', // deshabilitado color de dot
      60: '#0C1034', // font color en sessions
      80: '#00000080', // botón deshabilitado
      100: '#000000',
      120: '#1A1A1A',
      200: '#333333',
      300: '#4D4D4D',
      400: '#666666',
      500: '#808080',
      600: '#00000040',
      700: '#000000',
      800: '#000000',
      900: '#000000',
    },
    secondary: {
      5: '#ffffff',
      10: '#F2F2F2',
      20: '#E6E6E6',
      30: '#E1E1E1',
      40: '#CCCCCC',
      50: '#BFBFBF',
      60: '#E6E4F0', // color session badge
      70: '#A09CB4',
      80: '#a09fa0', // botón deshabilitado
      100: '#ffffff',
      120: '#ECECEC', // asistente emocional modal info
      150: '#D8D8D8',
      200: '#E6E6E6',
      300: '#CCCCCC',
      400: '#BFBFBF',
      500: '#808080',
      600: '#D1D2D9',
      700: '#F3F1F7',
      800: '#979797',
      900: '#ffffff50',
    },
    tertiary: {
      1: '#ffffff',
      5: '#f2effe',
      10: '#535874',
      20: '#313B5C',
      40: '#0F1D44',
      60: '#00152C',
      80: '#000B14',
      100: '#777194',
      120: '#7C7C9C',
      130: '#85879A',
      200: '#8282A4',
      300: '#8888AC',
      400: '#8E8EB4',
      500: '#9494BC',
      600: '#504B67',
      700: '#120917',
      800: '#FAF2AA4',
      900: '#8E8EB45',
    },
    linen: {
      10: '#FDE1D5', // cards de videos
      20: '#E9EDFB', // cards de chat
      40: '#DCAE9D',
      60: '#D19A8A',
      80: '#C68978',
      100: '#FCECE0',
      200: '#FDEBDF',
      300: '#FFF3F0',
      400: '#F6D6D6',
    },
    babyBlue: {
      10: '#6BA2C1',
      20: '#4396BB',
      40: '#1D8AC5',
      60: '#007ECE',
      80: '#0069D4',
      90: '#3155D3',
      100: '#AFE3FF',
      200: '#B5E7FF',
      300: '#BAEBFF',
      400: '#D6E3F6',
      500: '#CDF1EB',
    },
    violet: {
      10: '#8156FF',
      20: '#7539FF',
      40: '#6822FF',
      60: '#5C0CFF',
      80: '#5000FF',
      90: '#C2B7F8',
      100: '#AD8EFF',
      200: '#B09DFF',
      300: '#B4B4FF',
      400: '#A793D5',
    },
    black: {
      5: '#F2F2F2',

      10: '#CACACA',
      20: '#A1A1A1',
      40: '#797979',
      60: '#515151',
      80: '#282828',
      100: '#000000',
      110: '#0C0C0C',
    },
    neutral: {
      1: '#2D70F1',
      2: '#2766CC',
      3: '#578DF4',
    },
    positive: {
      1: '#31D372',
      2: '#32BF6B',
      3: '#5ADC8E',
      4: '#00FE6633',
      5: '#00FE66',
      6: '#D6F6E3',
      7: '#31D37240',
    },
    negative: {
      1: '#E54B2E',
      2: '#CC392E',
      3: '#EA6F58',
      4: '#FF9A22',
      5: '#D33131',
      6: '#D37D4C',
    },
    yellow: {
      1: '#FDEBC0',
      2: '#FFB600',
      3: '#FDF0E6',
    },
  },
  fontConfig: {
    Quicksand: {
      200: {
        normal: 'Quicksand-Light',
        italic: 'Quicksand-Light',
      },
      400: {
        normal: 'Quicksand',
        italic: 'Quicksand',
      },
      600: {
        normal: 'Quicksand-Medium',
        italic: 'Quicksand-Medium',
      },
      700: {
        normal: 'Quicksand-SemiBold',
        italic: 'QuickSand-SemiBold',
      },
      800: {
        normal: 'Quicksand-Bold',
        italic: 'Quicksand-Bold',
      },
    },
    ObjectSans: {
      400: {
        normal: 'PPObjectSans-Regular',
        italic: 'PPObjectSans-Slanted',
      },
      800: {
        normal: 'PPObjectSans-Bold',
        italic: 'PPObjectSans-BoldSlanted',
      },
    },
    Recoleta: {
      400: {
        normal: 'Recoleta',
        italic: 'Recoleta',
      },
      800: {
        normal: 'Recoleta',
        italic: 'Recoleta',
      },
    },
  },
  config: {},
  fonts: {
    heading: 'Quicksand',
    body: 'Quicksand',
    mono: 'Quicksand',
    recoleta: 'Recoleta',
    quicksand: 'Quicksand',
  },
  components: {
    Button: {
      defaultProps: {
        _hover: {bg: 'primary.40'},
        _text: {numberOfLines: 1, adjustsFontSizeToFit: true},
      },
      variants: {
        // TODO: AGREGAR FUENTES A BOTONES
        primary: () => ({
          bg: 'primary.100',
          rounded: 'full',
          _pressed: {bg: 'primary.80'},
          px: 1,
          size: 'lg',
          _text: {
            color: 'secondary.100',
          },
        }),
        secondary: () => ({
          bg: 'secondary.100',
          rounded: 'full',
          px: 1,
          size: 'lg',
          _text: {
            color: 'tertiary.100',
          },
        }),
        primaryInverted: () => ({
          bg: 'tertiary.5',
          _pressed: {bg: 'tertiary.20'},
          rounded: 'full',
          px: 1,
          size: 'lg',
          _text: {
            color: 'primary.100',
          },
        }),
        tertiary: () => ({
          bg: 'tertiary.100',
          _pressed: {bg: 'tertiary.200'},
          rounded: 'full',
          px: 1,
          size: 'lg',
          _text: {
            color: 'primary.100',
          },
        }),
        transparent: () => ({
          bg: 'light.50',
          rounded: 'full',
          _pressed: {bg: 'light.200'},
        }),
        outline: () => ({
          borderColor: 'primary.100',
          bg: 'transparent',
          _pressed: {
            bg: 'primary.5',
            borderColor: 'primary.120',
            _text: {color: 'primary.120'},
          },
          _text: {color: 'primary.100'},
          rounded: 'full',
          px: 10,
          size: 'lg',
        }),
        outlineSecondary: ({fill}) => ({
          rounded: 'full',
          bg: 'white',
          borderWidth: 2,
          borderColor: 'secondary.100',
          _pressed: {
            bg: 'secondary.100',
            borderColor: 'primary.100',
            _text: {color: 'primary', my: 1},
          },
          _text: {color: 'primary.100', my: 1},
          size: 'lg',
        }),
        outlineSecondarySelected: () => ({
          rounded: 'full',
          bg: 'white',
          borderColor: 'primary.100',
          borderWidth: 2,
          _text: {color: 'primary.100', my: 1},
          size: 'lg',
        }),
        outlineSecondaryGray: ({fill}) => ({
          rounded: 'full',
          bg: 'white',
          borderWidth: 2,
          borderColor: 'secondary.100',
          _pressed: {
            bg: 'secondary.100',
            borderColor: 'secondary.20',
            _text: {color: 'primary', my: 1},
          },
          _text: {color: 'primary.100', my: 1},
          size: 'lg',
        }),
        link: () => ({
          _text: {
            color: 'primary.100',
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
          },
          _pressed: {
            _text: {color: 'primary.80'},
          },
        }),
        outlineNotTransparent: ({isSelected}) => ({
          borderColor: 'secondary.20',
          bg: isSelected ? 'secondary.100' : 'transparent',
          borderWidth: '2',
          _pressed: {
            bg: 'secondary.20',
            borderColor: 'secondary.20',
            _text: {color: 'white'},
          },
          _text: {
            color: isSelected ? 'white' : 'secondary.100',
            alignSelf: 'center',
            numberOfLines: 1,
          },
          size: 'lg',
          rounded: 'full',
        }),
      },
    },
    Input: {
      variants: {
        lila: () => ({
          borderWidth: '1',
          borderColor: 'secondary.20',
          _focus: {
            borderWidth: '1',
            borderColor: 'secondary.20',
          },
        }),
      },
    },
    Select: {
      variants: {
        lila: () => ({borderWidth: '1', borderColor: 'secondary.20'}),
      },
    },
  },
});
