export default {
  modal: {
    title: 'New version',
    body: {
      isNeeded: 'A new Mindsurf update is available.. \n¡Download it now!',
      isRecommended: 'A new Mindsurf update is available.. \n¡Download it now!',
    },
    button: 'Update',
  },
};
