import React from 'react';
import {View} from 'react-native';
import {Pressable} from 'native-base';
import PropTypes from 'prop-types';
import FavIcon from '../icons/FavIcon';
import FavIconEmpty from '../icons/FavIconEmpty';
import {colors} from '../../styles/colors';

const FavButton = ({isFav, onClick}) => {
  return (
    <Pressable
      testID="fav-button"
      style={styleButton}
      onPress={data => {
        onClick && onClick(data);
      }}>
      <View>
        {isFav ? (
          <FavIcon name="fav" h={30} w={30} color={colors.primary100} />
        ) : (
          <FavIconEmpty name="fav" h={30} w={30} color={colors.primary100} />
        )}
      </View>
    </Pressable>
  );
};

const styleButton = [{paddingRight: 2, marginRight: 3}];

FavButton.propTypes = {
  isFav: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default FavButton;
