import {Center} from 'native-base';
import Info from '../../res/svgs/info.svg';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente  Info-icon normal
 */

const InfoIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <Info width={h} height={w} fill={colour} />
    </Center>
  );
};

InfoIcon.propTypes = {
  colour: PropTypes.string,
};
export default InfoIcon;
