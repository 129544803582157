import {useNavigation} from '@react-navigation/native';
import {
  Box,
  Center,
  FormControl,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import validator from 'validator';
import InputForm from '../../components/Form/InputForm';
import NavigateBar from '../../components/NavigateBar';
import PrimaryButton from '../../components/PrimaryButton';
import SelectPhone from '../../components/SelectPhone';
import Tab from '../../components/Tab';
import {LOGIN_TYPES} from '../../constants/codeConstants';
import {RemoteConfigContext} from '../../hooks/SessionContext';
import images from '../../res/images';
import {colors} from '../../styles/colors';
import {isWeb} from '../../helpers/utils/utils';
import {getWidthAndHeight} from '../../helpers/utils/utils';

/**
 * Screen de Inicio de sesión
 */
export default function EntryLogin({
  onSubmit,
  onCreate,
  defaultData,
  forgotPassword,
  loading,
  back,
  commonStylesWeb,
}) {
  const {
    remoteConfig: {loginTypeDefault},
  } = useContext(RemoteConfigContext);
  const {t, i18n} = useTranslation();
  const navigation = useNavigation();
  const {topBarLogo} = images;
  const [lwc, setlwc] = useState(setDefaultProps('lwc'));
  const [validPhoneNumber, setvalidPhoneNumber] = useState(
    setDefaultProps('vphone'),
  );
  const [email, setEmail] = useState(setDefaultProps('email'));
  const [isEmail, setIsEmail] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(setDefaultProps('phone'));
  const [helperTextEmail, setHelperTextEmail] = useState();
  const [helperTextPassword, setHelperTextPassword] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const loginText = lwc
    ? t('login:labelLoginEmail')
    : t('login:labelLoginPhone');
  const {height} = getWidthAndHeight();

  const setCellphone = useCallback(
    val => {
      setPhoneNumber(val);
    },
    [setPhoneNumber],
  );
  const setValidPhone = useCallback(
    val => {
      setvalidPhoneNumber(val);
    },
    [setvalidPhoneNumber],
  );

  function setDefaultProps(field) {
    switch (field) {
      case 'phone':
        if (!defaultData || !defaultData.phone) {
          const code = i18n.language === 'es' ? 'MX' : 'US';
          const iso = i18n.language === 'es' ? '+52' : '+1';
          return {
            codeCountry: code,
            isoCountry: iso,
            number: '',
            inputNum: '',
          };
        }
        return defaultData.phone;
      case 'email':
        if (!defaultData || !defaultData.email) {
          return '';
        }
        return defaultData.email;
      case 'lwc':
        if (defaultData) {
          return !defaultData.email;
        } else {
          return loginTypeDefault === 'PHONE';
        }
      case 'vphone':
        if (defaultData && defaultData.phone) {
          return true;
        }
        return false;
    }
  }

  function validateForm() {
    const validPw = validPassword && password.length > 7;
    const v = lwc ? validPhoneNumber && validPw : validEmail && validPw;
    return v;
  }

  function submit() {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    onSubmit({
      type: lwc ? LOGIN_TYPES.LOGIN_PHONE : LOGIN_TYPES.LOGIN_EMAIL,
      phoneNumber,
      email,
      password,
    });
  }

  const handleEmailChange = text => {
    setEmail(text);
    let reg =
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
    setValidEmail(text.length === 0 || (text.length > 3 && reg.test(text)));
  };

  function onChangePassword(val) {
    setPassword(val);
    const validPW = validator?.isStrongPassword(val, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    setValidPassword(validPW || val.length <= 3);
  }

  useEffect(() => {
    setHelperTextEmail(validEmail ? '' : t('login:inputEmailInvalid'));
    setHelperTextPassword(validPassword ? '' : t('login:inputPasswordInvalid'));
  }, [validEmail, validPassword, isEmail, password]);

  const stylesApp = isWeb()
    ? undefined
    : {space: 2.5, alignSelf: 'center', py: 5};

  return (
    <Box {...stylesApp}>
      <NavigateBar
        navigation={isWeb() ? undefined : navigation}
        headerTitle={
          <Image
            size={'xs'}
            resizeMode={'contain'}
            alt={'logo'}
            source={topBarLogo}
          />
        }
      />

      {/* Titulo */}
      <Box my={3} alignItems="center">
        <Heading
          fontFamily={'recoleta'}
          mt={5}
          color="primary.100"
          fontWeight={800}
          size={'xl'}>
          {t('login:youBackTitle')}
        </Heading>
        <Text fontSize={'md'}>{t('login:goodSeeYou')}</Text>
      </Box>
      {/* Form */}
      <Box my={1}>
        <HStack space={5} justifyContent={'center'} alignItems={'center'}>
          <Tab
            testID={'tab-option'}
            setState={setlwc}
            options={[t('login:phone'), t('login:mail')]}
          />
        </HStack>
      </Box>
      <Box px={5} mt={2}>
        {/* Celphone */}
        {lwc ? (
          <FormControl mb="5">
            {isFocused && (
              <FormControl.Label
                testID={'middle-id-login-phone'}
                _text={{
                  fontSize: 'md',
                  fontWeight: 'normal',
                  color: 'primary.100',
                }}>
                {t('login:inputPhoneNumber')}
              </FormControl.Label>
            )}
            <Box>
              <SelectPhone
                setIsFocused={setIsFocused}
                isFocused={isFocused}
                id={'input-phone'}
                validPhone={validPhoneNumber}
                setValidPhone={setValidPhone}
                cellphone={phoneNumber}
                setCellphone={setCellphone}
                language={i18n.language}
                type={'login'}
                showWarning={false}
              />
            </Box>
            <Box mt={1}>
              <InputForm
                helperText={helperTextPassword}
                label={t('login:inputPasswordLogin')}
                testID={'password-phone-login'}
                setInput={onChangePassword}
                input={password}
                isEmail={!isEmail}
                valid={validPassword}
                showWarning={false}
              />
            </Box>
          </FormControl>
        ) : (
          <Box flexDirection={'row'} flexWrap={'wrap'}>
            <FormControl w={'100%'} mb="2" isInvalid={!validEmail}>
              {/* Email */}
              <Box>
                <InputForm
                  testID={'email-input-login'}
                  helperText={helperTextEmail}
                  label={t('login:inputEmail')}
                  setInput={handleEmailChange}
                  input={email}
                  isEmail={isEmail}
                  valid={validEmail}
                  showWarning={false}
                />
              </Box>
              <Box mt={1}>
                <InputForm
                  testID={'password-input-login'}
                  helperText={helperTextPassword}
                  label={t('login:inputPasswordLogin')}
                  setInput={onChangePassword}
                  input={password}
                  isEmail={!isEmail}
                  valid={validPassword}
                  showWarning={false}
                />
              </Box>
            </FormControl>
          </Box>
        )}
      </Box>
      <Box px={7}>
        <PrimaryButton
          testId={'button-login-entry'}
          onPress={submit}
          extraParams={{
            isLoading: loading,
            isDisabled: !validateForm(),
            mt: 5,
            ...commonStylesWeb,
          }}>
          {t('login:buttonLogin')}
        </PrimaryButton>
        <Center>
          <Box my={2}>
            <Text color={colors.purpleGray}>
              {t('login:forgotPasswordLink')}
            </Text>
            <Center>
              <Link
                textAlign={'center'}
                mb={1}
                _text={{
                  fontWeight: 800,
                  fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
                }}
                isUnderlined={false}
                testID="forgot-button"
                color="primary.100"
                onPress={() => {
                  forgotPassword({email, phoneNumber});
                }}>
                {t('login:recoverAccess')}
              </Link>
            </Center>
          </Box>
          <Link
            testID="create-account"
            mt={1}
            isUnderlined={false}
            _text={{
              fontSize: 'md',
              fontWeight: 800,
              fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
            }}
            color="primary.100"
            onPress={onCreate}>
            {t('login:register')}
          </Link>
        </Center>
      </Box>
    </Box>
  );
}
