export default {
  activeFree: 'Tienes el plan gratuito activo.',
  buttonToPlans: 'Suscribirme',
  recovery: 'Quiero recuperarla',
  recoverTitle: 'La versión premium te espera ✨',
  freeFinished: 'Tu prueba gratuita del plan ilimitado terminó.',
  title: 'Planes de suscripción',
  trial: 'Tienes $dias días de versión premium ⏳',
  currentPlan: 'Plan actual',
  iOSCancelPlanTitle:
    'Puedes cancelar tu subscripción en la app Configuración.',
  iOSCancelPlanStep1: '1. Abre la app Configuración.',
  iOSCancelPlanStep2: '2. Toca tu nombre.',
  iOSCancelPlanStep3: '3. Toca "Suscripciones".',
  iOSCancelPlanStep4: '4. Toca la suscripción que quieras administrar.',
  iOSCancelPlanStep5: '5. Toca "Cancelar suscripción".',
  androidCancelPlanTitle:
    'Puedes cancelar tu subscripción en la app Google Play Store.',
  androidCancelPlanStep1: '1. Abre la app Google Play Store.',
  androidCancelPlanStep2: '2. Toca el menú de la esquina superior derecha.',
  androidCancelPlanStep3: '3. Presiona Pagos y Suscripciones > Subscripciones.',
  androidCancelPlanStep4: '4. Toca la suscripción que quieras administrar.',
  androidCancelPlanStep5: '5. Toca "Cancelar suscripción".',
  androidCancelPlanStep6: '6. Sigue las instrucciones.',
  manualCancel:
    'Para cancelar tu suscripción, escribe al correo de contacto y soporte.',
  close: 'Cerrar',
  successPayment: '¡Pago realizado con éxito!',
  successPaymentMessage:
    '¡Gracias por tu compra! Puedes cancelar tu suscripción en cualquier momento.',
  monthly: '{{price}} al mes',
  yearly: '{{price}} anual',
  secureApple:
    'Puedes cancelar cuando quieras.\nAsegurado a través de App Store.',
  secureAndroid:
    'Puedes cancelar cuando quieras.\nAsegurado a través de Google Play.',
  subscriptionTitle: 'Suscripción',
  cancelSubscription: 'Cancelar suscripción',
  versionPremium: 'Versión premium',
  versionDesc: 'Tú y la persona que elijas tienen todos estos beneficios:',
  cancelDesc: 'Si cancelas, ya no podrás:',
  continueCancel: 'Continuar con la cancelación',
  instructionsCancel: 'Instrucciones para cancelar',
  unlimitedAccess:
    'Acceso ilimitado a todos los ejercicios, sesiones en vivo y terapia digital a su ritmo.',
  psychologicalHelp:
    'Ayuda psicológica 24/7 en crisis emocionales a través de una línea telefónica de apoyo.',
  personalized:
    'Recomendaciones personalizadas mediante el análisis del diario emocional con inteligencia artificial.',
  emotionalSupport:
    'Soporte emocional en sesiones mensuales de grupos de apoyo.',
  accessContent:
    'Acceder cuando quieras a todos los contenidos y progresar en la terapia digital al ritmo que tú decidas.',
  psychologicalAssistance:
    'Pedir asistencia psicológica por teléfono si estás pasando por una crisis emocional.',
  personalizedRecommendations:
    'Recibir recomendaciones de ejercicios hechas a tu medida y entrar a las sesiones de apoyo grupal.',
  shareSubscription:
    'Compartir tu suscripción con otra persona, quien también perderá sus beneficios.',
  step1Android: 'Abre la app de Google Play en tu dispositivo Android.',
  step2Android: 'En la esquina superior derecha, presiona el icono de perfil.',
  step3Android: 'Toca Pagos y Suscripciones > Suscripciones.',
  step4Android: 'Selecciona la suscripción a Mindsurf.',
  step5Android: 'Presiona Cancelar suscripción.',
  step1Ios: 'Abre la configuración.',
  step2Ios: 'Toca tu nombre.',
  step3Ios: 'Toca Suscripciones.',
  step4Ios: 'Toca la suscripción a Mindsurf.',
  step5Ios: 'Toca Cancelar suscripción.',
  actualPlan: 'Actualmente cuentas con:',
  dateExpiration: 'Fecha de expiración:',
};
