import {ScaledSheet} from 'react-native-size-matters';
import {chat} from './chat';
import {markdown} from './markdown';
import {phoneCountry} from './phoneCountry';

/* istanbul ignore next */
export function phoneCountryStyles(overrides = {}) {
  return ScaledSheet.create({
    ...phoneCountry,
    ...overrides,
  });
}

/* istanbul ignore next */
export function createChatStyles(overrides = {}) {
  return ScaledSheet.create({
    ...chat,
    ...overrides,
  });
}

/* istanbul ignore next */
export function createMarkdownStyles(overrides = {}) {
  return ScaledSheet.create({
    ...markdown,
    ...overrides,
  });
}
