export default {
  buttonBack: 'BACK',
  buttonStartContent: 'Let’s start',
  buttonStart: 'Let’s start',
  firstMenuTitle: 'What do you want to work in today?',
  select3: 'Select up to 3',
  showMore: 'Show more',
  next: 'Next',
  finish: 'See results',
  done: 'Finish',
  pauseTitle: "You're pausing your evaluation",
  titleIntro: '$evaluation evaluation',
  therapy: 'THERAPY',
  therapyDsc: 'Book a therapy session with a specialist.',
  pauseDsc:
    "This evaluation is to learn how to help you. If you can't finish it now, pick it up next time where you left off.",
  attention1: 'Initial attention',
  attention1Dsc: 'You have mild symptoms of this ailment.',
  attention2: 'Required attention',
  attention2Dsc: 'You have moderate symptoms of this ailment.',
  attention3: 'Urgent attention',
  attention3Dsc:
    'You have severe symptoms of this ailment, and it is important that you treat it as soon as possible.',
  reportTitle: '$evaluation report',
  reportTitleOneRow: '{{$evaluation}} Report',
  reportDate: 'You evaluated yourself on $date',
  reportDsc:
    'Read this report attentively because it’ll help you see how you’re doing, as well as to see if you need any improvement in your emotional wellness.',
  levelAttention: 'Your risk level is:',
  buttonTherapy: 'Explore therapists',
  buttonClose: 'Close',
  buttonHome: 'Home',
  pauseSession: 'Pause session',
  minutesLabel: 'I’ll show you a brief multiple-choice questionnaire.',
  explore: "Let's explore",
  startSession: 'Start session 1',
  minutes: 'minutes',
  titleCardTime: 'Evaluation',
  chat: 'Chat',
  evaluationHeader: '{{title}}',
  evaluationModalDone:
    'Perfect 🤩! You have completed this questionnaire. Thank you for responding!',
};
