import React, {useState} from 'react';
import {Text, Center, Box, Pressable, Spinner} from 'native-base';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

/**
 * Componente  AudioTrack.web normal
 */

const AudioTrack = ({
  setIsVisible = () => {},
  setFinish = () => {},
  title,
  id,
  isRecommended,
  contentUrl,
  duration,
  destroyOnUnmount = false,
  onFinish,
  onStart = () => {},
  onPlayPause = () => {},
}) => {
  const [progress, setProgress] = useState(0);
  return (
    <Box>
      <Center roundedTop={'md'} w={'100%'} bg={'primary.5'}>
        {!isRecommended ? (
          <Text
            numberOfLines={1}
            py={1}
            color={'primary.100'}
            fontWeight={800}
            fontSize={'sm'}>
            {title}
          </Text>
        ) : null}
      </Center>
      <Center
        bg={'primary.100'}
        p={5}
        roundedTop={isRecommended ? 'full' : 'none'}
        roundedBottom={isRecommended ? 'full' : 'md'}>
        <ReactPlayer
          onEnded={() => {
            setFinish(true);
            onFinish(100);
          }}
          onStart={() => {
            onStart();
          }}
          onPlay={() => {
            onPlayPause(progress);
          }}
          onPause={() => {
            onPlayPause(progress);
          }}
          onProgress={e => {
            setProgress(e?.playedSeconds);
          }}
          width={'90%'}
          url={contentUrl}
          controls
          height="50px"
        />
      </Center>
    </Box>
  );
};

AudioTrack.propTypes = {
  setIsVisible: PropTypes.func,
  setFinish: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
  isRecommended: PropTypes.bool,
  contentUrl: PropTypes.string,
  duration: PropTypes.number,
  destroyOnUnmount: PropTypes.bool,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
  onPlayPause: PropTypes.func,
};
export default AudioTrack;
