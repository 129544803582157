import {Box, Center, Text} from 'native-base';
import React, {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {colors} from '../../styles/colors';
import PrimaryButton from '../PrimaryButton';
import {ROUTE_NAMES} from '../../navigation/MainNavigator';

const EvaluationCard = forwardRef((props, ref) => {
  const {extraData, onRedirectAndClose} = props;
  const {t, i18n} = useTranslation();
  const {title, description} = extraData?.display_details;
  const paramsToNavigate = extraData?.display_details?.route?.params;

  return (
    <Box
      testID={'bot-evaluation'}
      borderRadius={20}
      px={5}
      py={4}
      borderWidth={1}
      borderColor={colors.primary100}
      bg={'white'}>
      <Center>
        <Center
          bg={'secondary.60'}
          borderRadius={20}
          px={5}
          py={2}
          opacity={0.5}>
          <Text fontWeight={800} color={'primary.60'}>
            {t('program:evaluation')}
          </Text>
        </Center>
      </Center>

      <Center my={3}>
        <Text
          alignSelf={'baseline'}
          fontSize={'2xl'}
          fontFamily={'recoleta'}
          fontWeight={800}
          color={colors.primary100}>
          {`${t('evaluations:titleIntro').replace('$evaluation', title)}`}
        </Text>
        <Text fontWeight={700} my={2} color={'tertiary.100'} fontSize={'sm'}>
          {description}
        </Text>
      </Center>
      <PrimaryButton
        onPress={() => {
          onRedirectAndClose({
            route: ROUTE_NAMES.evaluation,
            params: paramsToNavigate,
          });
        }}>
        {t('chat:startEvaluation')}
      </PrimaryButton>
    </Box>
  );
});

export default EvaluationCard;
