/* istanbul ignore file */
//import functions from '@react-native-firebase/functions';
import {LOGIN_TYPES} from '../constants/codeConstants';
import {reportError} from '../helpers/crashlytics/fbCrashlytics';
import {fbFunctions} from './firebase/firebaseAuth';
import {Platform} from 'react-native';
/**
 *
 * CMS DATA NEW
 * @params endpoint: url
 */

const os = Platform.OS;

/** Nueva Función de CMS Data */
async function getCMSV2Call(url) {
  const httpsCallableCMS = fbFunctions.httpsCallable('cmsDataV2');
  try {
    const response = await httpsCallableCMS({
      endpoint: url,
    });
    return response.data;
  } catch (e) {
    console.log('Error', e);
    reportError('cms', {url}, 'cmsDataV2', e);
    throw new Error(e);
  }
}

/** login  */
async function authPhoneLogin(dataObject) {
  const httpAuthPhone = fbFunctions.httpsCallable('authPhoneLogin');
  return httpAuthPhone(dataObject)
    .then(({data}) => data)
    .catch(e => {
      console.log('**', e);
      reportError('cms', {data: dataObject}, 'authPhoneLogin', e);
      throw new Error('error:generalDsc');
    });
}

/** Login with Employee Number */
export const authSingleEmployeeLogin = async dataObject => {
  const httpAuthEmployee = fbFunctions.httpsCallable('authSingleEmployeeLogin');
  return httpAuthEmployee(dataObject)
    .then(async data => {
      console.log('authSingleEmployeeLogin response data', data);
      const {status, token} = data.data;
      if (status === 'LOGGED' || status === 'CREATED') {
        return token;
      }
      let message =
        status === 'UNAUTHORIZED_B2B'
          ? 'error:employeeLoginInvalid'
          : 'error:generalDsc';
      throw {
        message,
        code: status,
      };
    })
    .catch(e => {
      console.log('**', e);
      reportError('cms', {data: dataObject}, 'authSingleEmployeeLogin', e);
      if (e.code === 'UNAUTHORIZED_B2B') {
        throw e;
      }
      throw new Error('error:generalDsc');
    });
};

/** Checar si el codigo que se ingresó viene de whats o de llamada */
export const checkCallCenterCode = async (
  phone,
  phoneRefCode,
  verificationCode,
) => {
  const checkCall = fbFunctions.httpsCallable('authCheckAlternativeCode');
  try {
    const {number} = phone;
    const params = {
      phone: number,
      phoneRefCode: phoneRefCode,
      code: verificationCode,
    };
    const {data} = await checkCall(params);
    return data;
  } catch (e) {
    reportError(
      'cms',
      {phone, phoneRefCode, verificationCode},
      'authCheckAlternativeCode',
      e,
    );
    console.log(e?.description);
    throw new Error(e?.message);
  }
};

/** Validar si el codigo de la empresa es correcto  */
export const checkPolicy = async police => {
  const path = `membership/policy_availability/${police}`;
  try {
    const {status} = await getCMSV2Call(path);
    console.log('status membership: ', status);
    return status === 'SUCCESS';
  } catch (e) {
    console.log(e);
    reportError('cms', {path}, 'getCMSV2Call', e);
    return false;
  }
};

/**  Revisa si está logueado con PHONE y si tiene PASSWORD. */
export const isRegisterAndHavePassword = async phone => {
  try {
    const responseAuth = await authPhoneLogin({phone});
    const {authType, appVersion} = responseAuth;
    console.log(
      'isRgisterAndHavePassword       -----------------------PPPPPPPPPPPPPPPPPP',
    );
    console.log('authType');
    console.log(authType);
    console.log('responseAuth');
    console.log(JSON.stringify(responseAuth));

    // Current response
    // {"authType":"LOGIN_PHONE_NO_PASSWORD","status":"NEED_INFO"}
    return {
      isLogin:
        authType === LOGIN_TYPES.LOGIN_PHONE_PASSWORD ||
        (authType === LOGIN_TYPES.LOGIN_PHONE_NO_PASSWORD && !!appVersion),
      appVersion: appVersion,
      hasPassword: authType === LOGIN_TYPES.LOGIN_PHONE_PASSWORD,
    };
  } catch (e) {
    console.log('cms Linea 33', e);
    throw new Error('error:generalDsc');
  }
};

/** Guarda la Contraseña  */
export const savePassword = async password => {
  const httpsCallableCMS = fbFunctions.httpsCallable('authSetPhonePassword');
  return httpsCallableCMS({password})
    .then(response => {
      console.log('response');
      console.log(response);
      const {data} = response;
      if (data?.status !== 'SUCCESS') {
        reportError(
          'cms',
          {},
          'authSetPhonePassword',
          new Error('Error al guardar la contraseña'),
        );
        throw {
          message: 'error:generalDsc',
          description: data,
        };
      }
      return data;
    })
    .catch(e => {
      reportError('cms', {}, 'authSetPhonePassword', e);
      console.log(e?.description);
      throw new Error(e?.message);
    });
};

/** Creación de usuarios en Back despues de haber realizado todo el flujo */
export const createUser = async (
  typeUser,
  policy,
  code = undefined,
  anonymous = false,
  anonymous_id = undefined,
) => {
  const params = {
    typeUser: anonymous ? 'anonymous' : typeUser ? 'B2B' : 'B2C',
    code,
    anonymous_id,
  };
  if (policy) {
    params.policy = policy;
  }
  const authCreateProfile = fbFunctions.httpsCallable('authCreateProfile');
  return authCreateProfile(params)
    .then(({data}) => {
      console.log('>>>>>', JSON.stringify(data));
      if (data?.status === 'ERROR') {
        reportError(
          'cms',
          {params, data},
          'authCreateProfile',
          new Error('Error al crear el usuario'),
        );
        throw {
          message: 'error:generalDsc',
          description: data?.description,
        };
      }

      return data;
    })
    .catch(e => {
      console.log(e);
      reportError('cms', {params}, 'authCreateProfile', e);
      throw new Error('error:generalDsc');
    });
};

/** Solicitar código por llamada o whats authCheckAlternativeCode */
export const requestCall = async (
  phone,
  callCenterCode,
  typeOfCall,
  language,
) => {
  const request = fbFunctions.httpsCallable('authRequestAlternativeCode');
  try {
    const {number, codeCountry, isoCountry} = phone;
    const params = {
      internationalPhoneCode: isoCountry.replace('+', ''),
      phoneCodeISOCountry: codeCountry,
      phone: number,
      phoneRefCode: callCenterCode,
      typeOfCall: typeOfCall,
      language,
    };
    const {data} = await request(params);
    if (data?.status !== 'SUCCESS') {
      reportError(
        'cms',
        {params, data},
        'authRequestAlternativeCode',
        new Error('Error al solicitar el código'),
      );
      throw {
        message: 'error:generalDsc',
        description: data,
      };
    }
  } catch (e) {
    console.log(e?.description);
    reportError(
      'cms',
      {phone, callCenterCode, typeOfCall},
      'authRequestAlternativeCode',
      e,
    );
    throw new Error(e?.message);
  }
};

/** login propio con celular y password */
export const loginWithPhoneAndPassword = async (phone, password) => {
  return authPhoneLogin({phone, password})
    .then(async ({status, token}) => {
      if (status === 'LOGGED') {
        return token;
      }
      const message =
        status === 'BAD_CREDENTIALS'
          ? 'error:loginInvalid'
          : 'error:generalDsc';
      throw {
        message,
        description: status,
      };
    })
    .catch(e => {
      console.log(e?.description);
      throw new Error(e?.message);
    });
};

/** Enviar codigo de verificacion por email  */
export const sendVerificationEmail = async (email, type, language) => {
  const authSendCode = fbFunctions.httpsCallable('authSendCodeToEmail');
  try {
    const {data} = await authSendCode({email, language, os});
    switch (data.status) {
      case 'BAD_CREDENTIALS':
        return type.includes('B2B')
          ? LOGIN_TYPES.LOGIN_EMAIL_B2B
          : LOGIN_TYPES.LOGIN_EMAIL;
      case 'SUCCESS':
        return type.includes('B2B')
          ? LOGIN_TYPES.REGISTER_EMAIL_B2B
          : LOGIN_TYPES.REGISTER_EMAIL;
      default:
        reportError(
          'cms',
          {
            email,
            type,
            data,
          },
          'authSendCodeToEmail',
          new Error('Error al Enviar codigo de verificacion por email'),
        );
        throw {
          message: 'error:generalDsc',
          description: data,
        };
    }
  } catch (e) {
    console.log(e?.description);
    reportError('cms', {email, type}, 'authSendCodeToEmail', e);
    throw new Error(e?.message);
  }
};

/** Verificar Email por Código */
export const verifyEmailCode = async (email, code) => {
  const authVerifyCode = fbFunctions.httpsCallable('authCheckEmailCode');
  const {data} = await authVerifyCode({email, code});
  if (data?.status !== 'SUCCESS' && data?.status !== 'LOGGED' && !data?.token) {
    throw new Error('error:invalidCode');
  }
  return data?.token || null;
};

/* change email password */
export const changeEmailPassword = async password => {
  const authChangePassword = fbFunctions.httpsCallable('authSetEmailPassword');
  return authChangePassword({password})
    .then(response => {
      console.log('response');
      console.log(response);
      const {data} = response;
      if (data?.status !== 'SUCCESS') {
        reportError(
          'cms',
          {},
          'authSetEmailPassword',
          new Error('Error al guardar la contraseña'),
        );
        throw {
          message: 'error:generalDsc',
          description: data,
        };
      }
      return data;
    })
    .catch(e => {
      reportError('cms', {}, 'authSetPhonePassword', e);
      console.log(e?.description);
      throw new Error(e?.message);
    });
};
