import React, {useState, useContext} from 'react';
import {Text, Center, Modal} from 'native-base';
import {useTranslation} from 'react-i18next';
import {RemoteConfigContext} from '../hooks/SessionContext';

/**
 * Componente  ModalThanks normal
 */

const ModalThanks = ({showModal, setShowModal}) => {
  const {i18n} = useTranslation();
  const {
    remoteConfig: {gpt3Diary},
  } = useContext(RemoteConfigContext);
  const [language] = useState(i18n?.language);
  const gpt3Labels = {
    afterTitle: gpt3Diary?.feedback?.afterTitle?.[language] || '',
    afterDescription: gpt3Diary?.feedback?.afterDescription?.[language] || '',
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={'sm'}
      _backdrop={{opacity: 0.6, bg: 'primary.100'}}>
      <Modal.Content bg={'secondary.100'} borderColor={'primary.5'}>
        <Modal.CloseButton
          testID="close-btn"
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
        />
        <Modal.Body mt={8} p={5}>
          <Center testID={'thanks-feedback'}>
            <Text
              numberOfLines={2}
              adjustsFontSizeToFit={true}
              fontSize={'lg'}
              color={'primary.100'}
              fontWeight={800}>
              {gpt3Labels?.afterTitle}
            </Text>
            <Text
              textAlign={'center'}
              mt={3}
              fontSize={'lg'}
              color={'primary.100'}>
              {gpt3Labels?.afterDescription}
            </Text>
          </Center>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ModalThanks;
