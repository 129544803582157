import {Center} from 'native-base';
import Warning from '../../res/svgs/warning.svg';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente  Info-icon normal
 */

const WarningIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <Warning width={h} height={w} fill={colour} />
    </Center>
  );
};

WarningIcon.propTypes = {
  colour: PropTypes.string,
};
export default WarningIcon;
