import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowRoundBack, IoIosEye, IoIosEyeOff, IoIosMenu, IoIosWarning, IoMdInformationCircle} from "react-icons/io";
import { Pressable } from "native-base";



/**
 * Componente  IconIon.native normal
 */

const IconIon =  ({name, size, style, onPress, color}) => {
  const styles = {
    fontSize: size >= 30 && size <= 50 ? '3rem' : size,
    ...style,
  };
  const props = {
    style: styles,
    color,
  };
  switch (name) {
    case 'arrow-back-outline':
      return <Pressable onPress={onPress}>
        <IoIosArrowRoundBack {...props} />
      </Pressable> ;
    case 'eye-outline':
      return <Pressable onPress={onPress}>
        <IoIosEye {...props} />
      </Pressable> ;
    case 'eye-off-outline':
      return <Pressable onPress={onPress}>
        <IoIosEyeOff {...props} />
      </Pressable> ;
    case 'menu':
      return <Pressable onPress={onPress}>
        <IoIosMenu {...props} />
      </Pressable> ;
    case 'warning':
      return <Pressable onPress={onPress}>
        <IoIosWarning {...props} />
      </Pressable> ;
    case 'info':
      return <Pressable onPress={onPress}>
        <IoIosMenu {...props} />
      </Pressable> ;
    default:
      return  <IoIosArrowRoundBack/>;
  }
};

IconIon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  onPress: PropTypes.func,
};
export default IconIon;
