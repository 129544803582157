import React from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';

/**
 * Componente  AnimatableBox.web normal
 */

const AnimatableBox = ({
  children,
  animation = 'fadeIn',
  duration = 2000,
  style = {},
}) => {
  return <View style={style}>{children}</View>;
};

AnimatableBox.propTypes = {
  text: PropTypes.string,
};
export default AnimatableBox;
