export default {
  descriptionLabel: 'Description',
  duration: 'Duration: ',
  typeExcercise: 'excercise',
  lastRecord: 'Last interaction: {{date}}',
  minutes: '{{duration}} minutes',
  recomendation: 'You may also like:',
  seeHistory: 'See history',
  historyTitle: 'Interaction history',
  lastInteraction: 'Last interaction: ',
};
