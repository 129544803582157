export default {
  profiling: 'Cuéntame más de ti',
  seeAll: 'Ver todo',
  tabBarLabelHome: 'Inicio',
  tabBarLabelPrograms: 'Terapia\ndigital',
  tabBarLabelTherapy: 'Terapeutas',
  tabBarLabelTools: 'Ejercicios',
  tabBarLabelAssistant: 'Asistente',
  workToday: '¿En qué quieres\ntrabajar hoy?',
  useFilter: 'Usa los filtros para elegir un tema.',
  name: 'Mindsurf',
};
