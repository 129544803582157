import {Center, Modal, Text} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

const SmallModalMinimalist = ({
  testID,
  message,
  showModal,
  setShowModal,
  submit = () => {},
  size = 'md',
  children,
}) => {
  if (!showModal) return <></>;
  return (
    <Modal
      testID={testID}
      isOpen={showModal}
      size={size}
      _backdrop={{
        opacity: 0.3,
        bg: 'primary.100',
        onPress: () => {
          setShowModal(false);
        },
      }}>
      <Modal.Content borderRadius={'3xl'} bg={'white'}>
        <Modal.CloseButton
          testID={'close-modal-button'}
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
          onPress={() => {
            setShowModal(false);
            submit();
          }}
        />
        <Modal.Body mt={5} py={8} px={3}>
          {children ? (
            children
          ) : (
            <Center>
              <Text
                w={'100%'}
                textAlign={'center'}
                adjustsFontSizeToFit={true}
                fontSize={'md'}
                color={'black.40'}>
                {message}
              </Text>
            </Center>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

SmallModalMinimalist.propTypes = {
  testID: PropTypes.string,
  message: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  submit: PropTypes.func,
};

export default SmallModalMinimalist;
