/*
 * En este custom hook vamos a manejar si el usuario tiene acceso a diversos
 * modulos de la app.
 * Este hook de mandará llamar en cualquier parte de la app y con esto nos
 * ahorraremos hacer un query de usuario en cada pantalla y la funcion de
 * redirectIfNotHavePrivileges
 *
 * 1. Traernos el contexto del usuario y actualizarlo si es necesario.
 * 2. Hacer un query para traer los privilegios del usuario.
 * 3. Guardar los privilegios en el estado.
 * 4. Retornar los privilegios.
 * 5. Checar si tiene acceso a entrar a la pantalla o no
 * */

import {useContext, useEffect, useState} from 'react';
import {
  FeatureFlagsContext,
  initialize,
  SessionContext,
  UserContext,
  userInitialize,
} from './SessionContext';
import {useQuery} from '@apollo/client';
import {GET_SUBSCRIPTIONS_ACTIVE} from '../graphql/queries/memberships';
import {navigateTo, ROUTE_NAMES} from '../navigation/MainNavigator';
import {logEvent, resetRudder} from '../helpers/analytics/fbAnalytics';
import {singOut} from '../core/firebase/firebaseAuth';
import {clearItems} from './useAsyncStorageData';

export default function usePrivileges() {
  const {user, setUser} = useContext(UserContext);
  const {setSession} = useContext(SessionContext);
  const {featureFlags} = useContext(FeatureFlagsContext);
  const [subscription, setSubscription] = useState(user?.suscriptionStatus);

  const {data} = useQuery(GET_SUBSCRIPTIONS_ACTIVE, {
    variables: {
      active: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  // useEffect actualizando user y teniendo la suscripción activa.
  useEffect(() => {
    if (data && data?.subscriptions && data?.subscriptions?.length > 0) {
      const activeSubscription = data?.subscriptions[0];
      setSubscription(activeSubscription);
      if (activeSubscription?.id !== user?.suscriptionStatus?.id) {
        // hacer setter solo si es diferente
        setUser({...user, suscriptionStatus: activeSubscription});
      }
    }
  }, [data]);

  // obtener si un modulo está activo o no.
  function isModuleActive(moduleName) {
    return featureFlags?.[moduleName];
  }

  // redirigir si no tiene privilegios
  /*
   * La función checa si tiene privilegios, si no manda a
   * pantalla de planes de suscripción
   *
   * */
  function redirectIfNotHavePrivileges({
    moduleName,
    navigateParams,
    from,
    fromExtra,
  }) {
    try {
      // 2. checar si el modulo está en la suscripción
      // 3. si no, redirigir a la pantalla de planes de suscripción
      const moduleToPass = !!subscription?.plan?.[moduleName];

      // si no tiene acceso o no tiene prueba trial
      if (!moduleToPass) {
        logEvent('GoToPlans', {
          from,
          currentPlan: subscription,
          moduleToSee: module,
          ...(fromExtra || {}),
        });

        navigateTo(ROUTE_NAMES.subscription, {
          successRoute: navigateParams,
        });
        return;
      }
      return true;
    } catch (e) {
      // Por que se relanza el error?
      // reportError('usePrivileges', {}, 'redirectIfNotHavePrivileges', e);
      throw new Error('NO_ACCESS', e);
    }
  }

  //console.log('::) ', JSON.stringify(subscription, null, 2));

  async function logout(routeName, client) {
    try {
      await logEvent('ProfileLogout', {
        user: user?.userFB?.email?.includes('donotsend.com')
          ? user?.userFB?.user?.phoneNumber
          : user?.userFB?.email,
      });
      await singOut();
    } catch (e) {
      console.log('error', e);
    }
    setSession({...initialize});
    setUser({...userInitialize});
    await clearItems([]);
    navigateTo(routeName, {}, true);
    resetRudder();
    await client?.resetStore();
  }

  return {
    user,
    subscription,
    isModuleActive,
    redirectIfNotHavePrivileges,
    logout,
  };
}
