import {Button, Center} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import {createChatStyles} from '../styles/base';

/**
 * Botón mindful color azul con las medidas y estilos
 *
 * @param extraParams si el boton necesita parametros extra (acepta parametros de native base)
 * @param testId
 * @param variant variante de native base
 * @param onPress evento click
 * @param children lo que va dentro del boton
 */
// este componente no tiene UT
const PrimarySmallButton = ({
  extraParams = {},
  disabled = false,
  testId,
  variant = 'primary',
  onPress,
  children,
}) => {
  const chatStyles = createChatStyles();

  return (
    <Center>
      <Button
        {...extraParams}
        disabled={disabled}
        testID={testId}
        px={12}
        minW={'25%'}
        minH={47}
        bg={disabled ? 'primary.80' : 'primary.100'}
        _pressed={{bg: 'primary.80'}}
        variant={variant}
        _text={{
          fontSize: 'md',
          fontWeight: 800,
        }}
        onPress={() => onPress()}>
        {children}
      </Button>
    </Center>
  );
};

PrimarySmallButton.propTypes = {
  extraParams: PropTypes.object,
  testId: PropTypes.string,
  variant: PropTypes.string,
  onPress: PropTypes.func,
  children: PropTypes.node,
};
export default PrimarySmallButton;
