import {Center} from 'native-base';
import Fav from '../../res/svgs/favorites.svg';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Componente  BackIcon normal
 */

const FavIcon = ({colour = 'white', h = 20, w = 20}) => {
  return (
    <Center pr={2} pl={2}>
      <Fav width={h} height={w} fill={colour} />
    </Center>
  );
};

FavIcon.propTypes = {
  colour: PropTypes.string,
};
export default FavIcon;
