import buttons from './buttons';
import chat from './chat';
import diary from './diary';
import emergency from './emergency';
import error from './error';
import evaluations from './evaluations';
import lives from './lives';
import login from './login';
import menu from './menu';
import onboarding from './onboarding';
import options from './options';
import profile from './profile';
import program from './program';
import screens from './screens';
import subscriptions from './subscriptions';
import therapy from './therapy';
import version from './version';
import introex from './introex';
import exercises from './exercises';

export default {
  buttons,
  chat,
  diary,
  emergency,
  error,
  evaluations,
  lives,
  login,
  menu,
  onboarding,
  profile,
  program,
  screens,
  subscriptions,
  options,
  therapy,
  version,
  introex,
  exercises,
};
