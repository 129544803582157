import {ScaledSheet} from 'react-native-size-matters';

const colours = {
  primary100: '#0C0F5D',
  primary40: '#7F81AA',
  secondary100: '#593DED',
  secondary20: '#C2B7F8',
  tertiary5: '#FFFCF2',
  tertiary100: '#FFBB00',
  tertiary20: '#FFE6A1',
  negative1: '#DE1515',
  black40: '#797979',
};

const dotStyles = {
  // Pagination dot
  dot: {
    backgroundColor: colours.tertiary20,
    width: 6, //6
    height: 6, //6
    borderRadius: 10,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  // Active dot
  activeDot: {
    backgroundColor: colours.tertiary100,
  },
  // Rectangule
  dotRectangule: {
    backgroundColor: colours.tertiary20,
    width: 8,
    height: 24,
    // borderRadius: 10,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
};

/* istanbul ignore next */
export function swiperStyle(overrides = {}) {
  return ScaledSheet.create({
    ...dotStyles,
    ...overrides,
  });
}

/* istanbul ignore next */
export default {
  swiperStyle,
  colours,
};
