export const colors = {
  primary2: '#E8E9EC', // color del boton de boz assistant
  primary5: '#333333',
  primary10: '#666666',
  primary20: '#999999',
  primary40: '#CCCCCC',
  primary60: '#E6E6E6',
  primary70: '#E0E0E0',
  primary80: '#00000080',
  primary100: '#000000',
  primary120: '#1A1A1A',
  secondary5: '#ffffff',
  secondary10: '#F2F2F2',
  secondary20: '#E6E6E6',
  secondary40: '#CCCCCC',
  secondary60: '#808080',
  secondary70: '#A09CB4',
  secondary80: '#a09fa0',
  secondary100: '#ffffff',
  secondary120: '#F2F2F2',
  secondary600: '#D1D2D9',
  tertiary1: '#ffffff',
  tertiary5: '#f2effe',
  tertiary7: '#9F9BB4',
  tertiary8: '#E1E1E1',
  tertiary10: '#535874',
  tertiary20: '#313B5C',
  tertiary40: '#0F1D44',
  tertiary50: '#8B8AAD80',
  tertiary60: '#00152C',
  tertiary70: '#8E8EB4',
  tertiary80: '#000B14',
  tertiary100: '#777194',
  tertiary120: '#7C7C9C',
  tertiary130: '#7A7593',
  linen100: '#FCECE0',
  linen400: '#F6D6D6',
  babyBlue90: '#3155D3',
  babyBlue100: '#AFE3FF',
  babyBlue200: '#CDF1EB',
  babyBlue400: '#D6E3F6',
  violet100: '#AD8EFF',
  negative1: '#E54B2E',
  negative2: '#CC392E',
  negative3: '#EA6F58',
  negative4: '#FF9A22',
  negative5: '#D33131',
  positive1: '#31D372',
  positive2: '#32BF6B',
  positive3: '#5ADC8E',
  positive4: '#00FE6633',
  positive5: '#00FE66',
  black5: '#F2F2F2',
  black10: '#CACACA',
  black20: '#A1A1A1',
  black40: '#797979',
  black60: '#515151',
  black80: '#282828',
  black100: '#000000',
  white: '#ffffff',
  purple: '#9070C8',
  purplePastel: '#EEEBFC',
  purpleDark: '#5437E3',
  purpleLight: '#B7AEE4',
  purpleGray: '#7F85AB',
  purpleDarkGray: '#A793D5',
  peach: '#F1A880',
  perry: '#69A09B',
  darkLight: '#424B5A',
  warning: '#F1CDCF',
  red: '#FF4A4A',
  yellow1: '#FDEBC0',
  transparent: 'transparent',
  yellow: '#FFB600',
};
