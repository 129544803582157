import AsyncStorage from '@react-native-async-storage/async-storage';
import {Mutex, withTimeout} from 'async-mutex';
import {Box} from 'native-base';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import ChatContainer from '../../components/Chat/ChatContainer/ChatContainer';
import ChatSkeleton from '../../components/Chat/ChatSkeleton/ChatSkeleton';
import {
  BOX_ASSISTANT_COMPONENTS,
  CHAT_MODULE,
  CHAT_TYPES,
  MESSAGE_TYPES,
} from '../../constants/codeConstants';
import {
  disconnect,
  getConnection,
  getMessages,
  sendMessageToFB,
} from '../../core/chat/chatServices';
import {isModuleActive, MODULES} from '../../core/featureFlagService';
import {
  logMainScreen,
  logRudderEvent,
  logSimpleEvent,
} from '../../helpers/analytics/fbAnalytics';
import {secsToRead, storageFeedbackMessage} from '../../helpers/chatUtils';
import {orderAndFormatMessages} from '../../helpers/utils/utils';
import {ChatContext, defaultChat} from '../../hooks/ChatContext';
import {
  BoxAssistantContext,
  FeatureFlagsContext,
  PushStateContext,
  RemoteConfigContext,
  UserContext,
} from '../../hooks/SessionContext';
import {navigationRef} from '../../navigation/MainNavigator';
import {useTranslation} from 'react-i18next';
import Loading from '../../components/Loading';
import ModalFeedback from '../../components/ModalFeedback';
import ModalThanks from '../../components/ModalThanks';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';

/*
 * Este componente es el que vamos a importar en tooodos lados.
 *
 * El encargado de recibir:
 *  conversationFlow: string
 *  type: TEXT | VOICE
 *  module: DIARY | HISTORY | GPT | EXERCISES | PROGRAM
 *  finishTextButton: string
 *  onFinishConversation: function
 *
 * > Este componente tendrá la integración y lógica con firebase Services
 * > Aquí se hará el guardado de mensajes y así
 *
 export const CHAT_MODULE = {
  DIARY: 'DIARY',
  HISTORY: 'HISTORY',
  PROGRAMV2: 'PROGRAMV2',
  GPT: 'GPT',
  EXERCISES: 'EXERCISES',
  PROGRAM: 'PROGRAM',
};

 export const CHAT_TYPES = {
   TEXT: 'text',
  AUDIO: 'audio',
};
 *
 * */

const CHAT_STATE_RESPONSE = {
  EXPECTING_RESPONSE: 'EXPECTING_RESPONSE',
  WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
  FINISH_MESSAGE: 'FINISH_MESSAGE',
};

const ChatMain = ({
  conversationFlow,
  type = CHAT_TYPES.TEXT,
  chatModule = CHAT_MODULE.GPT,
  finishTextButton,
  onFinishConversation,
  onOpenModal = () => {},
  onStartAnswer = () => {},
  onLastMessage = () => {},
  chatAsLastMessages = false, // bandera para ver si prendemos historial o no
  lastNumberMessages = 10, // ultimos mensajes
  webStates = {}, // estados de mostrar siempre el menú && mandar a evaluaciones
  webNomId,
  onboardingConversationFlow,
  sendFeedback = () => {},
}) => {
  // States
  const {t} = useTranslation();
  const [hasConnection, setHasConnection] = useState(false);
  const [numberMessages, setNumberMessages] = useState(lastNumberMessages);
  const [messages, setMessages] = useState(null);
  const [messagesDiary, setMessagesDiary] = useState(null);
  const [firstUserReply, setFirstUserReply] = useState(true);
  const [currentFlow, setCurrentFlow] = useState('');
  const [recommendationHappened, setRecommendationHappened] = useState(false);
  const [recommendationCard, setRecommendationCard] = useState(null);
  const {chatOptions, setChatOptions} = useContext(ChatContext);
  const {boxAssistant, setBoxAssistant} = useContext(BoxAssistantContext);
  const {data} = boxAssistant || {};
  const firstUserMessage = data?.firstUserMessage || null;
  const {pushState} = useContext(PushStateContext);
  const [loadingDiary, setLoadingDiary] = useState(
    chatModule === CHAT_MODULE.DIARY,
  );
  // variables para feedback like, dislike
  const [showFeedBackModal, setShowFeedBackModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [currentFeedbackMessage, setCurrentFeedbackMessage] = useState(null);
  const [feedbackActivate, setFeedbackActivate] = useState(false);

  const [chatStateResponse, setChatStateResponse] = useState(
    CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE,
  );
  // Hooks
  const {featureFlags} = useContext(FeatureFlagsContext);
  const {
    remoteConfig: {webConfig},
  } = useContext(RemoteConfigContext);
  const {user} = useContext(UserContext);
  const {
    remoteConfig: {chatConfig},
  } = useContext(RemoteConfigContext);
  // Constants
  const userID = user?.userFB?.uid;
  const mutex = withTimeout(new Mutex(), 750);

  /* Función para abrir conexión */
  async function openConnection() {
    await getConnection(setHasConnection);
    await AsyncStorage.removeItem('lastTimeMessage');

    if (chatModule === CHAT_MODULE.HISTORY || chatAsLastMessages) {
      return getMessages(userID, numberMessages, getMessagesFromDB);
    }
    // si tiene mensajes en chatOptions, los agrega al state
    /*
     * TODO: Esta funcionalidad carga la conversación que esta en contexto.
     *  Por ahora que no tenemos la caja de asistente no se ocupa pero para FASE 2 será necesario. NO BORRAR
     * */
    // if (chatOptions?.conversation?.length && chatModule === CHAT_MODULE.GPT) {
    //   setMessages(chatOptions.conversation);
    //   return await getMessages(userID, 1, getMessagesFromDB);
    // }

    // 1. enviar mensaje de para despertar al bot
    const time = await sendMessageToBot({
      content: `/lcf ${conversationFlow}`,
      isCommand: true,
      typeMessage: type,
    });
    console.log('time', time);
    return await getMessages(userID, numberMessages, getMessagesFromDB, time);
  }

  /** Formatea todos los mensajes nuevos y los agrega al state*/
  async function getMessagesFromDB(snapshot) {
    const newValue = snapshot.val();
    if (!newValue) {
      if (chatAsLastMessages) {
        // aqui si no hay nada de mens ajes, comienza la conversación
        // 1. enviar mensaje de para despertar al bot
        const registerPrefix = await AsyncStorage.getItem('registerPrefix');
        const cf = registerPrefix
          ? onboardingConversationFlow
          : webConfig?.conversationFlowOnBoarding || conversationFlow;

        const time = await sendMessageToBot({
          content: `/lcf ${cf}`,
          isCommand: true,
          typeMessage: type,
        });
        return;
      }
      return;
    }
    const newMessages = orderAndFormatMessages(newValue);
    if (newMessages.length > 0 && chatModule === CHAT_MODULE.GPT) {
      const messageWA = newMessages[0]; // messageWithoutArray
      // si es bot y tiene extraData y multimedia, entonces es audio, de lo contrario es texto
      // pero si no es bot y tiene extraParams y path es audio, de lo contrario es texto
      const isAudio =
        (messageWA.bot &&
          messageWA.extraData &&
          messageWA.extraData.multimedia) ||
        (!messageWA.bot && messageWA.extraParams && messageWA.extraParams.path);
      logMainScreen('chat_exercise');
      sendEvent('2.A MessageReceived', {
        content: messageWA?.content,
        conversationFlow,
        bot: messageWA?.bot,
        messageType: isAudio ? 'audio' : 'text',
        audioData: isAudio
          ? {
              ...messageWA?.extraParams,
              ...messageWA?.extraData,
            }
          : null,
      });
    }

    if (!featureFlags[MODULES.CHAT_DELAY]) {
      await addMessagesWithoutDelay(newMessages);
      return;
    }

    if (featureFlags[MODULES.MUTEX]) {
      mutex.waitForUnlock().then(async () => {
        //console.log('mutex in', msges[0].content);
        await addNewMessagesWithDelay(newMessages);
        //console.log('mutex out', msges[0].content);
      });
      return;
    }
    await addNewMessagesWithDelay(newMessages);
  }

  /* Agregar nuevos mensajes cuando tiene delay (de uno en uno) */
  async function addNewMessagesWithDelay(newMessages) {
    console.log('addNewMessagesWithDelay', chatStateResponse);

    if (!navigationRef.isFocused()) {
      return;
    }
    let _lastTimeMessage = await AsyncStorage.getItem('lastTimeMessage');
    _lastTimeMessage = _lastTimeMessage ? JSON.parse(_lastTimeMessage) : null;
    const lastTimeMessage =
      _lastTimeMessage !== null ? new Date(_lastTimeMessage) : null;

    const newLastTime = newMessages.reduce((lastTime, msg) => {
      const addMessage = oldMessages => {
        const newMessage = {...msg, isLastMessage: true};
        const updatedMessages = oldMessages.map(message => ({
          ...message,
          isLastMessage: false,
        }));
        const messagesWithNewMessage = [newMessage, ...updatedMessages];

        setLastResponseOptions(messagesWithNewMessage);
        return removeDuplicates(messagesWithNewMessage);
      };
      if (!msg.bot) {
        setMessages(addMessage);
        return null;
      }
      const timeToRead = secsToRead(msg.content, chatConfig.wordsPerMinute);
      const _lastTime = lastTime || new Date();
      const _newLastTime = _lastTime.getTime() + timeToRead * 1000;
      const currentTime = new Date();
      const timeDiff = _lastTime.getTime() - currentTime.getTime();

      if (!lastTime || timeDiff < 0) {
        setMessages(addMessage);
      } else {
        // const timeout = _lastTime - new Date();
        setTimeout(() => {
          setMessages(addMessage);
        }, timeDiff);
      }
      return new Date(_newLastTime);
    }, lastTimeMessage);
    await AsyncStorage.setItem('lastTimeMessage', JSON.stringify(newLastTime));
  }

  /* Agregar nuevos mensajes sin delay */
  function addMessagesWithoutDelay(newMessages) {
    if (!navigationRef?.isFocused()) {
      return;
    }
    // vemos si se queda esto es para feedback solo los ultimos TODO:
    // if (newMessages?.[0].bot) {
    //   setCount(c => c + 1);
    // } else {
    //   setCount(0);
    // }
    setMessages(oldMessages => {
      const updatedMessages = oldMessages?.map(message => ({
        ...message,
        isLastMessage: false,
      }));

      const messagesWithFlags = newMessages?.map((newMsg, index) => ({
        ...newMsg,
        isLastMessage: index === newMessages?.length - 1,
      }));

      const combinedMessages = !updatedMessages
        ? [...messagesWithFlags]
        : [...messagesWithFlags, ...updatedMessages];

      setLastResponseOptions(combinedMessages);
      return removeDuplicates(combinedMessages);
    });
  }

  function removeDuplicates(obj) {
    const uniqueIds = new Set();
    return (
      obj?.filter(objeto => {
        const objectId = objeto?.id;
        if (objectId && !uniqueIds.has(objectId)) {
          uniqueIds.add(objectId);
          return true;
        }
        return false;
      }) || obj
    );
  }

  /** Agregar al state & storage el ultimo mensaje TODO */
  function setLastResponseOptions(newMessages) {
    //Como los invertimos en la función orderAndFormatMessages el ultimo es el primero
    // setLastMessage(newMessages[0]);
    const chatState = newMessages[0].finishingMessage
      ? CHAT_STATE_RESPONSE.FINISH_MESSAGE
      : newMessages[0].bot && newMessages[0].expectingResponse
      ? CHAT_STATE_RESPONSE.EXPECTING_RESPONSE
      : CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE;
    setChatStateResponse(chatState);
    if (chatState === CHAT_STATE_RESPONSE.FINISH_MESSAGE) {
      onLastMessage();
    }

    // TODO: Toda esta funcionalidad era por el cajón.
    // No la borraré aunque me parece que el approach no es el más optimo.
    // Cuando implementemos cajón, revisar si hay una mejor manera de hacer esto.
    // setCurrentFlow(newMessages[0].flowName);
    // filter msges by is not bot
    // if (chatModule !== CHAT_MODULE.DIARY) {
    //   return;
    // }

    // const userMessageCount = newMessages.reduce(
    //   (count, msg) => (!msg.bot ? count + 1 : count),
    //   0,
    // );
    //
    // if (newMessages[0]?.bot && userMessageCount === 0) {
    //   const time = sendMessageToFB(
    //     userID,
    //     firstUserMessage,
    //     currentFlow,
    //     'webbot',
    //     false,
    //     type,
    //   );
    // }
    // si ya hay un mensaje del usuario, y el ultimo es del bot significa que es el que vamos a mostrar.

    // if (userMessageCount > 0 && newMessages[0]?.bot) {
    // Resto del código
    // setLastMessage([newMessages[0]]);
    // const messageWithAnswer = {
    //   ...newMessages[0],
    //   responses: `${t('diary:optionNotToday')}/${t(
    //     'diary:optionYes',
    //   )}/Menú Principal`,
    // };
    //
    // setMessagesDiary([messageWithAnswer]);
    // setLoadingDiary(false);
    // }
  }

  /** Enviamos el mensaje a FB /lcf Check-in V2  /lcf Resiliencia 5 */
  function sendMessageToBot({content, isCommand, extraParams, typeMessage}) {
    if (firstUserReply && !isCommand) {
      const sessionID = messages && messages[0]?.extraData?.session_id;
      sendEvent('1.A UserReply', {
        content,
        sessionID,
      });
      setFirstUserReply(false);
    }

    if (
      chatModule === CHAT_MODULE.DIARY &&
      content.trim() === t('diary:optionNotToday').trim()
    ) {
      logSimpleEvent('diaryFlow', {
        step: '2.C NoTalkAssistant',
        pushState,
      });
      console.log('⭐⭐⭐⭐⭐⭐⭐ termina diario');
      onFinishConversation();
      return;
    }
    if (
      chatModule === CHAT_MODULE.DIARY &&
      content.trim() === t('diary:optionYes').trim()
    ) {
      logSimpleEvent('diaryFlow', {
        step: '2.B KeepTalkingAssistant',
        pushState,
      });
      console.log('🌙🌙🌙🌙🌙🌙🌙🌙🌙🌙 me cambio a gpt');
      setBoxAssistant({
        ...boxAssistant,
        component: BOX_ASSISTANT_COMPONENTS.CHAT_TEXT,
      });
      onOpenModal();
      return;
    }

    setChatStateResponse(CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE);
    //setwaitingForResponse(true);
    setNumberMessages(numberMessages + 1);
    // await AsyncStorage.removeItem('lastTimeMessage');
    return sendMessageToFB(
      userID,
      content,
      currentFlow,
      isCommand ? 'sendMessageToEngine' : 'webbot',
      isCommand,
      typeMessage,
      extraParams,
    );
  }

  // esta funcion solo se usará para web y si tiene la opcion de traerte los ultimos
  function loadMoreMessages() {
    // setter de numberOfMessages and then an async function
    if (!chatAsLastMessages || numberMessages >= 100) {
      return;
    }
    const newNumber = numberMessages + 20;
    setNumberMessages(newNumber);
    getMessages(userID, newNumber, getMessagesFromDB);
  }

  function sendEvent(step, params) {
    logRudderEvent({
      name: 'conversationFlow',
      step: step,
      screen: 'ChatGenerico',
      extraFrom: {
        component: 'ChatGenerico',
      },
      pushState,
      extraProperties: {...params},
    });
    console.log('🪐🪐🪐🪐🪐🪐🪐🪐🪐', JSON.stringify(params, null, 2));
  }

  // TODO: Esta función no se ocupa pero si regresamos con recomendaciones será necesaria. NO BORRAR
  async function onStartRecommendation({type, conversationFlow}) {
    // si es flow
    console.log('onStartRecommendation', type, conversationFlow);
    switch (type) {
      case MESSAGE_TYPES.BOT_EXERCISE_CHAT:
        // recomendacion como true;
        //mandar llamar a ese conversation flow
        // maybe guardar este mensaje.
        setRecommendationHappened(true);
        setRecommendationCard(lastMessage);
        const time = await sendMessageToBot({
          content: `/lcf ${conversationFlow}`,
          isCommand: true,
          typeMessage: type,
        });

        break;
    }
  }

  async function onFinishRecommendation({type, displayDetail}) {
    if (chatStateResponse === CHAT_STATE_RESPONSE.FINISH_MESSAGE) {
      return;
    }
    setChatStateResponse(CHAT_STATE_RESPONSE.FINISH_MESSAGE);
  }

  function modifyMessageFeedback(newMessage) {
    const messageIndex = messages.findIndex(msg => msg.id === newMessage?.id);
    if (messageIndex !== -1) {
      const newMessages = [...messages];
      newMessages[messageIndex] = newMessage;
      setMessages(newMessages);
    }
  }

  function storageFeedback({feedBackType, message: messageFromChild, comment}) {
    const message = messageFromChild || currentFeedbackMessage;

    storageFeedbackMessage({
      feedBackType,
      message,
      comment,
      conversationFlow,
      pushState,
      modifyMessageFeedback,
      setShowThanksModal,
    });
  }

  function openComment({message}) {
    if (message?.feedback?.comment) {
      return;
    }
    setCurrentFeedbackMessage(message);
    setShowFeedBackModal(true);
  }

  /* Paso 1: Abrir conexión con FB */
  useEffect(() => {
    let _connection;
    try {
      if (_connection) {
        return;
      }
      _connection = openConnection()
        .then(() => {
          console.log('connection open');
          setHasConnection(true);
        })
        .catch(e => {
          console.log('errorcdcd', e);
        });
    } catch (error) {
      console.log('error', error);
      reportError('ChatMain', {extra: {_connection}}, 'connection', error);
    }
    return async () => {
      console.log('await disconnect');
      if (!_connection || !disconnect || !userID) {
        return;
      }
      await disconnect(user?.id);
      // execute connection if is a function
      _connection && typeof _connection === 'function' && _connection();
      setMessages([]);
      // setLastMessage(null);
      setMessagesDiary([]);
      if (chatModule !== CHAT_MODULE.DIARY && chatModule !== CHAT_MODULE.GPT) {
        setChatOptions(defaultChat);
      }
    };
  }, []);

  // useEffect featureFlags
  useEffect(() => {
    setFeedbackActivate(
      isModuleActive(MODULES.DIARY_FEEDBACK, featureFlags) &&
        chatModule === CHAT_MODULE.GPT,
    );
  }, [featureFlags]);

  // useEffect(() => {
  //   if (
  //     !lastMessage ||
  //     !messages
  //   ) {
  //     return;
  //   }
  // TODO: Esto es la funcionalidad de de las recomendaciones,
  //  por ahora no se ocupa pero para FASE 2 será necesario. NO BORRAR
  //   if (
  //     lastMessage &&
  //     lastMessage?.finishingMessage &&
  //     recommendationHappened &&
  //     recommendationCard
  //   ) {
  //     setMessages([recommendationCard, ...messages]);
  //   }
  // setChatOptions({
  //   conversationFlow,
  //   sessionID: messages[0]?.extraData?.session_id || chatOptions?.sessionID,
  //   messageID: messages[0]?.extraData?.message_id || chatOptions?.messageID,
  //   lastMessage: lastMessage || chatOptions?.lastMessage,
  // });
  //   return () => {};
  // }, [lastMessage]);

  // useEffect(() => {
  //   if (chatModule === CHAT_MODULE.GPT) {
  //     setShowFeedback(isFeedbackActive());
  //   }
  // }, [chatModule]);

  if (loadingDiary || !hasConnection) {
    return (
      <Box h={'100%'} flex={1}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <ChatContainer>
        <ChatSkeleton
          lastMessage={
            chatModule === CHAT_MODULE.DIARY
              ? messagesDiary[0]
              : messages?.length > 0
              ? messages[0]
              : null
          }
          finishTextButton={finishTextButton}
          onFinishConversation={onFinishConversation}
          onSendResponse={sendMessageToBot}
          messages={chatModule === CHAT_MODULE.DIARY ? messagesDiary : messages}
          chatStateResponse={chatStateResponse}
          onStartRecommendation={onStartRecommendation}
          recommendationHappened={recommendationHappened}
          onFinishRecommendation={onFinishRecommendation}
          isFeedbackShow={feedbackActivate}
          // showFeedback={isFeedbackShow}
          storageFeedback={storageFeedback}
          openComment={openComment}
          onStartAnswer={onStartAnswer}
          chatModule={chatModule}
          loadMoreMessages={loadMoreMessages}
          webStates={webStates}
          webNomId={webNomId}
          sendFeedback={sendFeedback}
        />
        <ModalFeedback
          onFeedback={storageFeedback}
          showModal={showFeedBackModal}
          setShowModal={setShowFeedBackModal}
        />
        <ModalThanks
          showModal={showThanksModal}
          setShowModal={setShowThanksModal}
        />
      </ChatContainer>
    </>
  );
};

ChatMain.propTypes = {
  conversationFlow: PropTypes.string.isRequired,
  type: PropTypes.string,
  chatModule: PropTypes.string,
  finishTextButton: PropTypes.string,
  onFinishConversation: PropTypes.func,
};
export default ChatMain;
