/* istanbul ignore file */
import React, {useEffect, useRef, useState} from 'react';
import * as _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {Text, View} from 'react-native';
import {
  initialize,
  pushStateInitialize,
  userInitialize,
} from './hooks/SessionContext';
import CombineContexts from './CombineContexts';
import './constants/IMLocalize';
import {
  getSession,
  getUser,
  notificationInitialize,
  remoteConfigInitialize,
} from './core/initialize/initializeService';
import getClient from './graphql/apollo';
import {theme} from './styles/nativeBase';
import {FLAGS_DEFAULT, getFlags, MODULES} from './core/featureFlagService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {GET_USER_DATA, UPDATE_DEVICE_INFO} from './graphql/queries/users';
import MainNavigator, {
  getParams,
  navigateTo,
  ROUTE_NAMES,
} from './navigation/MainNavigator';
import {initFirebase, singOut} from './core/firebase/firebaseAuth';
import Splash from './components/Splash';
import {clearItems} from './hooks/useAsyncStorageData';
import FloatingUserInfo from './components/FloatingUserInfo';
import {defaultChat} from './hooks/ChatContext';
import {reportError} from './helpers/crashlytics/fbCrashlytics';

/* RudderStack JS Web*/
import * as rudderanalytics from 'rudder-sdk-js';
import * as Sentry from '@sentry/react';
import {resetRudder} from './helpers/analytics/fbAnalytics';
import NetInfo from '@react-native-community/netinfo';
import MessageSystem from './components/MessageSystem';

import {buildNumberWeb, ENV} from './web.config';

const AppWeb = () => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGraphqlErrors, setShowGraphqlErrors] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState(null);
  const [user, setUser] = useState(userInitialize);
  const [extraInfo, setExtraInfo] = useState({floatAudio: false});
  const [session, setSession] = useState(initialize);
  const {t, i18n} = useTranslation();
  const [recapchaData, setRecapchaData] = useState(null);
  const [featureFlags, setFeatureFlags] = useState(FLAGS_DEFAULT);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [pushState, setPushState] = useState(pushStateInitialize);
  const [chatOptions, setChatOptions] = useState(defaultChat);
  const [routeLink, setRouteLink] = useState(null);
  const [isUserFetched, setIsUserFetched] = useState(false);
  const previousUserRef = useRef(user);

  const [notificationMessage, setNotificationMessage] = useState({
    title: t('error:generalTitle'),
    message: t('error:generalDsc'),
    type: 'error',
    first: true,
  });
  const [showModalMessage, setShowModalMessage] = useState(false);

  function setStateForNotificationMessage(data) {
    setNotificationMessage(data);
    setShowModalMessage(true);
  }

  const handleDynamicLink = link => {
    if (!link) return;
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};
    let match;

    while ((match = regex.exec(link))) {
      params[match[1]] = match[2];
    }

    if (params.route) {
      setRouteLink(params.route);
      getParams(params.id);
      console.log('handleDynamicLink', params);
    }
  };

  /* TODO: Estas keys no deberían vivir aqui. :(  */
  async function initAnalytics({
    rs_wk = '2AFlAqLdf1udWDmbOia8J0F2tgd',
    rs_dp = 'https://weareautomqwa.dataplane.rudderstack.com',
  }) {
    try {
      rudderanalytics.load(rs_wk, rs_dp);
      rudderanalytics.ready(() => {
        console.log('We are all set!!!');
      });
    } catch (error) {
      console.log('error', error);
      reportError('App.web', {}, 'initAnalytics', error);
    }
  }

  async function handleUserUpdates() {
    const userFromAsync = await getUser();
    let userFB = userFromAsync?.userFB;
    const userFBOld = userFromAsync?.user;

    if (userFBOld && !userFB) {
      console.log(
        'es un usuario con el user viejito',
        JSON.stringify(userFromAsync, null, 2),
      );
      userFB = userFBOld;
    }

    if ((user === userInitialize || !session?.authenticated) && !userFB) {
      // el usuario no ha cambiado, no hacemos nada.
      return;
    }
    /* El usuario se ha modificado desde otra pantalla:
                          login, account, etc. Aqui  no hacemos peticion de nuevo, solo guardamos la data
                        */
    const {userCMS, suscriptionStatus, country, timezone} = user;

    if (
      !userCMS &&
      userFromAsync &&
      userFromAsync?.userFB &&
      !isUserFetched &&
      client
    ) {
      // nos traemos la info del usuario desde el CMS si aún no lo tenemos
      const {data} = await client?.query({query: GET_USER_DATA});
      let profile = data?.profile;
      // parse de company variables
      if (profile?.workCompany?.variables) {
        try {
          const workCompanyVariables =
            JSON.parse(profile?.workCompany?.variables) || {};
          profile = {
            ...profile,
            workCompany: {
              ...profile?.workCompany,
              variables: workCompanyVariables,
            },
          };
        } catch (e) {
          console.error('Error parsing workCompany variables', e);
          reportError(
            'App.web',
            {extra: {profile}},
            'Error parsing workCompany variables',
            e,
          );
        }
      }

      const newUser = {
        userCMS: profile,
        userFB,
        suscriptionStatus,
        country,
        timezone,
      };

      if (JSON.stringify(newUser) !== JSON.stringify(user)) {
        setUser(newUser);
      }
      setIsUserFetched(true);
      return data?.profile;
    }
    return null;
  }

  const updateDeviceInfo = async data => {
    setDeviceInfo(data);
    client
      .mutate({
        mutation: UPDATE_DEVICE_INFO,
        variables: {
          input: {
            ...data,
            deviceInfo: JSON.stringify(data?.deviceInfo),
          },
          profile: {},
        },
      })
      .then(async res => {
        console.log('DeviceInfo updated', res);
        const identifier = user?.userFB?.uid;
        // user && user.user && (user.user.phoneNumber || user.user.email);
        const profile = res?.data?.updateProfile?.profile;
        const company = profile?.workCompany;
        let profileExtraInfo = {};
        try {
          profileExtraInfo = JSON.parse(profile?.extraInfo);
        } catch (e) {
          console.log('Error parsing extraInfo', e);
          reportError('App.web', {}, 'updateDeviceInfo', e);
        }

        if (identifier) {
          rudderanalytics.identify(
            identifier,
            {
              ...profileExtraInfo,
              uid: user?.userFB?.uid,
              email: user.userFB.email || '',
              phone: user.userFB.phone || '',
              isEmulator: data?.deviceInfo?.isEmulator,
              installerPackageName: data?.deviceInfo?.installerPackageName,
              tags: data?.deviceInfo?.tags,
              companyId: company?.publicId || '',
              companyName: company?.name || '',
              businessModel: company?.businessModel || '',
              dateJoined: profile?.dateJoined,
              // extraInfo: profileExtraInfo,
              environment: ENV,
            },
            null,
          );
          // await logMainScreen('app', pushState);
          console.log('Identify sent as: ' + identifier);
        }
      })
      .catch(e => {
        console.log('Error en updateDeviceInfo', e);
        reportError('graphql', data, 'UPDATE_DEVICE_INFO', e);
      })
      .finally(async () => {
        const flags = await getFlags(data, client);
        setFeatureFlags(flags);
        setShowGraphqlErrors(flags[MODULES.GRAPHQL_ERRORS]);
      });
  };

  async function networkStatus() {
    return new Promise(resolve => {
      const unsubscribe = NetInfo.addEventListener(state => {
        resolve({
          isConnected: state.isConnected,
          message: state.isConnected ? undefined : t('error:poorConnection'),
        });
      });
      unsubscribe();
    });
  }

  async function getGraphClient({urls}) {
    try {
      console.log('urls 💝💝💝💝💝💝💝', urls.apollo);

      const status = await networkStatus();

      const cl = await getClient(urls.apollo, () =>
        onGraphqlError({networkError: status}),
      );
      setClient(cl);
    } catch (e) {
      console.error('Error en conectarse a graphQL', e);
      reportError('App.web', {}, 'getGraphClient', e);
    }
  }

  async function onGraphqlError({networkError, graphQLErrors}) {
    console.log('onGraphqlError web', {networkError, graphQLErrors});
    let message;
    let title;
    if (graphQLErrors) {
      console.log('el mensaje: ', graphQLErrors?.[0]?.message);
      if (graphQLErrors?.[0]?.message && session?.authenticated) {
        try {
          await singOut();
        } catch (e) {
          //No está logueado con Firebase
          reportError('App.web', {}, 'onGraphqlError', e);
        }
        setSession({...initialize});
        setUser({...userInitialize});
        await clearItems([]);
        await AsyncStorage.setItem('user-language', i18n?.language);
        resetRudder();
        // link to and reset
        await navigateTo(ROUTE_NAMES.login, {reset: true});
      }

      if (!showGraphqlErrors) {
        return;
      }
      title = '¡Request Error!';
      message = _.flatMap(graphQLErrors || [], 'message').join('. ');
    } else {
      title = '¡Network Error!';
      message = networkError?.message;
    }
    console.log('onGraphqlError message', message);
    setNotificationMessage({
      title,
      message: message || 'Unhandled error',
      type: 'error',
      show: !networkError.isConnected,
    });
  }

  // Recaptcha checar si se sigue ocupando con firebase
  useEffect(() => {
    if (!recapchaData) {
      console.log('recaptcha', recapchaData);
      //setRecapchaData(recaptcha());
    }
  }, [recapchaData]);

  /** Hook for initialize **/
  useEffect(() => {
    async function remoteC() {
      const userFBInit = initFirebase();
      //await backgroundMessageHandler();
      const remote = await remoteConfigInitialize();
      setRemoteConfig(remote);
      //await initializePlayer();
      setSession(await getSession());
      // setUser(await getUser());
      await getGraphClient(remote);
      await initAnalytics(remote);
      const metas = document.head.getElementsByTagName('meta');
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].name == 'viewport') {
          metas[i].content += ', user-scalable=no';
        }
      }
      handleUserUpdates()
        .then(res => {})
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        });
    }

    const unsubscribe = NetInfo.addEventListener(state => {
      setNotificationMessage({
        message: state.isConnected ? undefined : t('error:poorConnection'),
        type: 'error',
        show: !state.isConnected,
      });
    });

    function initDynamicLinks() {
      // Para obtener el Dynamic Link inicial al cargar la página.
      const initialLink = new URL(window.location.href);
      if (initialLink) {
        handleDynamicLink(initialLink?.href);
      }
    }

    remoteC().then(() => {});
    initDynamicLinks();

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    async function updateRemote() {
      if (user?.userFB !== null && remoteConfig && client === null) {
        await getGraphClient(remoteConfig);
      }
    }

    updateRemote();
  }, [user, remoteConfig, client]);

  useEffect(() => {
    if (session !== initialize) {
      AsyncStorage.setItem('session', JSON.stringify(session));
      handleUserUpdates();
    }
  }, [session]);

  useEffect(() => {
    // revisamos en handleUserUpdates si el usuario ha cambiado, si recibimos Null significa que aun no se ha logueado
    // Detecta si ha habido un cambio real en `user`

    if (JSON.stringify(user) !== JSON.stringify(previousUserRef.current)) {
      handleUserUpdates().then(async userData => {
        if (!userData) return;
        await notificationInitialize(updateDeviceInfo);
      });

      // Actualiza la referencia al usuario anterior
      previousUserRef.current = user;

      // Restablece la bandera para permitir otra actualización en caso de cambio
      setIsUserFetched(false);
    }
  }, [user, session]);

  console.log('🎀🎀🎀🎀', JSON.stringify(user, null, 2));

  if (!client) {
    return null;
  }

  return (
    <CombineContexts
      apollo={{client}}
      notificationMessage={{
        notificationMessage,
        setNotificationMessage: setStateForNotificationMessage,
      }}
      user={{user, setUser}}
      deviceInfo={deviceInfo}
      featureFlags={featureFlags}
      session={{
        session,
        setSession,
      }}
      remoteConfig={remoteConfig}
      extraInfo={{extraInfo, setExtraInfo}}
      pushState={{pushState, setPushState}}
      chatState={{chatOptions, setChatOptions}}
      theme={theme}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          right: 15,
          zIndex: 1000,
        }}>
        <Text style={{color: 'gray', fontSize: 10}}>
          Version: {buildNumberWeb}
        </Text>
      </View>
      {loading ? (
        <Splash />
      ) : (
        <MainNavigator routeLink={routeLink} setRouteLink={setRouteLink} />
      )}
      {!loading ? <FloatingUserInfo user={user} /> : null}
      {/* create an empty container that doesn't occup space with id: recaptcha-container */}
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 0,
          height: 0,
        }}
        collapsable={false}
        pointerEvents="none"
        id="recaptcha-container"
        testID="recaptcha-container"
      />

      <MessageSystem
        isWarning={notificationMessage?.type === 'error'}
        showModalMessage={notificationMessage?.show}
        setShowModalMessage={() => {
          setNotificationMessage({...notificationMessage, show: false});
        }}
        message={notificationMessage?.message}
      />
    </CombineContexts>
  );
};

Sentry.init({
  dsn: 'https://fcd0c6b4f8a3482ead4f9622ce46f8e2@o264458.ingest.sentry.io/6324457',
  environment: ENV,
  debug: ENV === 'local' || ENV === 'staging',
  tracesSampleRate: 1.0,
});

export default AppWeb;
