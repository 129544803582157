export default {
  profiling: 'Tell us about you!',
  seeAll: 'See all',
  tabBarLabelHome: 'Home',
  tabBarLabelPrograms: 'Digital\ntherapy',
  tabBarLabelTherapy: 'Therapists',
  tabBarLabelTools: 'Exercises',
  tabBarLabelAssistant: 'Assistant',
  workToday: 'What topic would you like to start with?',
  useFilter: 'Use the filters to choose a theme.',
  name: 'Mindsurf',
};
