/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Center,
  HStack,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import AnimatableBox from '../components/AnimatableBox/AnimatableBox';
import {isWeb} from '../helpers/utils/utils';
import {logRudderEvent} from '../helpers/analytics/fbAnalytics';
import {PushStateContext, UserContext} from '../hooks/SessionContext';

// import * as Animatable from 'react-native-animatable';

/**
 * Componente  QuestionEvaluation
 * Muestra la pregunta con las opciones de respuesta.
 * @params
 * question => Objeto pregunta con las respuestas, numero y texto
 * getAnswer => funcion que se ejecuta cuando se selecciona la respuesta
 */

const QuestionEvaluation = ({
  question,
  getAnswer,
  index,
  therapyTitle,
  screenFrom = 'Therapy',
}) => {
  const {text, answers, id, questionCode, answerSelected} = question;
  //console.log('>>>>', question);
  const [answer, setAnswer] = useState(answerSelected);
  const animation = useRef();
  const [first, setFirst] = useState(true);
  const scrollRef = useRef();

  const {pushState} = useContext(PushStateContext);

  async function setAnswerSelected(answerId, action) {
    setAnswer(createIdAnswer(answerId));
    if (question.lastOne) {
      getAnswer({id, answerId, action});
      return;
    }
    // await delay(0.2);
    // animation.current.bounceOutRight();
    getAnswer({id, answerId, action});
    // await delay(0.5);
    // animation.current.bounceInLeft();
  }

  function createIdAnswer(idA) {
    return `${id}-${idA}`;
  }

  useEffect(() => {
    setAnswer(answerSelected);
    if (first) {
      setFirst(false);
      return;
    }
    if (animation) {
      // await delay(0.2);
      // animation.current.fadeOut();
      // await delay(0.5);
      // animation.current.fadeIn();
      // animation.current.zoomInUp();
    }
  }, [question]);

  return (
    <>
      <ScrollView bg={'secondary.100'} ref={scrollRef}>
        <VStack flex={1} justifyContent={'flex-start'}>
          <Center>
            <HStack justifyContent="flex-start" mt={3} mb={1}>
              <Box w="10%">
                <Text
                  color="primary.100"
                  fontSize={'lg'}
                  fontWeight={800}
                  fontFamily={'recoleta'}>
                  {index + 1}.
                </Text>
              </Box>
              <Box w="85%">
                <Text
                  mb={2}
                  color="primary.100"
                  fontSize={'lg'}
                  fontWeight={800}
                  fontFamily={'recoleta'}>
                  {text}
                </Text>
              </Box>
            </HStack>
          </Center>

          <Box px={4}>
            <AnimatableBox ref={animation}>
              {answers.map(({id, text, action}) => (
                <Button
                  testID={`answerButton-${id}`}
                  onPress={() => {
                    setAnswerSelected(id, action);
                    if (scrollRef?.current) {
                      scrollRef.current.scrollTo({
                        y: 0,
                        animated: true,
                      });
                    }

                    if (index === 0) {
                      logRudderEvent({
                        name: 'digitalTherapy',
                        step: '0.B answer_first_question',
                        screen: 'Evaluation',
                        extraFrom: {
                          component: 'QuestionEvaluation',
                        },
                        extraProperties: {
                          therapy_name: therapyTitle,
                          screenFrom,
                        },
                        pushState,
                      });
                    }
                  }}
                  my={2}
                  _text={{
                    fontWeight: 800,
                    numberOfLines: 3,
                    lineHeight: 'xs',
                    fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
                  }}
                  shadow={3}
                  key={id}
                  fill={true}
                  variant={
                    id === answerSelected
                      ? 'outlineSecondarySelected'
                      : 'outlineSecondary'
                  }>
                  {text}
                </Button>
              ))}
            </AnimatableBox>
          </Box>
        </VStack>
      </ScrollView>
    </>
  );
};

QuestionEvaluation.propTypes = {
  question: PropTypes.object,
  getAnswer: PropTypes.func,
};
export default QuestionEvaluation;
