import React from 'react';
import PropTypes from 'prop-types';
import ReactImageZoom from 'react-image-zoom';

/**
 * Componente  ImageViewer.web normal
 */

const ImageViewer = ({url}) => {
  const props = {width: 400, height: 250, zoomWidth: 500, img: url};

  return <ReactImageZoom {...props} />;
};

ImageViewer.propTypes = {
  url: PropTypes.string.isRequired,
};
export default ImageViewer;
