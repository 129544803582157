import {Box, Center, HStack, Image, Pressable, Text} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import {StyleSheet} from 'react-native';
import {getWidthAndHeight} from '../helpers/utils/utils';
import images from '../res/images';

/**
 * Componente  NavigateBar web
 */

const NavigateBar = ({
  navigation,
  headerTitle,
  optionEvent,
  optionChildren,
  backEvent,
  textAligned = 'center',
  bg = 'transparent',
  props = {},
}) => {
  const {menuDots, backArrow} = images;

  function onBack() {
    if (!backEvent && !navigation) {
      return;
    }

    if (backEvent) {
      backEvent();
    } else {
      navigation?.goBack();
    }
  }

  function printTitle() {
    if (!headerTitle) {
      return;
    }
    if (typeof headerTitle === 'string') {
      return (
        <Text
          textAlign={textAligned}
          fontFamily={'recoleta'}
          fontWeight={800}
          fontSize={'lg'}>
          {headerTitle}
        </Text>
      );
    }

    return <Center>{headerTitle}</Center>;
  }

  function printOption() {
    if (optionChildren) {
      return optionChildren;
    }
    if (!optionEvent) {
      return;
    }

    return (
      <Pressable testID={'navigateBarOptionsButton'} onPress={optionEvent}>
        <Image
          h={3}
          w={6}
          resizeMode={'contain'}
          source={menuDots}
          alt="back"
        />
      </Pressable>
    );
  }

  if (!navigation && !headerTitle && !optionEvent && !backEvent) {
    return null;
  }

  return (
    <HStack
      pr={5}
      pl={backEvent || navigation ? 0 : 5}
      bg={bg}
      style={styles.box}
      flex={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      {...props}>
      {(backEvent || navigation) && (
        <Pressable
          pr={1}
          pt={1}
          pl={5}
          py={2}
          testID={'navigateBarBackButton'}
          onPress={onBack}>
          <Center>
            <Image w={2} h={4} source={backArrow} alt="back" />
          </Center>
        </Pressable>
      )}
      <Box style={styles.box} w={'80%'}>
        {printTitle()}
      </Box>
      <Center>{printOption()}</Center>
    </HStack>
  );
};

const styles = StyleSheet.create({
  box: {
    flex: 1,
    flexGrow: 1,
  },
});

NavigateBar.propTypes = {
  navigation: PropTypes.object,
  headerTitle: PropTypes.any,
  backEvent: PropTypes.func,
  optionEvent: PropTypes.func,
};
export default NavigateBar;
