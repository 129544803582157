/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

/** Historial por paginación */
export const GET_DISPLAY_DETAILS_GROUPS = gql`
  query displayDetailGroups($groupType: String, $favorite: Boolean) {
    displayDetailGroups(groupType: $groupType, favorite: $favorite) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          description
          order
          image {
            title
            cdnUrl
          }
          displayDetails {
            id
            title
            shortDescription
            contentType
            group {
              image {
                cdnUrl
              }
            }
            image {
              title
              file
              cdnUrl
            }
          }
        }
      }
    }
  }
`;

/** Categorias (grupos de herramientas) sin ejercicios */
export const GET_DISPLAY_DETAILS_GROUPS_MINIMAL = gql`
  query displayDetailGroups {
    displayDetailGroups(groupType: "TOOLS", favorite: true) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          order
          featured
          image {
            title
            file
            cdnUrl
          }
        }
      }
    }
  }
`;

// Obtener programa por id
export const GET_DISPLAY_DETAIL_BY_ID = gql`
  query displayDetail($id: ID!) {
    displayDetail(id: $id) {
      id
      title
      description
      shortDescription
      duration
      durationInSecs
      contentType
      contentUrl
      internalName
      multimedia {
        id
      }
      isFavorite
      group {
        id
        name
        image {
          cdnUrl
        }
      }
      programSession {
        id
        duration
      }
      conversationFlow {
        name
      }
      records {
        id
        percentage
        completed
        updatedAt
        currentTime
      }
      coach {
        therapistName
        therapistDescription
        therapistPhoto
        approach
      }
      article {
        title
        content
      }
    }
  }
`;

export const RECORD_DISPLAY_DETAIL_PROGRESS = gql`
  mutation addDisplayDetailRecord(
    $displayDetailId: ID!
    $percentage: Float!
    $currentTime: Float
  ) {
    addDisplayDetailRecord(
      displayDetailId: $displayDetailId
      currentTime: $currentTime
      percentage: $percentage
    ) {
      displayDetailRecord {
        id
        percentage
        updatedAt
        currentTime
        displayDetails {
          id
          title
          contentType
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_DISPLAY_DETAIL_GROUP_BY_ID = gql`
  query displayDetailGroup($id: ID!) {
    displayDetailGroup(id: $id) {
      name
      description
      displayDetails {
        id
        title
        shortDescription
        description
        emoji
        duration
        contentType
      }
    }
  }
`;

export const GET_FILTER_DISPLAY_DETAIL_GROUPS = gql`
  query filterDisplayDetailGroup {
    filterDisplayDetailGroup {
      id
      title
      displayDetailGroups {
        id
        name
      }
    }
  }
`;

export const GET_RECOMMENDED_DISPLAY_DETAILS = gql`
  query displayDetail($id: ID!) {
    displayDetail(id: $id) {
      id
      title
      contentType
      recommendedDisplayDetails {
        id
        contentType
        title
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET_DISPLAY_DETAIL_CONTENTY_TYPE = gql`
  query displayDetailGroup($id: ID!, $contentType: String) {
    displayDetailGroup(id: $id) {
      id
      name
      image {
        cdnUrl
      }
      featured
      displayDetails(contentType: $contentType) {
        id
        title
        contentType
        group {
          id
          name
          image {
            cdnUrl
          }
        }
        completedStatus
      }
    }
  }
`;

export const GET_DISPLAY_DETAIL_CONTENT_TYPE_PAGINATION = gql`
  query displayDetailGroup(
    $first: Int
    $after: String
    $id: ID!
    $contentType: String
  ) {
    displayDetailGroup(id: $id) {
      id
      name
      contentTypes
      image {
        cdnUrl
      }
      isFavorite
      featured
      displayDetailsPagination(
        first: $first
        after: $after
        contentType: $contentType
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            title
            contentType
            group {
              id
              name
              image {
                cdnUrl
              }
            }
            completedStatus
          }
        }
      }
    }
  }
`;

export const GET_ALL_DISPLAY_DETAIL = gql`
  query displayDetailGroups {
    displayDetailGroups {
      edges {
        cursor
        node {
          id
          description
          name
          order
          image {
            title
            file
            description
            cdnUrl
          }
        }
      }
    }
  }
`;

export const MUTATION_ADD_FAVORITE = gql`
  mutation addFavoriteDisplayDetail($displayDetailId: ID!) {
    addFavoriteDisplayDetail(displayDetailId: $displayDetailId) {
      favoriteDisplayDetail {
        id
        displayDetails {
          title
          id
        }
      }
    }
  }
`;

export const MUTATION_DELETE_FAVORITE = gql`
  mutation deleteFavoriteDisplayDetail($displayDetailId: ID!) {
    deleteFavoriteDisplayDetail(displayDetailId: $displayDetailId) {
      favoriteDisplayDetail {
        id
        displayDetails {
          title
          id
        }
      }
    }
  }
`;
