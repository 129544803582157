export default {
  modal: {
    title: 'Actualiza tu app',
    body: {
      isNeeded:
        'Hay una nueva versión de Mindsurf con mejoras. No te quedes atrás y descárgala ahora.',
      isRecommended:
        'Hay una nueva versión de Mindsurf con mejoras. No te quedes atrás y descárgala ahora.',
    },
    button: 'Actualizar',
  },
};
