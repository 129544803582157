export default {
  buttonDate: 'Book appointment',
  buttonProfile: 'See more therapists',
  meet: 'Get to know them:',
  title: 'Book an appointment with a specialist',
  notFound:
    'None of our therapists match with those characteristics. Try with different ones. 🙂',
  therapyHeader: 'Digital therapy',
  typeTherapy: 'Specialty',
  reasonTherapy: 'Reason for consultation',
  allFilters: 'All',
  specialties: 'Specialties',
  profileTherapist: 'Therapist profile',
  appointment: 'Schedule an appointment',
  timeSelection: 'Time selection',
  timeZone: 'Time zone:',
  availableHour: 'Available hours',
  detailsAndPayment: 'Appointment details and payment',
  detailsAndConfirm: 'Appointment details and confirmation',
  psychologist: 'Psychologist',
  dateAndTime: 'Date and time',
  amountToPay: 'Amount to Pay',
  durationTime: 'Duration time',
  schedule: 'Schedule',
  makePayment: 'Make your payment',
  pay: 'Pay',
  therapyScheduled: 'Therapy Scheduled',
  confirmAppointment: 'Your appointment has been confirmed!',
  details: 'Details of your appointment 📅',
  addCalendar: 'Add to calendar',
  backHome: 'Go back home',
  reminderTomorrow: 'Your appointment is tomorrow! 😊',
  reminder2Days: 'Your appointment is in 2 days! 🌻',
  reminderToday: 'Today is your appointment! 🌈',
  reminderTitle: 'Reminder of your appointment 🔔',
  myTherapy: 'My therapies',
  myTherapyDesc:
    'Self-care starts here. Take the first step towards your well-being!',
  noTherapies:
    "It seems like you don't have any therapies scheduled. Why not visit our therapists to start taking care of your emotional well-being? 💫",
  seeTherapist: 'See therapists',
  nextTherapy: 'Next appointments',
  pastTherapy: 'Past',
  availableAppointment:
    'You still have {{appointments}} available appointments! 🌈',
  manualBanner:
    'Keep enjoying your therapy sessions and purchase a new package.',
  scheduleButton: 'Schedule',
  noAvailability: 'No availability at the moment.',
  confirmation: 'Confirm',
  saveSuccess: 'Appointment successfully added to your calendar.',
  titleEvent: 'Mindsurf: Therapy Session.',
  notesEvent:
    'Follow the link to join your therapy session. You can access your therapy from the Mindsurf app.',
  notesEventAndroid:
    'Follow the link to join your therapy session. You can access your therapy from the Mindsurf app. {{link}}',
  therapyDetail: 'Individual therapy · {{minutes}} minutes',
  detailsTitle: 'Individual therapy',
  detailsSubLabel: 'Session · {{minutes}} minutes',
  description: 'Description',
  rulesTherapy: 'Rules for attending therapy',
  rulesCancel: 'Need to reschedule or cancel?',
  startTherapy: 'Start my therapy',
  titleTherapySession: 'Therapy',
  webNotice: 'The button will be enabled when it is time for your therapy.',
  timeSession: '50',
  sessionDescription:
    'Welcome to your therapy session! With the support of our trained psychologists, we will explore your emotions and personal challenges together to improve your emotional well-being and achieve your goals.',
  noAppointments:
    'No upcoming appointments. Explore and schedule with our therapists.',
  noPastAppointments: 'For now, there are no past appointments recorded.',
  rhAdvice:
    "It seems you don't have any available appointments. Why don't you reach out to Human Resources for further assistance?",
  ruleTherapy:
    '1. The tolerance is 15 minutes for both parties, if after this time they do not connect, the session is considered given.',
  ruleTherapy2:
    '2. The sessions with each of our specialists are completely confidential.',
  ruleReschedule:
    "1. To reschedule or cancel a session, it must be done 24 hours in advance out of respect for the specialists' time. Otherwise, the session will be considered given.",
  ruleReschedule2:
    '2. Notify the contact at hello@mindsurf.ai about any unforeseen events to reschedule or cancel a session.',
  PAID_NOT_BOOKED: 'Pendiente de reservar',
  BOOKED: 'Reservada',
  CANCELLED_THERAPIST: 'Cancelada por terapeuta',
  CANCELLED_USER: 'Cancelada por usuario',
  TAKEN: 'Completada',
  LOST: 'Perdida',
  noTimezones:
    'No results found for your search. Please try different keywords.',
  searchTimeZone: 'Search time zone.',
  errorGeneral:
    'There was an error trying to schedule your appointment. Please try again or contact us at hello@mindsurf.ai',
  errorOpenPayment:
    'An error occurred while confirming your appointment. Please try again later.',
  errorAgenda:
    'The selected date and time are not available. Reservations must be made at least 24 hours in advance.',
  erroAvailable:
    'You have no paid or free sessions available. Please acquire a session to proceed.',
  errorNotFound:
    'The selected date and time are not available. Please try selecting a different date and time.',

  notFoundAppointment:
    'This appointment was not found or has expired. Please check your appointment details and try again. If the problem persists, contact our support.',
  popUpNamesNull:
    'It is necessary to complete your profile information to schedule a therapy session.',
  popUpButton: 'Go to my profile',
  popUpAnonymous:
    'You need to create an account to schedule a therapy session.',
  popUpLogin: 'Go to registration',
  therapistNull:
    'At the moment, there are no appointments available, but you can try with another therapist to find an option that suits you. 😊',
};
