import {Box, Pressable, Text, View} from 'native-base';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CountryPicker from 'react-native-country-picker-modal';
import {
  isPhoneNumberValid,
  isWeb,
  getWidthAndHeight,
} from '../helpers/utils/utils';
import {phoneCountryStyles} from '../styles/base';
import {colors} from '../styles/colors';
import IconFont from './Icon/IconFont';
import {defaultTheme, Provider, TextInput} from '@react-native-material/core';

/**
 * Input de número de Teléfono con validación de País y mensaje en caso de que no sea válido
 * @param validPhone si el teléfono es de formato válido
 * @param setValidPhone evento p/mandar el nuevo estado
 * @param setIsFocused
 * @param isFocused
 * @param cellphone
 * @param setCellphone evento p/mandar el nuevo estado
 * @param id id del input
 * @param language es/en
 * @param validation si queremos validar o no el número
 */

const SelectPhone = ({
  validPhone,
  setValidPhone = () => {},
  setIsFocused = () => {},
  isFocused,
  cellphone,
  setCellphone = () => {},
  id,
  language,
  validation = true,
  showTextCode = true,
  type = 'register',
  showWarning = true,
}) => {
  const phoneRef = useRef('');
  const phoneCountryStyle = phoneCountryStyles();
  const {height, width} = getWidthAndHeight();
  const [visible, setVisible] = useState(false);
  const validationLength =
    cellphone?.number?.length >= 0 && cellphone?.number?.length <= 4;
  const {t} = useTranslation(language);

  /* istanbul ignore next */
  function onChange(value) {
    if (validation) {
      setValidPhone(isPhoneNumberValid(value, cellphone?.codeCountry));
    } else {
      setValidPhone(value?.length > 8 && value?.length <= 15);
    }

    setCellphone({
      ...cellphone,
      number: `${cellphone.isoCountry}${value}`,
      inputNum: value,
    });
  }

  /* istanbul ignore next */
  function onSelectCountry(value) {
    setCellphone({
      number: `+${value.callingCode[0]}`,
      inputNum: '',
      codeCountry: value.cca2,
      isoCountry: `+${value.callingCode[0]}`,
    });
    setValidPhone(false);
  }

  /* istanbul ignore next */
  function onCloseCountry() {
    setVisible(false);
  }

  return (
    <>
      <Box w={'80%'} flexDir={'row'}>
        <Box
          style={
            isWeb()
              ? phoneCountryStyle.containerInWeb
              : phoneCountryStyle.containerIn
          }>
          <Pressable
            testID={'selectPhone-button-id'}
            onPress={() => setVisible(true)}
            style={phoneCountryStyle.touchCont}>
            <CountryPicker
              withCallingCode
              withCallingCodeButton
              withFlag
              withFlagButton={false}
              containerButtonStyle={phoneCountryStyle.buttonStyle}
              countryCode={cellphone?.codeCountry}
              onSelect={onSelectCountry}
              onClose={onCloseCountry}
              withFilter
              translation={language === 'es' ? 'spa' : language}
              preferredCountries={['MX', 'FR', 'BR', 'AR', 'US']}
              placeholder={''}
              visible={visible}
              excludeCountries={['AQ', 'BV', 'TF', 'HM', 'UM', 'VA']}
              // onClose={() => setVisible(false)}
              onOpen={() => setVisible(true)}
            />
            <IconFont
              style={phoneCountryStyle.icon}
              name="chevron-down"
              type="font-awesome"
              color={colors.black20}
            />
          </Pressable>
        </Box>

        <Provider
          theme={{
            // extend the default theme
            ...defaultTheme,
            shapes: {
              medium: {
                borderTopStartRadius: 0,
                borderTopEndRadius: 10,
                borderBottomStartRadius: 0,
                borderBottomEndRadius: 10,
              },
            },
          }}>
          <TextInput
            testID={id}
            autoCapitalize={'none'}
            autoCorrect={false}
            secureTextEntry={false}
            style={{width: isWeb() ? '105%' : '90%'}}
            value={cellphone?.inputNum}
            color={colors.purpleGray}
            inputStyle={{
              paddingVertical: 2,
              textAlignVertical: 'center',
              fontFamily: 'Quicksand',
              fontWeight: '600',
              fontSize: 18,
              outline: 0,
            }}
            onChangeText={onChange}
            variant={'outlined'}
            label={t('login:inputPhoneNumber')}
          />
        </Provider>
      </Box>
      {cellphone?.number?.length >= 0 && showWarning && (
        <Box
          w={'100%'}
          h={5}
          my={1}
          flex={1}
          borderRadius={'full'}
          bgColor={
            validationLength || validPhone ? colors.transparent : colors.warning
          }>
          <Text
            ml={2}
            textAlign={'left'}
            color={validationLength || validPhone ? colors.black40 : colors.red}
            fontSize={'xs'}>
            {showTextCode
              ? validationLength || validPhone
                ? type === 'register'
                  ? t('login:codeConfirmation')
                  : ''
                : t('login:inputNumberInvalid')
              : validationLength || validPhone
              ? ''
              : t('login:inputNumberInvalid')}
          </Text>
        </Box>
      )}
    </>
  );
};

SelectPhone.propTypes = {
  validPhone: PropTypes.bool.isRequired,
  setValidPhone: PropTypes.func.isRequired,
  cellphone: PropTypes.object,
  setCellphone: PropTypes.func.isRequired,
};

export default SelectPhone;
