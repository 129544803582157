export default {
  anonymousTitle: 'Bienvenida',
  agreeButton: 'Aceptar',
  buttonContinue: 'Continuar',
  buttonStart: 'Comenzar',
  confidenciality1:
    'Antes de comenzar, quiero\n que sepas que los mejores expertos\n en salud mental me programaron\n para explicarte técnicas muy efectivas\n para cuidar de ti y que todo lo que\n hables conmigo será confidencial.',
  confidenciality2:
    'En caso de que considere\n que estás en riesgo, me comunicaré\n con el contacto de emergencia que me indiques, ¿de acuerdo?',
  confidenciality3: '¡Estoy aquí para ti!',
  confidencialityTitle: 'Gracias por tu confianza',
  confidentialityTitle: 'Mensaje de Confidencialidad',
  inputContactEmergencyName: 'Contacto de emergencia',
  inputContactEmergencyPhone: 'Num. de contacto de emergencia',
  inputProfilingAge: 'Selecciona tu fecha de nacimiento:',
  inputProfilingBirthDate: 'Selecciona tu fecha de nacimiento:',
  inputProfilingChildren: 'Cuéntame si tienes hijos:',
  inputProfilingContactSchedule:
    'Elige la hora del día en que tienes tiempo\n para que podamos hablar.',
  inputProfilingGender: 'Selecciona el género con el que te identificas:',
  inputProfilingName: 'Escribe tu nombre:',
  inputProfilingNationality: 'Selecciona tu nacionalidad:',
  inputProfilingOccupation: 'Selecciona tu ocupación:',
  inputProfilingSalaryRange: 'Cuéntame cual es tu rango salarial:',
  inputProfilingWorkDeepOther: '¿Podrías decirme cuál es ese otro?',
  inputProfilingWorkDep: 'Dime el giro de tu trabajo:',
  inputSelectPlaceholder: 'Elige una opción',
  preProfiling2:
    'Eso me ayudará a saber cómo\n puedo guiarte en esta aventura.',
  preProfiling: 'Me encantaría conocer\n un poco más de ti.',
  preferNotToSay: 'Prefiero no decirlo',
  profilingLabel: 'Cuéntame más de ti',
  omitLogin: 'Comenzar a explorar',
  loginButton: 'Iniciar sesión ',
  registerButton: 'Crear cuenta',
  screen1Text:
    'En Mindsurf tendrás todas las herramientas necesarias para sentirte mejor y reducir síntomas de ansiedad, estrés y más.',
  screen1Title: 'Mejora tu bienestar',
  screen2Text:
    'Podrás registrar tus estados de ánimo y recibirás recomendaciones de ejercicios para manejar lo que sientes.',
  screen2Title: 'Conoce tus emociones',
  screen3Text:
    'Aprenderás técnicas para la vida diaria en una terapia a tu ritmo y disponible 24/7 o con asistencia online de un terapeuta.',
  screen3Title: 'Afronta los retos de la vida',
  screenOnboardingTitle: 'Bienestar emocional',
  screenOnboardingDesc:
    'Mejora tu bienestar con ejercicios guiados y terapia en línea, disponible en cualquier momento.',
  selectCareerAccount: 'Contabilidad',
  selectCareerCS: 'Informática',
  selectCareerFinance: 'Finanzas',
  selectCareerLog: 'Logística',
  selectCareerManagement: 'Dirección',
  selectCareerMarketing: 'Marketing',
  selectCareerOther: 'Otro',
  selectCareerProduction: 'Producción',
  selectCareerRH: 'RH',
  selectCareerSales: 'Ventas',
  selectChildrenNot: 'No',
  selectChildrenYes: 'Sí',
  selectGenderFemale: 'Femenino',
  selectGenderMale: 'Masculino',
  selectGenderNon: 'No binario',
  selectNationality: 'Mexicana',
  selectNationalityOther: 'Otra',
  selectOccNone: 'Ninguna de las dos',
  selectOccStudent: 'Estudio',
  selectOccWork: 'Trabajo',
  selectSalary11: '11-25 mil',
  selectSalary25: '25-45 mil',
  selectSalaryLess: '-10 mil',
  selectSalaryMore: '+45 mil',
  selectSalaryNot: 'Prefiero no contestar',
  studyAndWork: 'Estudio y trabajo',
};
