import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Box, Center, HStack, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import {isWeb} from '../../helpers/utils/utils';

const CardTime = ({title = 'Mindsurf', time = 10}) => {
  const {t} = useTranslation();
  return (
    <Center my={3}>
      <Box w={'60%'} bg={'secondary.30'} py={2} px={3} rounded={'full'}>
        <Text
          fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
          textAlign={'center'}
          fontWeight={800}>
          {`${title} · ${time} ${t('evaluations:minutes')}`}
        </Text>
      </Box>
    </Center>
  );
};

export default CardTime;
