import React from 'react';
import AnimatableBox from '../../AnimatableBox/AnimatableBox';
import {Center} from 'native-base';
import TypingDot from '../../TypingDot';

const WaitingForResponse = () => {
  return (
    <>
      <AnimatableBox
        animation="fadeInLeftBig"
        // style={chatStyles.typing}
      >
        <Center p={7} alignSelf={'flex-start'}>
          <TypingDot />
        </Center>
      </AnimatableBox>
    </>
  );
};

export default WaitingForResponse;
