import {AppRegistry} from 'react-native';
import AppWeb from './App.web';
import * as Sentry from '@sentry/react';
import Config from 'react-native-config';
// import font
import objectSans from './res/fonts/Object_Sans/PPObjectSans-Regular.ttf';
import recoleta from './res/fonts/Recoleta/Recoleta.otf';
import quicksand from './res/fonts/Quicksand/Quicksand.ttf';
import quicksandBold from './res/fonts/Quicksand/Quicksand-Bold.ttf';
import quicksandLight from './res/fonts/Quicksand/Quicksand-Light.ttf';
import quicksandMedium from './res/fonts/Quicksand/Quicksand-Medium.ttf';
import quicksandSemiBold from './res/fonts/Quicksand/Quicksand-SemiBold.ttf';

const regularFontStyles = `@font-face {
  src: url(${
    (recoleta,
    quicksand,
    quicksandBold,
    quicksandLight,
    quicksandMedium,
    quicksandSemiBold)
  });
  font-family: 'Recoleta';
  font-family: 'Quicksand';
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = regularFontStyles;
} else {
  style.appendChild(document.createTextNode(regularFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

const {ENV} = Config;

Sentry.init({
  dsn: 'https://fcd0c6b4f8a3482ead4f9622ce46f8e2@o264458.ingest.sentry.io/6324457',
  environment: ENV,
  debug: ENV === 'local' || ENV === 'staging',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
});

AppRegistry.registerComponent('App', () => AppWeb);
AppRegistry.runApplication('App', {rootTag: document.getElementById('root')});
