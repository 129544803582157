import {Box, Center, Image} from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import images from '../res/images';
import {createMarkdownStyles} from '../styles/base';
import {colors} from '../styles/colors';
import {fonts} from '../styles/fonts';
import Markdown from './Markdown/Markdown';

/**
 * Componente  Quote normal
 * @params
 * img: imagen que muestra/ si no trae pone un circulo amarillo
 * text: Texto en marckdown
 * imageSize: Tamaño de la imagen: md para quote y xl para insignia
 */

const Quote = ({content}) => {
  if (!content) {
    return null;
  }
  const imageSize = 'md';
  const {insignia} = images;

  function getImageUrl() {
    if (!content.includes('/***/')) {
      return null;
    }
    let img = content.indexOf('/***/');
    img = content.substring(img + 5);
    return img;
  }

  function getText() {
    const txt = content.split('/***/');
    return txt[0];
  }

  const img = getImageUrl();
  const text = getText();

  const markdownStyles = createMarkdownStyles({
    paragraph: {
      color: colors.tertiary100,
      fontSize: '20@ms',
      fontWeight: '300',
      fontFamily: fonts.objectSans,
      textAlign: 'center',
      lineHeight: 28,
    },
  });

  const imageToShow = img ? {uri: img} : insignia;
  return (
    <Center>
      <Center
        testID="quote-content"
        w={'90%'}
        bg={'primary.600'}
        rounded={'lg'}
        p={7}>
        <Image
          alt={'Insignia'}
          source={imageToShow}
          size={imageSize}
          resizeMode={'contain'}
        />
        <Box m={3}>
          <Markdown styles={markdownStyles}>{text}</Markdown>
        </Box>
      </Center>
    </Center>
  );
};

Quote.propTypes = {
  text: PropTypes.string,
};
export default Quote;
