import React, {useEffect} from 'react';
import {Box, Center, Text} from 'native-base';
import PropTypes from 'prop-types';
import {createChatStyles} from '../styles/base';
import Loading from './Loading';
import {useTranslation} from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

const FloatingUserInfo = ({user}) => {
  if (!user?.userFB) return null;
  const [showBanner, setShowBanner] = React.useState(false);

  console.log('FloatingUserInfo.user', user);
  const chatStyles = createChatStyles();
  const {t} = useTranslation();

  useEffect(() => {
    AsyncStorage.getItem('registerPrefix').then(registerPrefix => {
      if (registerPrefix) {
        setShowBanner(true);
      }
    });
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <Box
      position={'absolute'}
      top={10}
      left={'2%'}
      w={'96%'}
      rounded={'full'}
      style={[chatStyles.backgroundButton]}
      borderRadius={'full'}>
      <Center roundedTop={'md'} w={'100%'}>
        {loadingData || !user?.userCMS ? (
          <Loading />
        ) : (
          <Text fontSize={'lg'} style={chatStyles.textButton}>
            {t('profile:userName', {name: user?.userCMS?.nombres})}
          </Text>
        )}
      </Center>
    </Box>
  );
};

FloatingUserInfo.prototypes = {
  user: PropTypes.object.isRequired,
};
export default FloatingUserInfo;
