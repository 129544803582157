export default {
  typeExcercise: 'Ejercicio de ',
  descriptionLabel: 'Descripción',
  duration: 'Duración: ',
  lastRecord: 'Última interacción: {{date}}',
  minutes: '{{duration}} minutos',
  recomendation: 'Esto puede interesarte:',
  seeHistory: 'Ver historial',
  historyTitle: 'Historial de interacciones',
  lastInteraction: 'Última interacción: ',
};
