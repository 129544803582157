import React, {useEffect, useState} from 'react';
import {Center, HStack, Pressable} from 'native-base';
import PropTypes from 'prop-types';
//import Sound from 'react-native-sound';
import {Animated as Anima, Easing} from 'react-native';
//import Icon from 'react-native-vector-icons/AntDesign';

/**
 * Componente AudioWaveSoundPlayer
 * Este componente emulará la funcionalidad de AudioWave pero usando la librería:
 * react-native-sound
 * Se tomó inspo de SoundTrack component.
 * Por qué no reutilizamos AudioWave o SoundTrack ya que son una mezcla de ambos?
 * 1. AudioWave inicializa configuraciones de react-native-track-player que no queremos
 * 2. Soundtrack está muy amarrado a su UI.
 * Solución:
 * Usar UI de audioWave y funcionalidad de SoundTrack
 * @param {string} url
 * @param {boolean} bot
 * @param {function} onPlayAudio
 * @param {function} onPauseAudio
 * @param {boolean} canPlay
 * @returns {JSX.Element}
 */
const dotAnimations = Array.from({length: 10}).map(() => new Anima.Value(1));

const AudioWaveSoundPlayer = ({
  url,
  onPlayAudio = () => {},
  onPauseAudio = () => {},
  bot = true,
  canPlay = true,
}) => {
  const [soundRef, setSoundRef] = useState(null);
  const [playingState, setPlayingState] = useState('initial');

  const loopAnimation = node => {
    const keyframes = [1.2, 0.7, 1];

    return Anima.sequence(
      keyframes.map(toValue =>
        Anima.timing(node, {
          toValue,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ),
    );
  };

  const loadAnimation = nodes =>
    Anima.loop(Anima.stagger(200, nodes.map(loopAnimation))).start();

  React.useEffect(() => {
    loadAnimation(dotAnimations);
  }, []);

  useEffect(() => {
    if (!url) return;

    /*const soundRefNew = new Sound(url.trim(), null, error => {
      if (error) {
        console.log('Error 🌴', error);
      }
    });
    setSoundRef(soundRefNew);
    return () => {
      removeSound();
    };*/
  }, [url]);

  function playComplete(success) {
    console.log('playComplete(success)');
    console.log(success);
    setPlayingState('paused');
    if (soundRef) {
      if (success) {
        console.log('successfully finished playing');
        soundRef.pause();
        onPauseAudio();
      } else {
        setTimeout(play, 100);
        console.log('playback failed due to audio decoding errors');
      }
    }
  }

  function pause() {
    if (!soundRef) return;
    setPlayingState('paused');
    soundRef.pause();
    onPauseAudio();
  }

  async function play() {
    if (!soundRef) return;
    setPlayingState('playing');
    soundRef.play(playComplete);
    onPlayAudio();
  }

  function removeSound() {
    if (soundRef) {
      soundRef.release();
      setSoundRef(null);
      setPlayingState('paused');
    }
  }

  const RecordingDots = ({barColor}) => {
    const stylesBar = {
      height: 20,
      width: 3,
      borderRadius: 2,
      marginHorizontal: 2,
    };

    function hasAnimation(animation) {
      if (playingState === 'paused' || playingState === 'initial') {
        return null;
      }
      return {
        transform: [
          {
            scale: animation,
          },
        ],
      };
    }

    return (
      <HStack justifyContent={'center'} flex={1} alignItems={'center'}>
        {dotAnimations.map((animation, index) => {
          return (
            <Anima.View
              key={`${index}`}
              style={[
                {...stylesBar},
                {backgroundColor: barColor},
                {...hasAnimation(animation)},
              ]}
            />
          );
        })}
      </HStack>
    );
  };

  if (!canPlay) {
    return null;
  }

  return (
    <HStack>
      <Center>
        <Pressable
          testID={'on-play-pause-button-sound'}
          onPress={playingState === 'playing' ? pause : play}>
          {/*<Icon
            name={playingState === 'playing' ? 'pausecircle' : 'play'}
            size={35}
            color={bot ? 'white' : 'black'}
  />*/}
        </Pressable>
      </Center>
      <Center mx={2} testID={'wave-playing'}>
        <RecordingDots barColor={bot ? 'white' : 'black'} />
      </Center>
    </HStack>
  );
};

AudioWaveSoundPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  bot: PropTypes.bool,
  onPlayAudio: PropTypes.func,
  onPauseAudio: PropTypes.func,
};
export default AudioWaveSoundPlayer;
