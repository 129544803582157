import {Box, Flex, Modal, Text} from 'native-base';
import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import InfoIcon from './icons/InfoIcon';
import WarningIcon from './icons/WarningIcon';
import {isWeb} from '../helpers/utils/utils';
import IconIon from './Icon/IconIon';
import {colors} from '../styles/colors';

const MessageSystem = ({
  isWarning,
  showModalMessage,
  setShowModalMessage,
  message,
  testID = 'message-system-id',
}) => {
  const insets = useSafeAreaInsets();
  if (!showModalMessage) return <></>;
  return (
    <Modal
      testID={testID}
      isOpen={showModalMessage}
      size={isWeb() ? 'lg' : 'full'}
      px={2}
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: insets.top === 0 ? 50 : insets.top,
      }}
      _backdrop={{
        opacity: 0,
        bg: isWarning ? 'linen.400' : 'babyBlue.400',
        onPress: () => {
          setShowModalMessage(false);
        },
      }}>
      <Modal.Content
        borderRadius={'3xl'}
        bg={isWarning ? 'linen.400' : 'babyBlue.400'}>
        <Modal.CloseButton
          testID="close-btn"
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
          onPress={() => {
            setShowModalMessage(false);
          }}
        />
        <Modal.Body>
          <Flex flexDir={'row'} alignItems="center" justifyContent="center">
            <Box mr={5}>
              {isWeb() ? (
                isWarning ? (
                  <IconIon
                    name={'warning'}
                    size={25}
                    color={colors.negative5}
                  />
                ) : (
                  <IconIon name={'info'} size={25} color={colors.babyBlue90} />
                )
              ) : isWarning ? (
                <WarningIcon />
              ) : (
                <InfoIcon />
              )}
            </Box>
            <Text
              w={'80%'}
              pr={5}
              textAlign={'left'}
              adjustsFontSizeToFit={true}
              fontSize={'md'}
              color={'black.40'}>
              {message}
            </Text>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default MessageSystem;
