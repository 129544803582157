export default {
  buttonContinue: 'Guardar',
  buttonSave: 'Guardar',
  buttonConfirm: 'Confirmar',
  buttonCreateAccount: 'Crear cuenta',
  register: 'Crear una cuenta',
  buttonLogin: 'Iniciar sesión',
  buttonNext: 'SIGUIENTE',
  buttonRecover: 'Enviar',
  callCodeLabel: 'Recibir llamada',
  codeEmailLabel: 'Revisa tu bandeja de entrada y la de correos no deseados.',
  codeSMSLabel: 'Envíamos un código de verificación al número',
  codeSMSResetLabel: 'Revisa tus mensajes. Te llegará un SMS a tu número.',
  writeCode: 'Escribe el código',
  createAccountButton: 'CREAR UNA CUENTA',
  createAccountButtonOr: 'o crear una cuenta',
  createAccountTitle: 'Crear una cuenta',
  youBackTitle: '¡Estás de vuelta!',
  emailCodeAgain: 'Enviar otro código por email',
  employeeLoginHelperTitle: '¿Tienes problemas para ingresar?',
  employeeLoginHelper:
    'Si no conoces tu número, por favor, contacta con Recursos Humanos de tu empresa. ¡Te esperamos!',
  forgotPasswordLink: '¿Olvidaste tu contraseña?',
  inputCode: 'Escribe el código. ',
  inputCodeDiscount: 'Tengo acceso empresarial',
  inputCodeDiscountLabel: 'Escribe tu número de póliza o código.',
  inputCodeHelper: '¿No te llegó el código?',
  inputCodeLabel:
    'Si tienes problemas para ingresar o no te llega el código escríbenos a ',
  inputCorporateAccess: 'Tengo acceso empresarial',
  inputEmail: 'Escribe tu mail.',
  inputEmailInvalid: 'El correo electrónico es inválido.',
  inputEmployeeNumber: 'Escribe tu número de empleado.',
  inputEmployeeNumberInvalid: 'El número de empleado es inválido.',
  inputName: 'Escribe tu nombre completo.',
  inputNameInvalid: 'El nombre es inválido.',
  inputNumberInvalid: 'El número de teléfono es inválido',
  inputPassword: 'Nueva contraseña',
  inputPasswordLogin: 'Contraseña',
  inputPasswordAgain: 'Confirma tu nueva contraseña.',
  inputPasswordAgainInvalid: 'La contraseña debe coincidir.',
  inputPasswordInvalid:
    'Debe tener al menos 8 caracteres, una mayúscula, un número y un caracter especial.',
  inputPhoneHelper: 'Te enviaré un código de confirmación.',
  inputPhoneNumber: 'Teléfono',
  inputPoliceNumber: 'Escribe tu número de póliza.',
  labelLoginEmail: 'Inicia sesión con tu correo electrónico',
  labelLoginEmployee: 'Inicia sesión con tu número de empleado',
  labelLoginPhone: 'Inicia sesión con tu número de celular',
  labelSignUpEmail: 'Regístrate con tu correo electrónico',
  labelSignUpPhone: 'Regístrate con tu número de celular',
  loginAccount: 'o iniciar sesión',
  loginAccountLower: 'Iniciar sesión',
  mail: 'Correo',
  newPasswordButton: 'Cambiar',
  newPasswordLabel: 'Crea una nueva contraseña',
  descNewPassword: 'Anótala para que no se te olvide 😉',
  phone: 'Teléfono',
  recoverLabelEmail: 'Escribe tu correo para enviarte un código.',
  recoverLabelPhone:
    'Escribe tu número de teléfono para enviarte un código por SMS.',
  recoverPassword: 'Vamos a recuperar tu acceso',
  titleNewPassword: 'Cambia tu contraseña',
  recoverWhitEmail: 'Recuperar por correo',
  recoverWhitPhone: 'Recuperar con mi teléfono',
  notRegisterEmail: 'Tal vez no te registrate con tu correo',
  notRegisterPhone: 'Tal vez no te registraste con tu número',
  spamLabel: 'ó revisa tu bandeja de correos no deseados',
  whatsappCodeLabel: 'Recibir por WhatsApp',
  wa: 'Te hemos enviado un mensaje vía Whatsapp con el código de ingreso, por favor espera.',
  call: 'Estamos tratando de contactarte, por favor espera.',
  isRegisterDes:
    'Inicia sesión con tu numero de teléfono, si no recuerdas tu contraseña puedes recuperarla.',
  isRegisterEmailDes:
    'Inicia sesión con tu correo electrónico, si no recuerdas tu contraseña puedes recuperarla.',
  isRegisterTitle: 'Parece que ya tienes una cuenta con nosotros',
  emailCode:
    'Te hemos enviado nuevamente tu código de acceso por correo electrónico',
  emailCodeTitle: 'Código enviado',
  smsRecovery: 'Te enviamos un SMS con un código para recuperar tu contraseña',
  emailRecovery:
    'Te enviamos un correo electrónico para recuperar tu contraseña.',
  termsAndConditions: {
    accept: 'Acepto ',
    termsAndConditions: 'Acepto términos y condiciones',
    consentOfInteraction: 'Consentimiento de Interacción',
    and: ' y ',
    privacyNotice: 'Aviso de privacidad',
  },
  codeConfirmation: 'Recibirás un código de confirmación.',
  ready: 'Estará lista en segundos.',
  goodSeeYou: 'Qué gusto verte 🤗',
  recoverAccess: 'Recuperar acceso',
  wait: 'Espera ',
  sendCodeEmail: 'Revisa tu bandeja de entrada y la de correos no deseados.',
  sendCodePhone: 'Te llegará un SMS a tu número.',
  sendCodeEmailModal: 'Te llegará un correo a',
  sendCodePhoneModal: 'Te llegará un SMS al',
  recoverPass: ' para cambiar tu contraseña',
  sendOtherCodeEmail: 'Te llegará otro correo a',
  sendCodeWha: 'Te llegará un Whatsapp',
  sendCodeEmailRegister: 'Te llegará un correo a',
  sendCodePhoneRegister: 'Te llegará un SMS al',
  verifyAccount: 'para verificar tu cuenta.',
  acceptTerms: 'Debes aceptar los términos y condiciones.',
  errorB2B:
    'Lo sentimos, no pudimos crear esta cuenta. Contacta con Recursos Humanos de tu empresa.',
};
