export default {
  buttonBack: 'REGRESAR',
  buttonStartContent: 'Iniciar $content',
  buttonStart: 'Iniciar',
  firstMenuTitle: '¿De qué temas te interesa aprender?',
  select3: 'Selecciona hasta 3',
  showMore: 'MOSTRAR MÁS',
  next: 'SIGUIENTE',
  finish: 'Ver resultado',
  done: 'Terminar',
  pauseTitle: 'Vas a pausar tu evaluación',
  titleIntro: 'Evaluación de $evaluation',
  therapy: 'Terapia',
  therapyDsc: 'Agendar terapia \n con un especialista',
  pauseDsc:
    'La hacemos para saber cómo ayudarte. Si no puedes terminarla ahora, retómala la próxima vez donde la dejaste.',
  attention1: 'Atención inicial',
  attention1Dsc: 'Tienes síntomas muy leves de este padecimiento.',
  attention2: 'Atención necesaria',
  attention2Dsc: 'Tienes síntomas moderados de este padecimiento.',
  attention3: 'Atención urgente',
  attention3Dsc:
    'Tienes síntomas severos de este padecimiento y es importante que los atiendas lo antes posible.',
  reportTitle: 'Reporte de $evaluation',
  reportTitleOneRow: 'Reporte de {{evaluation}}',
  reportDate: 'Te evaluaste el $date',
  reportDsc:
    'Lee este reporte con atención porque te ayudará a saber cómo estás\ny si tienes áreas de mejora en tu\nbienestar emocional.',
  levelAttention: 'Tu nivel de atención es:',
  buttonTherapy: 'Explorar terapuetas',
  buttonClose: 'Cerrar',
  buttonHome: 'Volver al inicio',
  pauseSession: 'Pausar Sesión',
  minutesLabel:
    'Te haré un cuestionario de opción múltiple con duración de unos minutos',
  explore: 'Vamos a explorar',
  startSession: 'Comenzar sesión 1',
  minutes: 'minutos',
  titleCardTime: 'Evaluación',
  chat: 'Chat',
  evaluationHeader: '{{title}}',
  evaluationModalDone:
    '¡Perfecto 🤩! Ya terminaste este cuestionario. ¡Gracias por responder!',
};
