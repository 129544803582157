import {Box, Center, FormControl, Image, Link, Text} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputForm from '../../components/Form/InputForm';
import NavigateBar from '../../components/NavigateBar';
import PrimaryButton from '../../components/PrimaryButton';
import SelectPhone from '../../components/SelectPhone';
import {LOGIN_TYPES} from '../../constants/codeConstants';
import images from '../../res/images';
import {colors} from '../../styles/colors';
import {fonts} from '../../styles/fonts';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';

/**
 * Screen de Recuperar la contraseña
 */

export default function PasswordRecovery({
  defaultData,
  onBack,
  onSubmit,
  loading,
  commonStylesWeb,
}) {
  const {t, i18n} = useTranslation();
  const {topBarLogo} = images;
  const {RECOVERY_EMAIL, RECOVERY_PHONE} = LOGIN_TYPES;
  const [phoneNumber, setPhoneNumber] = useState(setDefaultProps('phone'));
  const [validPhoneNumber, setvalidPhoneNumber] = useState(
    setDefaultProps('vphone'),
  );
  const [isFocused, setIsFocused] = useState(false);
  const [email, setEmail] = useState(setDefaultProps('email'));
  const [validEmail, setValidEmail] = useState(true);
  const [recoveryType, setRecoveryType] = useState(defaultData?.lwc);
  const [helperTextEmail, setHelperTextEmail] = useState();

  const {height} = getWidthAndHeight();

  const setCellphone = useCallback(
    val => {
      setPhoneNumber(val);
    },
    [setPhoneNumber],
  );
  const setValidPhone = useCallback(
    val => {
      setvalidPhoneNumber(val);
    },
    [setvalidPhoneNumber],
  );

  useEffect(() => {
    setHelperTextEmail(
      validEmail ? t('login:codeConfirmation') : t('login:inputEmailInvalid'),
    );
  }, [validEmail]);

  function setDefaultProps(field) {
    switch (field) {
      case 'phone':
        if (!defaultData || !defaultData.phone) {
          const code = i18n.language === 'es' ? 'MX' : 'US';
          const iso = i18n.language === 'es' ? '+52' : '+1';
          return {
            codeCountry: code,
            isoCountry: iso,
            number: '',
            inputNum: '',
          };
        }
        return defaultData.phone;
      case 'email':
        if (!defaultData || !defaultData.email) {
          return '';
        }
        return defaultData.email;
      case 'lwc':
        return !(defaultData && defaultData.email);
      case 'vphone':
        if (defaultData && defaultData.phone) {
          return true;
        }
        return false;
    }
  }

  function validateForm() {
    return (email && validEmail) || (phoneNumber.number && validPhoneNumber);
  }

  function submit() {
    const type = phoneNumber.number ? RECOVERY_PHONE : RECOVERY_EMAIL;
    onSubmit({
      type,
      email,
      phoneNumber,
    });
  }

  const handleEmailChange = text => {
    setEmail(text);
    let reg =
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
    setValidEmail(text.length === 0 || (text.length > 3 && reg.test(text)));
  };

  const handleRecoveryPass = () => {
    setRecoveryType(!recoveryType);
  };

  return (
    <Box mt={isWeb() ? 20 : 0}>
      <NavigateBar
        backEvent={onBack}
        headerTitle={
          <Image
            size={'xs'}
            resizeMode={'contain'}
            alt={'logo'}
            source={topBarLogo}
          />
        }
      />
      {/* Titulo */}
      <Box alignItems="center">
        <Text
          testID={'login-recover-password-label'}
          w={'90%'}
          mt={3}
          fontWeight={800}
          fontFamily={isWeb() ? 'Recoleta' : 'recoleta'}
          textAlign="center"
          color="primary.100"
          fontSize={'3xl'}>
          {t('login:recoverPassword')}
        </Text>
        <Text
          w={'80%'}
          mt={3}
          mb={10}
          textAlign="center"
          color="primary.100"
          fontSize="lg"
          // bold={true}
        >
          {recoveryType
            ? t('login:recoverLabelPhone')
            : t('login:recoverLabelEmail')}
        </Text>
      </Box>
      {/* Form */}
      <Center px={3} w={'100%'}>
        {recoveryType ? (
          <FormControl testID="phone-form" mb="1">
            {isFocused && (
              <FormControl.Label
                testID={'middle-id-login-phone'}
                _text={{
                  fontSize: 'md',
                  fontWeight: 'normal',
                  color: 'primary.100',
                }}>
                {t('login:inputPhoneNumber')}
              </FormControl.Label>
            )}
            <SelectPhone
              id={'input-phone-recovery'}
              validPhone={validPhoneNumber}
              setIsFocused={setIsFocused}
              setValidPhone={setValidPhone}
              cellphone={phoneNumber}
              setCellphone={setCellphone}
              language={i18n.language}
            />
          </FormControl>
        ) : (
          <FormControl mb="1" isInvalid={!validEmail}>
            {/* Email */}
            <InputForm
              helperText={helperTextEmail}
              testID={'email-input-recovery'}
              label={t('login:inputEmail')}
              input={email}
              setInput={handleEmailChange}
              isEmail={true}
              valid={validEmail}
            />
          </FormControl>
        )}
      </Center>
      <Box px={3}>
        <PrimaryButton
          testId={'button-recovery'}
          onPress={submit}
          extraParams={{
            isLoading: loading,
            isDisabled: !validateForm(),
            mt: isWeb() ? '10%' : '20%',
            ...commonStylesWeb,
          }}>
          {t('login:buttonRecover')}
        </PrimaryButton>
      </Box>
      <Text mt={10} textAlign={'center'} color={colors.purpleGray}>
        {recoveryType
          ? t('login:notRegisterPhone')
          : t('login:notRegisterEmail')}
      </Text>
      <Center>
        <Link
          testID={'recovery-type'}
          p={0}
          mb={5}
          isUnderlined={false}
          _text={{
            fontSize: 'md',
            color: colors.primary100,
            fontWeight: 700,
            textAlign: 'center',
            fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
          }}
          color="primary.100"
          onPress={handleRecoveryPass}>
          {recoveryType
            ? t('login:recoverWhitEmail')
            : t('login:recoverWhitPhone')}
        </Link>
      </Center>
    </Box>
  );
}
