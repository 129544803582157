import {Center} from 'native-base';
import EyeOpen from '../../res/svgs/eyeOpen.svg';
import React from 'react';
import PropTypes from 'prop-types';

const EyeOpenIcon = ({colour = 'white', h = 30, w = 30}) => {
  return (
    <Center>
      <EyeOpen width={h} height={w} fill={colour} />
    </Center>
  );
};

EyeOpenIcon.propTypes = {
  colour: PropTypes.string,
};
export default EyeOpenIcon;
