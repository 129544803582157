/* istanbul ignore file */

/**
 * Servicios para notificación
 */
import AsyncStorage from '@react-native-async-storage/async-storage';
import {fbFunctions, messaging} from '../firebase/firebaseAuth';

async function getToken() {
  return;
  let fcmToken = await AsyncStorage.getItem('fcmToken');
  // TODO: ASK Fer cada cuando se tiene que pedir el token.
  // if (fcmToken) {
  //   return;
  // }

  fcmToken = null;
  const message = messaging.getMessaging();
  return await messaging
    .getToken(message, {
      vapidKey:
        'BOhAqHs6FRzwp2MvDeuIWFIwxJTCNgUyj01QG9u05_OM-gPzjuYVIXfqeK6S0xkxCTlC7BZRbuBB8USI3nwIT7s',
    })
    .then(async token => {
      fcmToken = token;
      await AsyncStorage.setItem('fcmToken', fcmToken);
      //await setTokenToDB(fcmToken);
      return token;
    })
    .catch(err => {
      console.log('error de messaging ', err);
    });
  // if (fcmToken) {
  //   try {
  //     console.log('FCM TOKEN: ', fcmToken);
  //     await AsyncStorage.setItem('fcmToken', fcmToken);
  //     await setTokenToDB(fcmToken);
  //   } catch (e) {
  //     console.log('error en token', e);
  //   }
  //   return fcmToken;
  // }
}

async function getPermission() {
  try {
    await messaging().requestPermission();
  } catch (error) {
    console.log('permission rejected: ', error);
  }
}

// TODO: Si se reemplaza por la mutación sería aqui
async function setTokenToDB(token) {
  console.log('token: <> ', token);
  const authVerifyCode = fbFunctions.httpsCallable('setFCMToken');
  try {
    const {data} = await authVerifyCode({token});
    console.log('dataa', data);
    if (data?.status !== 'SUCCESS') {
      throw new Error(data?.message);
    }
  } catch (e) {
    console.log('🟥 Error en set token: ', e);
    throw new Error(e?.message);
  }
}

export const requestPermissions = async () => {
  // const enabled = await messaging.hasPermission();
  //  console.log('🐞', enabled);
  // if (enabled) {
  return await getToken();
  // }
  //await getPermission();
};

export const processNotification = async data => {
  // console.log('processNotification data', data);
  // // Mapa de actons disponibles con nombre de la ruta
  // const actions = {
  //   DiaryEntry: {name: 'Diario emocional'},
  //   Program: {name: 'Intro Programa'},
  //   LiveSession: {name: 'Meditaciones'},
  // };
  // let action = actions[data.action] || {name: 'Home'};
  // if (data.routeName) {
  //   action = {name: data.routeName};
  // }
  // action.params = {...action.params, from: 'pushNotification'};
  // // Enviar value como params de la función navigate
  // if (data.value) {
  //   const params = JSON.parse(data.value);
  //   action.params = {...action.params, ...params};
  // }
  // console.log('processNotification navigate', action);
  // rudderClient.track('push_notification', {action, data, eventType: 'process'});
  // navigate(action);
};

export const onMessageNotification = async callback => {
  // return messaging().onMessage(callback);
};

export const backgroundMessageHandler = async () => {
  // messaging().setBackgroundMessageHandler(async remoteMessage => {
  //   console.info('Message handled in the background!', remoteMessage);
  //   await AsyncStorage.setItem('push', JSON.stringify(true));
  //   await rudderClient.track('push_notification', {
  //     action: 'backgroundMessageHandler',
  //     data: remoteMessage,
  //     eventType: 'process',
  //   });
  // });
};

export const onNotificationOpenedApp = async callback => {
  //return messaging().onNotificationOpenedApp(callback);
};

export const getInitialNotification = async callback => {
  //return messaging().getInitialNotification().then(callback);
};
