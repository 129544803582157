import {Button, HStack} from 'native-base';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {isWeb} from '../helpers/utils/utils';

const Tab = ({
  options,
  setState = () => {},
  defaultOption = false,
  testID = 'tab-options',
}) => {
  const [option, setOption] = useState(defaultOption);

  const handleClick = () => {
    setOption(!option);
    setState(!option);
  };

  return (
    <HStack
      flexDirection={!option ? 'row' : 'row-reverse'}
      alignItems={'center'}
      borderRadius={'full'}
      borderWidth={1}
      borderColor={'secondary.600'}
      w={'65%'}>
      <Button
        testID={testID}
        shadow={3}
        onPress={() => handleClick()}
        px={5}
        borderRadius={'full'}
        _pressed={{bg: 'secondary.5'}}
        bg={'secondary.5'}
        w={'50%'}
        _text={{
          fontWeight: 800,
          color: 'primary.100',
          fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
        }}>
        {option ? options?.[0] : options?.[1]}
      </Button>
      <Button
        onPress={() => handleClick()}
        px={5}
        borderRadius={'full'}
        _pressed={{bg: 'transparent'}}
        bg={'transparent'}
        w={'50%'}
        _text={{
          fontWeight: 800,
          color: 'primary.100',
          fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
        }}>
        {option ? options?.[1] : options?.[0]}
      </Button>
    </HStack>
  );
};

Tab.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  setState: PropTypes.func.isRequired,
};

export default Tab;
