import React, {useEffect, useState} from 'react';
import {Center, HStack, Pressable} from 'native-base';
import PropTypes from 'prop-types';
/*import TrackPlayer, {
  State,
  usePlaybackState,
  useProgress,
} from 'react-native-track-player';*/
//import Icon from 'react-native-vector-icons/AntDesign';
import {ActivityIndicator, Animated as Anima, Easing} from 'react-native';

/**
 * Componente  AudioWave normal
 */

const dotAnimations = Array.from({length: 10}).map(() => new Anima.Value(1));
const buttonStates = {
  READY: 'READY',
  LOADING: 'LOADING',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
};
const AudioWave = ({url, id, title, bot = true, lastMessageAudio}) => {
  const [playOrPauseIcon, setPlayOrPauseIcon] = useState('play');
  const [buttonState, setButtonState] = useState(buttonStates.READY);
  const [track, setTrack] = useState(null);
  const progress = useProgress();
  const stateTrack = usePlaybackState();

  const loopAnimation = node => {
    const keyframes = [1.2, 0.7, 1];

    const loop = Anima.sequence(
      keyframes.map(toValue =>
        Anima.timing(node, {
          toValue,
          easing: Easing.ease,
          useNativeDriver: true,
        }),
      ),
    );

    return loop;
  };

  const loadAnimation = nodes =>
    Anima.loop(Anima.stagger(200, nodes.map(loopAnimation))).start();

  React.useEffect(() => {
    loadAnimation(dotAnimations);
  }, []);

  useEffect(() => {
    if (url) {
      setTrack({
        id: id,
        url: url,
        title: title,
        progress: 0,
      });
    }
    // TrackPlayer.add({
    //   id,
    //   url,
    //   title,
    // }).then(r => {
    //   console.log('Added: ', r);
    // });
    return async () => {
      //console.log('ya me morí');
      // await TrackPlayer.reset();
    };
  }, [id]);

  useEffect(() => {
    TrackPlayer.reset();
    if (bot && lastMessageAudio) {
      onPlayPausePress(true);
    }
  }, [bot, lastMessageAudio]);

  useEffect(() => {
    console.log('stateTrack', stateTrack);
    if (stateTrack?.state === State.Ended) {
      setPlayOrPauseIcon('play');
      TrackPlayer.reset().then(r => {
        //console.log('reset', r);
      });
    }
    const {state} = stateTrack || {state: State.None};
    console.log('useEffect state', state);
    switch (state) {
      case State.None:
      case State.Ready:
      case State.Paused:
      case State.Stopped:
      case State.Ended:
        if (state === State.Ended) {
          TrackPlayer.reset().then(r => {
            //console.log('reset', r);
          });
        }
        //setPlayOrPauseIcon('play');
        break;
      default:
        //setPlayOrPauseIcon('pausecircle');
        break;
    }

    return () => {};
  }, [stateTrack]);

  async function onPlayPausePress(autoPlay = false) {
    const state = await TrackPlayer.getState();
    onChangeButtonState(buttonStates.LOADING);
    console.log('state', state);

    let t = await TrackPlayer.getActiveTrack();
    if (autoPlay) {
      await TrackPlayer.reset();
      await TrackPlayer.add({
        id,
        url,
        title,
      });
    }
    console.log('trackObject', t?.id, 'versus ', track?.id);
    if (t?.id !== track?.id) {
      await TrackPlayer.reset();
      await TrackPlayer.add({
        id,
        url,
        title,
      });
      t = await TrackPlayer.getTrack(id);
    }

    setTrack({
      ...track,
      progress: progress?.position,
      id: id,
    });

    // cuando es autoplay a veces se queda en ready o stopped y nunca se reproduce
    // autoPlay valida si viene desde apretar el boton o es automatico

    switch (state) {
      case State.Ready:
        if (autoPlay) {
          await TrackPlayer.play();
          onChangeButtonState(buttonStates.PLAYING);
          return;
        }
        onChangeButtonState(buttonStates.READY);
        return;
      case State.Paused:
      case State.None:
        onChangeButtonState(buttonStates.PLAYING);
        if (track?.progress === 0) {
          await TrackPlayer.play();
          return;
        }
        await TrackPlayer.seekTo(progress?.position);
        await TrackPlayer.play();
        return;
      case State.Ended:
        onChangeButtonState(buttonStates.PLAYING);
        await TrackPlayer.reset();
        await TrackPlayer.add({
          id,
          url,
        });
        await TrackPlayer.play();
        return;
      case State.Playing:
        onChangeButtonState(buttonStates.PAUSED);

        await TrackPlayer.pause();
        return;
      case State.Stopped:
        if (autoPlay) {
          onChangeButtonState(buttonStates.PLAYING);
          await TrackPlayer.play();
        }
        return;
      default:
        await TrackPlayer.reset();
        await TrackPlayer.add({
          id,
          url,
          title,
        });
        return;
    }
  }

  function onChangeButtonState(bState) {
    switch (bState) {
      case buttonStates.READY:
        setPlayOrPauseIcon('play');
        break;
      case buttonStates.LOADING:
        setPlayOrPauseIcon('loading1');
        break;
      case buttonStates.PLAYING:
        setPlayOrPauseIcon('pausecircle');
        break;
      case buttonStates.PAUSED:
        setPlayOrPauseIcon('play');
        break;
      default:
        setPlayOrPauseIcon('play');
        break;
    }
  }

  const RecordingDots = ({barColor}) => {
    const stylesBar = {
      height: 20,
      width: 3,
      borderRadius: 2,
      marginHorizontal: 2,
    };

    function hasAnimation(animation) {
      if (playOrPauseIcon === 'play') {
        return null;
      }
      return {
        transform: [
          {
            scale: animation,
          },
        ],
      };
    }

    return (
      <HStack justifyContent={'center'} flex={1} alignItems={'center'}>
        {dotAnimations.map((animation, index) => {
          return (
            <Anima.View
              key={`${index}`}
              style={[
                {...stylesBar},
                {backgroundColor: barColor},
                {...hasAnimation(animation)},
              ]}
            />
          );
        })}
      </HStack>
    );
  };

  return (
    <HStack>
      <Center>
        <Pressable
          testID={'on-play-pause-button'}
          // onPressIn={() => {
          //   console.log('clicccccccc');
          // }}
          onPress={async () => {
            await onPlayPausePress(true);
          }}>
          {playOrPauseIcon === 'loading1' ? (
            <ActivityIndicator size="small" color={bot ? 'white' : 'black'} />
          ) : (
            <Icon
              name={playOrPauseIcon}
              size={35}
              color={bot ? 'white' : 'black'}
            />
          )}
        </Pressable>
      </Center>
      <Center mx={2} testID={'wave-playing'}>
        <RecordingDots barColor={bot ? 'white' : 'black'} />
      </Center>
    </HStack>
  );
};

AudioWave.propTypes = {
  url: PropTypes.string,
};
export default AudioWave;
