/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

// birthDate
export const GET_THERAPISTS = gql`
  {
    therapyOptions {
      id
      image {
        cdnUrl
      }
      url
      urlLabel
      order
      label
      subLabel
      description
    }
  }
`;

export const GET_THERAPISTS_BY_LANG = gql`
  query therapyOptions(
    $language: String!
    $reasonForConsultation: Int
    $therapyType: Int
  ) {
    therapyOptions(
      language: $language
      reasonForConsultation: $reasonForConsultation
      therapyType: $therapyType
    ) {
      id
      label
      subLabel
      price {
        label
        stripePriceId
        id
      }
      image {
        cdnUrl
      }
      url
      description
      canScheduleInApp
      closestAppointment
      reasonForConsultation {
        label
      }
    }

    appointmentsAvailable

    profile {
      workCompany {
        variables
      }
    }
  }
`;

export const GET_THERAPY_TYPE = gql`
  {
    therapyTypes {
      id
      label
    }
  }
`;

export const GET_THERAPY_REASON = gql`
  {
    reasonForConsultation {
      id
      label
    }
  }
`;

export const GET_THERAPISTS_INFO = gql`
  query therapyOption(
    $id: Int
    $timeZone: String
    $fromDate: String
    $daysToReturn: Int
  ) {
    therapyOption(id: $id) {
      id
      label
      canScheduleInApp
      closestAppointment
      availability(
        fromDate: $fromDate
        timeZone: $timeZone
        daysToReturn: $daysToReturn
      ) {
        date
        hours {
          id
          hour
        }
      }
    }
  }
`;

export const GET_NEXT_THERAPYS = gql`
  query nextTherapyMeetings($first: Int, $after: String) {
    nextTherapyMeetings(first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          profile {
            timeZoneApp
          }
          status
          dynamicLink
          reservationId
          date
          startTime
          meetingPatientUrl
          daysLeft
          therapyOption {
            label
          }
        }
      }
    }
  }
`;

export const GET_PAST_MEETINGS = gql`
  query pastTherapyMeetings($first: Int, $after: String) {
    pastTherapyMeetings(first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          profile {
            timeZoneApp
          }
          status
          reservationId
          date
          startTime
        }
      }
    }
  }
`;

export const GET_RESERVATION = gql`
  query appointmentReservation($reservationId: String!) {
    appointmentReservation(reservationId: $reservationId) {
      status
      dynamicLink
      fullDateIso
      meetingPatientUrl
      date
      startTime
      therapyOption {
        image {
          cdnUrl
        }
        label
        subLabel
        description
      }
    }
  }
`;

export const CREATE_RESERVATION = gql`
  mutation createAppointmentReservation(
    $availabilityId: ID!
    $paymentInfoId: ID
  ) {
    createAppointmentReservation(
      availabilityId: $availabilityId
      paymentInfoId: $paymentInfoId
    ) {
      therapyOption {
        label
      }
      dynamicLink
      date
      time
      daysLeft
      fullDateIso
    }
  }
`;

export const SET_APPOINTMENT_RESERVATION = gql`
  mutation setAppointmentReservationAsTaken($reservationId: ID!) {
    setAppointmentReservationAsTaken(reservationId: $reservationId) {
      success
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($pricingId: String!) {
    createPaymentIntent(pricingId: $pricingId) {
      clientSecret
      paymentInfoId
      publishableKey
    }
  }
`;

export const SAVE_APPOINTMENT_TOKEN = gql`
  mutation saveAppointmentPaymentToken($token: String!) {
    saveAppointmentPaymentToken(token: $token) {
      success
    }
  }
`;
