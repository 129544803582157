import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

/**
 * Componente  Video.native normFal
 */

const VideoComponent = ({
  videoRef,
  url,
  paused,
  handleProgress,
  handleLoad,
  handleEnd,
}) => {
  console.log('url', url);
  return (
    <ReactPlayer
      // onEnded={() => {
      //   setFinish(true);
      //   onFinish(100);
      // }}
      // onStart={() => {
      //   onStart();
      // }}
      // onPlay={() => {
      //   onPlayPause(progress);
      // }}
      // onPause={() => {
      //   onPlayPause(progress);
      // }}
      // onProgress={e => {
      //   setProgress(e?.playedSeconds);
      // }}
      // width={'90%'}
      url={url}
      controls
      height="50px"
    />
  );

  // return (
  //   <Video
  //     ref={videoRef}
  //     w={'100%'}
  //     m={'0'}
  //     resizeMode={'cover'}
  //     source={{
  //       uri: url,
  //     }}
  //     controls={false}
  //     paused={paused}
  //     onProgress={handleProgress}
  //     onLoad={handleLoad}
  //     onEnd={handleEnd}
  //     style={{
  //       height: 500,
  //       borderTopLeftRadius: 20,
  //       borderTopRightRadius: 20,
  //       borderBottomLeftRadius: 0,
  //       borderBottomRightRadius: 0,
  //     }}
  //   />
  // );
};

VideoComponent.propTypes = {
  text: PropTypes.string,
};
export default VideoComponent;
