import {Box, View} from 'native-base';
import React from 'react';
import {getWidthAndHeight} from '../../../helpers/utils/utils';

/**
 * Componente  ChatContainer.native normal
 */

const ChatContainer = ({children}) => {
  const {width, height} = getWidthAndHeight();

  return <Box h={height}>{children}</Box>;
};

export default ChatContainer;
