import React, {useState} from 'react';
import CoachModal from './CoachModal';
import {Box, Center, Circle, Image, Pressable, Text} from 'native-base';
import PropTypes from 'prop-types';
import images from '../../res/images';
import {isWeb} from '../../helpers/utils/utils';

const CoachCardButton = ({coach}) => {
  const {meditation} = images;
  const [showModal, setShowModal] = useState(false);

  function openModalCoach() {
    console.log('openModalCoach ->', coach);
    setShowModal(true);
  }

  return (
    coach &&
    coach?.therapistName && (
      <Pressable
        testID="coach-card-button"
        w={isWeb() ? '30%' : '90%'}
        alignSelf={'center'}
        h={70}
        px={3}
        onPress={() => openModalCoach()}
        _pressed={{bg: 'secondary.600'}}
        flexDirection={'row'}
        alignItems={'center'}
        my={3}
        bg={'secondary.100'}
        rounded={'full'}
        mx={15}
        shadow="2">
        {coach?.therapistPhoto && (
          <Center w={'25%'}>
            <Circle size="sm">
              <Image
                testID={'coach-image-button'}
                size={'100%'}
                resizeMode={'cover'}
                source={
                  coach?.therapistPhoto
                    ? {
                        uri: coach.therapistPhoto,
                      }
                    : meditation
                }
                alt={'Mindsurf'}
                style={{borderRadius: 200}}
              />
            </Circle>
          </Center>
        )}
        <Box w={'62%'} pl={5}>
          <Text
            textAlign={'left'}
            numberOfLines={1}
            fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
            fontSize={'md'}
            fontWeight={800}
            color={'primary.100'}
            testID={'coach-name-button'}>
            {coach?.therapistName}
          </Text>
          <Text
            pt={1}
            textAlign={'left'}
            fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
            fontWeight={800}
            fontSize={'sm'}
            numberOfLines={1}
            ellipsizeMode="tail"
            opacity={0.5}
            color={'primary.60'}
            testID={'coach-approach-button'}>
            {coach?.approach ? coach?.approach : coach?.therapistDescription}
          </Text>
        </Box>
        <CoachModal
          coach={coach}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Pressable>
    )
  );
};

const CoachDataType = {
  therapistName: PropTypes.string.isRequired,
  therapistDescription: PropTypes.string,
  therapistPhoto: PropTypes.string,
  approach: PropTypes.string,
};

CoachCardButton.propTypes = {
  coach: PropTypes.objectOf(PropTypes.shape(CoachDataType)),
};

export default CoachCardButton;
