/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

/** Obtener todos los planes */
export const GET_PLANS = gql`
  {
    plans {
      id
      name
      label
      description
      exercises
      diaryEntry
      programs
      reports
      lives
      evaluations
      sessionsIncluded
      emotionalAssistant
      streamingVoice
      enabled
      externalSubscriptionIds {
        externalSubscriptionId
      }
    }
  }
`;

export const GET_DATA_SHARE_ACCOUNT = gql`
  {
    shareAccount {
      holder {
        nombres
      }
      members {
        nombres
      }
      isHolder
    }
  }
`;

/** Obtener todos los planes */
export const GET_SUBSCRIPTIONS = gql`
  {
    subscriptions {
      id
      plan {
        id
        label
        description
        exercises
        diaryEntry
        programs
        reports
        lives
        evaluations
        sessionsIncluded
        emotionalAssistant
        streamingVoice
        enabled
        externalSubscriptionIds {
          externalSubscriptionId
        }
      }
      autoRenewal
      status
      expirationDate
      isTrial
      provider
      lastSync
      sessionAvailable
      daysToExpire
    }
  }
`;

/** Obtener todos los planes */
export const GET_SUBSCRIPTIONS_ACTIVE = gql`
  query subscriptions($active: Boolean) {
    subscriptions(active: $active) {
      id
      plan {
        id
        label
        exercises
        diaryEntry
        programs
        reports
        evaluations
        sessionsIncluded
        emotionalAssistant
        streamingVoice
        enabled
        lives
        default
        showBuy
        showCancel
      }
      autoRenewal
      status
      expirationDate
      isTrial
      provider
      lastSync
      sessionsAvailable
      daysToExpire
    }
  }
`;

/** Crear suscripción exitosa */
export const CREATE_SUCESS_SUBSCRIPTION = gql`
  mutation subscriptionCreateMutation(
    $externalSubscriptionId: ID!
    $packageName: String
    $purchaseToken: String
    $subscriptionId: String
    $provider: String
    $originalTransactionId: String
  ) {
    subscriptionCreateMutation(
      externalSubscriptionId: $externalSubscriptionId
      packageName: $packageName
      purchaseToken: $purchaseToken
      subscriptionId: $subscriptionId
      provider: $provider
      originalTransactionId: $originalTransactionId
    ) {
      subscription {
        id
        plan {
          id
          label
          description
          exercises
          diaryEntry
          programs
          reports
          lives
          evaluations
          sessionsIncluded
          emotionalAssistant
          streamingVoice
          enabled
          externalSubscriptionIds {
            externalSubscriptionId
          }
        }
        autoRenewal
        status
        expirationDate
        isTrial
        provider
        lastSync
        sessionsAvailable
        daysToExpire
      }
    }
  }
`;

/** Aplicar descuento */
export const CREATE_DISCOUNT = gql`
  mutation ($code: String!) {
    applyDiscountCodeMutation(code: $code) {
      profile {
        email
        workCompany {
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;
