import React, {useState} from 'react';
import {Animated, View, Platform, Easing} from 'react-native';
import {Center} from 'native-base';

const UpAndDown = ({easing = Easing.ease, delay, transformation, children}) => {
  const [base] = useState(new Animated.Value(0));
  const [stateApp, setAppState] = useState();

  React.useEffect(() => {
    const anim = Animated.sequence([
      Animated.delay(delay),
      Animated.loop(
        Animated.timing(base, {
          toValue: 1,
          duration: 987,
          easing,
          useNativeDriver: Platform.OS !== 'web',
        }),
      ),
    ]);
    anim.start();
    return () => anim.stop();
  }, [stateApp]);

  const translateY = base.interpolate({
    inputRange: [0, 0.45, 0.55, 1],
    outputRange: [5, -5, -5, 5],
  });
  const translateX = base.interpolate({
    inputRange: [0, 0.45, 0.55, 1],
    outputRange: [-3, 0, 0, -3],
  });
  const scale = base.interpolate({
    inputRange: [0, 0.45, 0.55, 1],
    outputRange: [1, 0.6, 0.6, 1],
  });
  const opacity = base.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [0.6, 1, 0.6],
  });
  const transform: any = [];
  let opacityAnim: boolean = false;

  const trsf = (
    Array.isArray(transformation) ? transformation : [transformation]
  ).forEach(trsf => {
    switch (trsf) {
      case 'translateY':
        transform.push({translateY});
        break;
      case 'scale':
        transform.push({scale});
        break;
      case 'translateX':
        transform.push({translateX});
        break;
      case 'opacity':
        opacityAnim = true;
        break;
      default:
        transform.push({translateY});
    }
  });

  return (
    <Animated.View
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          width: 22,
          transform,
        },
        opacityAnim ? {opacity} : undefined,
      ]}>
      {children}
    </Animated.View>
  );
};

const TypingDot = () => {
  const transformation = 'translateY';
  const color = 'rgba(0, 0, 0, 0.38)';
  const size = 15;
  return (
    <Center flexDir={'row'}>
      {[0, 329, 658].map((delay: number) => (
        <UpAndDown {...{transformation, delay}}>
          <View
            style={{
              width: size,
              height: size,
              borderRadius: (size || 0) / 2,
              backgroundColor: color,
            }}
          />
        </UpAndDown>
      ))}
    </Center>
  );
};

export default TypingDot;
