import {colors} from './colors';
import {fonts} from './fonts';

export const markdown = {
  blockquote: {
    marginLeft: 10,
    opacity: 0.8,
  },
  code_block: {
    fontFamily: fonts.quicksand,
    fontWeight: '500',
  },
  del: {
    textDecorationLine: 'line-through',
  },
  em: {
    fontStyle: 'italic',
  },
  heading: {
    fontWeight: '700',
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
  },
  heading1: {
    // fontSize: 32,
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 0,
    marginRight: 0,
  },
  heading2: {
    // fontSize: 24,
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
  },
  heading3: {
    // fontSize: 20,
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
  },
  heading4: {
    // fontSize: 16,
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 0,
    marginRight: 0,
  },
  heading5: {
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    // fontSize: 14,
    marginTop: 22,
    marginBottom: 22,
    marginLeft: 0,
    marginRight: 0,
  },
  heading6: {
    fontSize: '13@ms',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
    // fontSize: 11,
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 0,
    marginRight: 0,
  },
  hr: {
    backgroundColor: '#ccc',
    height: 1,
  },
  imageWrapper: {
    fontFamily: fonts.quicksand,
    padding: 4,
    width: 320,
    height: 320,
  },
  image: {
    flexGrow: 1,
  },
  inlineCode: {
    backgroundColor: 'rgba(128, 128, 128, 0.25)',
    fontFamily: fonts.quicksand,
    fontWeight: '500',
  },
  link: {
    color: colors.white,
    fontWeight: '800',
    textDecorationLine: 'underline',
    fontSize: '14@ms',
  },
  list: {
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    fontWeight: '300',
    margin: 8,
    color: colors.white,
  },
  listItem: {
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    fontWeight: '300',
    flexDirection: 'row',
    color: colors.white,
  },
  listItemNumber: {
    minWidth: 32,
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    fontWeight: '500',
    paddingRight: 4,
    color: colors.white,
  },
  listItemBullet: {
    minWidth: 12,
    fontFamily: fonts.quicksand,
    color: colors.white,
    fontSize: '14@ms',
    fontWeight: '300',
    paddingRight: 4,
  },
  listItemOrderedContent: {
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    color: colors.white,
    fontWeight: '500',
    flex: 1,
  },
  listItemUnorderedContent: {
    flex: 1,
    fontFamily: fonts.quicksand,
    fontSize: '14@ms',
    fontWeight: '500',
    color: colors.white,
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '14@ms',
    color: colors.white,
    fontWeight: 500,
    fontFamily: fonts.quicksand,
  },
  strong: {
    fontWeight: '700',
  },
  table: {
    margin: 4,
    borderColor: '#222',
  },
  tableHeaderCell: {
    borderColor: '#222',
  },
  tableHeaderCellContent: {
    fontWeight: '700',
  },
  tableCell: {
    padding: 5,
  },
  tableCellOddRow: {
    backgroundColor: 'rgba(128, 128, 128, 0.1)',
  },
  tableCellEvenRow: {},
  tableCellLastRow: {
    borderBottomWidth: 0,
  },
  tableCellOddColumn: {},
  tableCellEvenColumn: {},
  tableCellLastColumn: {
    borderRightWidth: 0,
  },
  tableCellContent: {},
  tableCellContentOddRow: {},
  tableCellContentEvenRow: {},
  tableCellContentLastRow: {},
  tableCellContentOddColumn: {},
  tableCellContentEvenColumn: {},
  tableCellContentLastColumn: {},
  u: {
    textDecorationLine: 'underline',
  },
};
