import React from 'react';
import PropTypes from 'prop-types';
import {Box, IconButton} from 'native-base';
// import Icon from 'react-native-vector-icons/AntDesign';
import styles from '../styles';
import Icon from '../components/Icon/Icon';

/**
 * Componente  CircleButtonIcon que devuelve un boton en circulo con un icono
 * @param iconName nombre del icono AntDesign
 * @param onPress evento Press
 * @param testId ID para pruebas
 * @param iconColor color del icono
 * @param extraParam JSON con otros parametros
 */

const CircleButtonIcon = ({
  iconName,
  onPress,
  testId,
  iconColor,
  extraParams,
}) => {
  const {colours} = styles;
  return (
    <Box alignItems={'center'} rounded={'full'}>
      <IconButton
        testID={testId}
        borderRadius="full"
        bg={'transparent'}
        _pressed={{bg: 'transparent'}}
        onPress={onPress}
        {...extraParams}
        icon={
          <Icon
            name={iconName}
            size={48}
            color={iconColor || colours.primary100}
          />
        }
      />
    </Box>
  );
};

CircleButtonIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  extraParam: PropTypes.object,
};
export default CircleButtonIcon;
