import React, {useState} from 'react';
import {Text, Center, HStack, Input, Button} from 'native-base';
import PropTypes from 'prop-types';
import {colors} from '../../styles/colors';
import SendIcon from '../icons/SendIcon';
import {createChatStyles} from '../../styles/base';
import {useTranslation} from 'react-i18next';

/**
 * Componente  InputResponse normal
 */

const InputResponse = ({
  hasMenu = false,
  setText,
  text,
  onStartAnswer,
  disabled,
  sendMessage,
}) => {
  const chatStyles = createChatStyles();
  const {t} = useTranslation();

  return (
    <HStack
      testID={'responseBox-input-id'}
      w={hasMenu ? '90%' : '100%'}
      px={5}
      p={5}>
      <Input
        _focus={{
          _ios: {selectionColor: colors.primary10},
          _android: {selectionColor: colors.primary10},
        }}
        underlineColorAndroid={'transparent'}
        w={'80%'}
        value={text}
        testID={'response-input'}
        onChangeText={setText}
        onFocus={() => {
          onStartAnswer();
        }}
        textAlignVertical={'center'}
        placeholderTextColor={colors.purpleGray}
        placeholder={t('chat:placeholder')}
        multiline={true}
        numberOfLines={2}
        autogrow={true}
        scrollEnabled={true}
        returnKeyType={'done'}
        variant="lila"
        py={2}
        px={4}
        rounded={text?.length > 30 ? '3xl' : 'full'}
        size="md"
        borderColor={colors.purpleGray}
      />
      <Center flex={1}>
        <Button
          disabled={disabled || text === ''}
          onPress={sendMessage}
          py={3}
          px={3}
          testID={'responseBox-button-id'}
          rounded={'full'}
          style={[
            chatStyles.backgroundButton,
            !text === '' ? chatStyles.chipDisabled : null,
          ]}
          //onPress={submitText}
        >
          <SendIcon w={'20'} h={'20'} colour={colors.secondary100} />
        </Button>
      </Center>
    </HStack>
  );
};

InputResponse.propTypes = {
  text: PropTypes.string,
};
export default InputResponse;
