import Clipboard from '@react-native-clipboard/clipboard';
import {useNavigation} from '@react-navigation/native';
import {
  Box,
  Center,
  Checkbox,
  FormControl,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Pressable,
  Text,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import validator from 'validator';
import InputForm from '../../components/Form/InputForm';
import NavigateBar from '../../components/NavigateBar';
import PrimaryButton from '../../components/PrimaryButton';
import SelectPhone from '../../components/SelectPhone';
import Tab from '../../components/Tab';
import TermsAndConditions from '../../components/TermsAndConditions';
import {LOGIN_TYPES} from '../../constants/codeConstants';
import {remoteConfigFetch} from '../../core/firebase/firebaseAuth';
import {notificationInitialize} from '../../core/initialize/initializeService';
import {
  handleMessageModal,
  isWeb,
  getWidthAndHeight,
} from '../../helpers/utils/utils';
import {
  NotificationContext,
  RemoteConfigContext,
} from '../../hooks/SessionContext';
import images from '../../res/images';
import IconFont from '../../components/Icon/IconFont';

/**
 * Screen de Registro
 */
export default function Register({
  setIsWarning = () => {},
  setShowModalMessage = () => {},
  setMessage = () => {},
  onSubmit = () => {},
  onLogin,
  defaultData,
  loading,
  back,
  commonStylesWeb,
}) {
  const {
    remoteConfig: {loginTypeDefault},
  } = useContext(RemoteConfigContext);
  const navigation = useNavigation();

  const {setNotificationMessage} = useContext(NotificationContext);
  const {t, i18n} = useTranslation();
  const {topBarLogo} = images;
  const [lwc, setlwc] = useState(setDefaultProps('lwc'));
  const [validPhoneNumber, setvalidPhoneNumber] = useState(
    setDefaultProps('vphone'),
  );
  const [email, setEmail] = useState(setDefaultProps('email'));
  const [isEmail, setIsEmail] = useState(true);
  const [terms, setTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState(setDefaultProps('password'));
  const [validPassword, setValidPassword] = useState(true);
  const [confirmPW, setConfirmPW] = useState(setDefaultProps('password'));
  const [comparePassword, setComparePassword] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(setDefaultProps('phone'));
  const [helperTextEmail, setHelperTextEmail] = useState();
  const [helperTextPassword, setHelperTextPassword] = useState();
  const [helperTextConfirmPass, setHelperTextConfirmPass] = useState();
  const [companyAccess, setCompanyAccess] = useState(false);
  const [policeNumber, setPoliceNumber] = useState('');
  const {width} = getWidthAndHeight();

  let showFCMTokenCounter = 0;

  const setCellphone = useCallback(
    val => {
      setPhoneNumber(val);
    },
    [setPhoneNumber],
  );
  const setValidPhone = useCallback(
    val => {
      setvalidPhoneNumber(val);
    },
    [setvalidPhoneNumber],
  );

  const showFCMToken = async data => {
    console.log('showFCMToken.deviceInfo', data);
    Clipboard.setString(data.fcmToken);
    setNotificationMessage({
      title: '',
      message: 'FCMToken copiado',
      type: 'success',
    });
    // call a function and pass a callback
    remoteConfigFetch().then(pricingFlow => {
      console.log('remoteConfigFetch');
      setNotificationMessage({
        title: '',
        message: `PricingFlow ${pricingFlow}`,
      });
    });
  };

  function onClickShowFCMToken() {
    showFCMTokenCounter++;
    console.log('showFCMToken', showFCMTokenCounter);
    if (showFCMTokenCounter > 5) {
      console.log('Show fcm token');
      const notifications = notificationInitialize(showFCMToken);
      // if notifications is an object, return it
      if (typeof notifications === 'object' || notifications === null) {
        return;
      }
      notifications();
    }
  }

  function setDefaultProps(field) {
    switch (field) {
      case 'phone':
        if (!defaultData || !defaultData.phone) {
          const code = i18n.language === 'es' ? 'MX' : 'US';
          const iso = i18n.language === 'es' ? '+52' : '+1';
          return {
            codeCountry: code,
            isoCountry: iso,
            number: '',
            inputNum: '',
          };
        }
        return defaultData.phone;
      case 'email':
        if (!defaultData || !defaultData.email) {
          return '';
        }
        return defaultData.email;
      case 'lwc':
        if (defaultData) {
          return !defaultData.email;
        } else {
          return loginTypeDefault === 'PHONE';
        }
      case 'vphone':
        return !!(defaultData && defaultData.phone);

      case 'password':
        return defaultData?.password || '';
    }
  }

  function onChangePassword(val) {
    setPassword(val);
    const validPW = validator?.isStrongPassword(val, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    setValidPassword(validPW || val?.length <= 3);
  }

  function validateButton() {
    const validPw =
      validPassword && password === confirmPW && password.length >= 8;
    return !lwc
      ? validEmail && validPw && terms
      : validPhoneNumber && validPw && terms;
  }

  function validateForm() {
    if (!terms) {
      handleMessageModal(
        true,
        true,
        t('login:acceptTerms'),
        setIsWarning,
        setShowModalMessage,
        setMessage,
      );
      return {
        valid: false,
        field: 'TERMS',
      };
    }

    const isLWC = lwc;
    const validPw =
      validPassword && password === confirmPW && password.length > 7;

    if (isLWC) {
      if (!validPhoneNumber) {
        setvalidPhoneNumber(false);
        return {
          valid: false,
          field: 'PHONE',
        };
      }
    } else if (!validEmail || email.length <= 3) {
      setValidEmail(false);
      return {
        valid: false,
        field: 'EMAIL',
      };
    }

    if (!validPw) {
      setComparePassword(false);
      return {
        valid: false,
        field: 'PASSWORD_CONFIRM',
      };
    }

    const companyAccessCheck = companyAccess ? policeNumber.length > 3 : true;

    return {
      valid: isLWC
        ? validPhoneNumber && validPw && companyAccessCheck
        : email.length > 3 && validPw && companyAccessCheck,
    };
  }

  function submit() {
    const {valid} = validateForm();
    if (!valid) {
      return;
    }
    onSubmit({
      type: lwc ? LOGIN_TYPES.REGISTER_PHONE : LOGIN_TYPES.REGISTER_EMAIL,
      companyAccess,
      phoneNumber,
      email,
      policeNumber,
      password,
    });
  }

  const handleEmailChange = text => {
    setEmail(text);
    let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setValidEmail(text?.length === 0 || reg.test(text));
  };

  const handleConfirmPassword = text => {
    setConfirmPW(text);
    setComparePassword(password === confirmPW);
  };

  useEffect(() => {
    setComparePassword(password === confirmPW);
  }, [password, confirmPW]);

  useEffect(() => {
    setHelperTextEmail(
      validEmail ? t('login:codeConfirmation') : t('login:inputEmailInvalid'),
    );
    setHelperTextPassword(validPassword ? '' : t('login:inputPasswordInvalid'));
    setHelperTextConfirmPass(
      comparePassword ? '' : t('login:inputPasswordAgainInvalid'),
    );
  }, [validEmail, validPassword, comparePassword, isEmail, password]);

  const stylesApp = isWeb()
    ? undefined
    : {space: 2.5, alignSelf: 'center', py: 3};

  return (
    <Box {...stylesApp}>
      <NavigateBar
        navigation={isWeb() ? undefined : navigation}
        headerTitle={
          <Image
            mt={isWeb() ? 10 : 0}
            size={'xs'}
            resizeMode={'contain'}
            alt={'logo'}
            source={topBarLogo}
          />
        }
      />
      {/* Titulo */}
      <Box mt={isWeb() ? 0 : 3} alignItems="center">
        <Pressable onPress={onClickShowFCMToken}>
          <Heading
            fontFamily={'recoleta'}
            mt={1}
            color="primary.100"
            fontWeight={800}
            size={'xl'}>
            {t('login:createAccountTitle')}
          </Heading>
        </Pressable>
        <Text testID={'register-message-ready'} fontSize={'md'}>
          {t('login:ready')}
        </Text>
      </Box>
      {/* Form */}
      <Box my={4}>
        <HStack space={5} justifyContent={'center'} alignItems={'center'}>
          <Tab
            testID={'tab-option'}
            setState={setlwc}
            options={[t('login:phone'), t('login:mail')]}
          />
        </HStack>
      </Box>
      <Box px={5} mt={1}>
        {lwc ? (
          <FormControl mb="2">
            {/* Cellphone */}
            <Box>
              <SelectPhone
                id={'input-phone-register'}
                validPhone={validPhoneNumber}
                setValidPhone={setValidPhone}
                cellphone={phoneNumber}
                setCellphone={setCellphone}
                language={i18n.language}
              />
            </Box>
            <Box>
              <InputForm
                helperText={helperTextPassword}
                testID={'password-phone'}
                label={t('login:inputPassword')}
                setInput={onChangePassword}
                input={password}
                isEmail={!isEmail}
                valid={validPassword}
              />
            </Box>
            <Box>
              <InputForm
                helperText={helperTextConfirmPass}
                testID={'confirm-password-phone'}
                label={t('login:inputPasswordAgain')}
                setInput={handleConfirmPassword}
                input={confirmPW}
                isEmail={!isEmail}
                valid={comparePassword}
                confirmPassword={true}
              />
            </Box>
          </FormControl>
        ) : (
          <Box flexDirection={'row'} flexWrap={'wrap'}>
            <FormControl w={'100%'}>
              {/* Email */}
              <InputForm
                testID={'email-input-register'}
                helperText={helperTextEmail}
                label={t('login:inputEmail')}
                setInput={handleEmailChange}
                input={email}
                isEmail={isEmail}
                valid={validEmail}
              />
              <Box>
                <InputForm
                  testID={'password-input-register'}
                  helperText={helperTextPassword}
                  label={t('login:inputPassword')}
                  setInput={onChangePassword}
                  input={password}
                  isEmail={!isEmail}
                  valid={validPassword}
                />
              </Box>
              <Box>
                <InputForm
                  testID={'confirm-password-input-register'}
                  helperText={helperTextConfirmPass}
                  label={t('login:inputPasswordAgain')}
                  setInput={handleConfirmPassword}
                  input={confirmPW}
                  isEmail={!isEmail}
                  valid={comparePassword}
                  confirmPassword={true}
                />
              </Box>
            </FormControl>
          </Box>
        )}
      </Box>
      <Box
        alignSelf={'center'}
        w={isWeb() ? '100%' : width * 0.95}
        ml={isWeb() ? 10 : 5}
        mb={1}>
        <TermsAndConditions
          setTerms={setTerms}
          testID={'check-terms-conditions'}
        />
        <FormControl mb="2">
          <Checkbox.Group accessibilityLabel="choose numbers">
            <Checkbox
              value={'uno'}
              testID={'checkbox-register-company'}
              my={2}
              colorScheme="white"
              icon={
                <Box
                  justifyContent="center"
                  alignItems="center"
                  bg={'primary.100'}>
                  <IconFont name="square" size={12} />
                </Box>
              }
              onChange={() => {
                setCompanyAccess(!companyAccess);
              }}>
              <Text
                fontWeight={600}
                fontFamily={'Quicksand'}
                color={'primary.100'}>
                {t('login:inputCodeDiscount')}
              </Text>
            </Checkbox>
          </Checkbox.Group>
        </FormControl>
      </Box>
      {companyAccess && (
        <Box alignSelf={'center'} pb={5} px={isWeb() ? 5 : 0} w={'100%'}>
          <FormControl w={width * 0.9}>
            <InputForm
              testID={'register-police-number'}
              helperText={'codigo invalido'}
              label={t('login:inputCodeDiscountLabel')}
              setInput={setPoliceNumber}
              input={policeNumber}
              isEmail={true}
              showWarning={false}
            />
          </FormControl>
        </Box>
      )}

      <Box px={7}>
        <PrimaryButton
          testId={'register-button-id'}
          onPress={submit}
          extraParams={{
            isLoading: loading,
            isDisabled: !validateButton(),
            ...commonStylesWeb,
          }}>
          {t('login:buttonCreateAccount')}
        </PrimaryButton>
        <Center>
          <Link
            my={3}
            safeAreaBottom
            _text={{
              fontSize: 'md',
              fontWeight: 800,
              fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
            }}
            color="primary.100"
            isUnderlined={false}
            onPress={onLogin}>
            {t('login:loginAccountLower')}
          </Link>
        </Center>
      </Box>
    </Box>
  );
}
