import React from 'react';
import {Box, Button, Center, Text, VStack} from 'native-base';
import {FlatList} from 'react-native';
import {createChatStyles} from '../../../styles/base';
import WaitingForResponse from '../WaitingForResponse/WaitingForResponse';
import FinishButton from '../FinishButton/FinishButton';
import ResponseBox from '../ResponseBox';
import {
  CHAT_STATE_RESPONSE,
  CHAT_TYPES,
} from '../../../constants/codeConstants';
import MessageChat from '../MessageChat';
import {useTranslation} from 'react-i18next';
import {isWeb, getWidthAndHeight} from '../../../helpers/utils/utils';

const ChatSkeletonStreaming = ({
  messages,
  chatStateResponse,
  onSendResponse,
  finishText,
  lastMessage,
  onFinishConversation = () => {},
  chatModule,
  storageFeedback = () => {},
  openComment = () => {},
  isFeedbackShow,
  sendToCall,
}) => {
  const chatStyles = createChatStyles();
  const copyButton = finishText || 'Finalizar';
  const {t} = useTranslation();
  const {height} = getWidthAndHeight();

  function onResponse(content, type, isStreaming = false) {
    /*
     * type: 'text' | 'audio',
     * format: 'base64',
     * content: string,
     *
     * */
    const response = {
      content,
      type,
    };

    const extraParams = type === CHAT_TYPES.TEXT ? null : {format: 'base64'};

    onSendResponse({
      ...response,
      ...extraParams,
    });
  }

  const DefaultView = () => <></>;

  function renderAnswers() {
    let currentChatStateResponse = chatStateResponse;

    if (
      chatStateResponse === CHAT_STATE_RESPONSE.NOT_RECONNECTED &&
      lastMessage?.finishMessage
    ) {
      currentChatStateResponse = CHAT_STATE_RESPONSE.FINISH_MESSAGE;
    }
    const renderFunc = {
      WAITING_FOR_RESPONSE: () => (
        <Box testID="waiting-response">
          <WaitingForResponse />
        </Box>
      ),
      FINISH_MESSAGE: () => (
        <Box testID="finish-button">
          <FinishButton
            labelText={copyButton}
            onFinishConversation={onFinishConversation}
          />
        </Box>
      ),
      EXPECTING_RESPONSE: () => (
        <Box testID="response-box">
          <ResponseBox
            lastMessage={lastMessage}
            onResponse={onResponse}
            disabled={false}
            chatModule={chatModule}
            isStreaming={true}
            sendToCall={sendToCall}
            //onStartAnswer={onStartAnswer}
          />
        </Box>
      ),
      RECONNECTING: () => (
        <Box py={2} my={5} mx={3} px={5} rounded={'full'} bg={'negative.3'}>
          <Center>
            <Text fontWeight={800} fontSize={'md'} color={'secondary.100'}>
              {t('chat:reconnecting')}
            </Text>
          </Center>
        </Box>
      ),
      NOT_RECONNECTED: () => (
        <Center>
          <Button
            testID="not-reconnected-button"
            onPress={onFinishConversation}
            my={5}
            mx={3}
            py={2}
            px={5}
            rounded={'full'}
            fontWeight={800}
            fontSize={'md'}>
            {t('chat:notReconnected')}
          </Button>
        </Center>
      ),
    }[currentChatStateResponse];
    if (renderFunc === undefined) {
      return <DefaultView />;
    }

    return <>{renderFunc()}</>;
  }

  const BoxStreaming = ({children}) => {
    if (isWeb()) {
      return (
        <Box height={height * 0.9} px={2}>
          {children}
        </Box>
      );
    }
    return <>{children}</>;
  };

  return (
    <BoxStreaming>
      <VStack flex={1} pt={2} justifyContent="space-between" px={3}>
        <FlatList
          scrollEnabled
          style={chatStyles.flat}
          data={messages}
          inverted
          keyExtractor={item => (item?.id ? item?.id : item?.index)}
          renderItem={item => (
            <Box testID={`message-chat-${item?.item?.id}`}>
              <MessageChat
                message={item?.item}
                lastMessage={lastMessage}
                isFeedbackShow={isFeedbackShow}
                storageFeedback={storageFeedback}
                openComment={openComment}
                hasAnimation={false}
              />
            </Box>
          )}
          onEndReachedThreshold={0.1}
          optimized={false}
        />

        <Box w={'100%'} alignSelf={'flex-end'}>
          {renderAnswers()}
        </Box>
      </VStack>
    </BoxStreaming>
  );
};

export default ChatSkeletonStreaming;
