import {gql} from '@apollo/client';

/** Entrada de diario */
export const CREATE_DIARY_ENTRY = gql`
  mutation createDiaryEntry($input: DiaryEntryCreateMutationInput!) {
    createDiaryEntry(input: $input) {
      diaryEntry {
        id
        emotion {
          name
          label
        }
        emotionSecondary {
          name
          conversationFlowForTheory {
            name
          }
          conversationFlow {
            name
          }
        }
        timestamp
        conversation
        emotionalNote
      }
      errors {
        field
        messages
      }
    }
  }
`;

/** Historial por paginación */
export const GET_DIARY_ENTRIES = gql`
  query diaryEntries($first: Int, $after: String, $period: String) {
    diaryEntries(first: $first, after: $after, period: $period) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          emotion {
            label
          }
          emotionSecondary {
            label
          }
          timestamp
          conversation
          emotionalNote
        }
      }
    }
  }
`;

/** Obtener el diario por ID */
export const GET_DIARY_BY_ID = gql`
  query diaryEntry($id: ID!) {
    diaryEntry(id: $id) {
      id
      conversation
      timestamp
    }
  }
`;

/** Obtener el diario por ID */
export const GET_DIARY_BY_ID_EXTRA_INFO = gql`
  query diaryEntry($id: ID!) {
    diaryEntry(id: $id) {
      id
      conversation
      timestamp
      emotion {
        label
      }
      emotionSecondary {
        label
      }
    }
  }
`;

/** Obtener los periodos que tienen entradas de diario */
export const GET_DIARY_PERIODS = gql`
  {
    diaryPeriods {
      periods
    }
  }
`;

/** Editar diario con la conversación */
export const UPDATE_DIARY_ENTRY = gql`
  mutation updateDiaryEntry($input: DiaryEntryUpdateMutationInput!) {
    updateDiaryEntry(input: $input) {
      diaryEntry {
        id
        timestamp
        conversation
        emotion {
          name
          recommendations {
            id
            title
            shortDescription
            contentType
            image {
              cdnUrl
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

/** Obtener grafica de dias llenados del diario emocional */
export const GET_DIARY_WEEK_STATS = gql`
  {
    diaryWeekStats {
      days {
        day
        basics
        conversations
        emotion
      }
    }
  }
`;

export const DIARY_CALENDAR_ENTRY = gql`
  query diaryCalendarEntry($period: String) {
    diaryCalendarEntry(period: $period) {
      weeks {
        days {
          emotion {
            name
          }
          hasConversation
          date
          day
        }
      }
    }
  }
`;

// Get Conversation Flow Details
export const GET_CONVERSATION_FLOW = gql`
  query conversationFlow($id: ID, $name: String) {
    conversationFlow(id: $id, name: $name) {
      id
      name
      displayDetails {
        id
        title
        shortDescription
        duration
      }
    }
  }
`;

export const GET_YEAR_DIARY_PERIODS = gql`
  query yearDiaryPeriods {
    yearDiaryPeriods {
      periods
    }
  }
`;
