import {Box, Center, HStack, Image, Text} from 'native-base';
import React, {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {colors} from '../../styles/colors';
import PrimaryButton from '../PrimaryButton';

const TherapistCard = forwardRef((props, ref) => {
  const {t} = useTranslation();
  const {onRedirectAndClose} = props;
  // console.log('la teraia');
  return (
    <Box
      testID={'bot-therapy'}
      borderRadius={20}
      px={5}
      py={4}
      borderWidth={1}
      borderColor={colors.primary100}
      bg={'white'}>
      <Center>
        <Center
          bg={'secondary.60'}
          borderRadius={20}
          px={5}
          py={2}
          opacity={0.5}>
          <Text fontWeight={800} color={'primary.60'}>
            {t('evaluations:therapy')}
          </Text>
        </Center>
      </Center>
      <HStack mb={5} w={'100%'} flex={1}>
        <Box w={'30%'}>
          <Image
            source={{
              uri: 'https://res.cloudinary.com/dnmco0j2g/image/upload/v1666618160/jenny_img/img-853.png',
            }}
            borderRadius={'full'}
            w={'75px'}
            h={'75px'}
            alt={'mindsurf'}
          />
        </Box>
        <Box w={'70%'}>
          <Text
            fontSize={'2xl'}
            fontFamily={'recoleta'}
            fontWeight={800}
            color={colors.primary100}>
            {t('chat:sessionTherapy')}
          </Text>
        </Box>
      </HStack>
      <PrimaryButton
        testId={'btn-therapist-card'}
        onPress={() => {
          onRedirectAndClose({route: 'Terapia', params: {}});
        }}>
        {t('chat:startEvaluation')}
      </PrimaryButton>
    </Box>
  );
});

export default TherapistCard;
