/* istanbul ignore file */

/* eslint-disable react-hooks/exhaustive-deps */
import AsyncStorage from '@react-native-async-storage/async-storage';

const SESSION_KEYS = [
  'session',
  'user',
  'fcmToken',
  'apolloToken',
  'registerPrefix',
];

/**
 * Servicio para guardar en AsyncStorage. Se maneja como un useState.
 */
// const useAsyncStorage = (key, defaultValue) => {
//   const [state, setState] = useState(defaultValue);

//   async function pullFromStorage() {
//     const fromStorage = await AsyncStorage.getItem(key);
//     let value = defaultValue;
//     if (fromStorage) {
//       value = JSON.parse(fromStorage);
//     }
//     setState(value);
//   }

//   async function updateStorage(newValue) {
//     const stringifiedValue = JSON.stringify(newValue);
//     await AsyncStorage.setItem(key, stringifiedValue);
//     setState(newValue);
//   }

//   async function getData() {
//     return await AsyncStorage.getItem(key);
//   }

//   useEffect(() => {
//     pullFromStorage();
//     // return async () => {
//     //   await AsyncStorage.clear();
//     // };
//   }, [state]);

//   return [state, getData, updateStorage];
// };

// export default useAsyncStorage;

export const revomeItem = async item => {
  const fromStorage = await AsyncStorage.getItem(item);
  if (fromStorage) {
    await AsyncStorage.removeItem(item);
  }
};

export const clearItems = async (items = []) => {
  if (items.length <= 0) {
    await AsyncStorage.clear();
    SESSION_KEYS.forEach(revomeItem);
    return;
  }
  items.forEach(async i => {
    await revomeItem(i);
  });
};
