import React from 'react';
import {Text, Modal, Button, Center} from 'native-base';
import {useTranslation} from 'react-i18next';

/**
 * Modal sobre pausar la evaluación
 * @param showModal if is open
 * @param draftEvaluation evento al dar pausar
 * @param setShowModal Open o close el modal evento
 */

const ModalEvaluationDone = ({showModal, setShowModal, onClose}) => {
  const {t} = useTranslation();

  return (
    <Modal isOpen={showModal} _backdrop={{opacity: 0.65}}>
      <Modal.Content bg={'positive.6'}>
        <Modal.Body p={5}>
          <Text fontSize={'lg'} color="primary.100">
            {t('evaluations:evaluationModalDone')}
          </Text>
        </Modal.Body>
        <Modal.Footer bg={'positive.6'}>
          <Center>
            <Button
              onPress={onClose}
              bg={'primary.100'}
              _text={{
                color: 'secondary.100',
                fontWeight: 800,
                fontFamily: 'Quicksand',
              }}
              testID={'modalE-id'}
              _pressed={{bg: 'primary.800'}}
              rounded={'full'}>
              {t('buttons:next')}
            </Button>
          </Center>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ModalEvaluationDone;
