import React from 'react';
import {Modal, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {getFormatDateHour2} from '../../helpers/utils/utils';

const ExerciseHistoryModal = ({exerciseId, records, showModal, onClose}) => {
  const {t} = useTranslation();

  return (
    <Modal
      isOpen={showModal}
      onClose={onClose}
      size={'xl'}
      _backdrop={{opacity: 0.2}}>
      <Modal.Content
        borderRadius={'3xl'}
        bg={'white'}
        py={1}
        borderColor={'secondary.100'}>
        <Modal.CloseButton
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
          testID="close-exercise-records-modal"
        />
        <Modal.Header
          w={'85%'}
          flexDir={'row'}
          justifyContent={'space-between'}
          pb={1}
          pl={8}
          bg={'secondary.100'}
          borderColor={'secondary.100'}>
          <Text
            textAlign={'center'}
            color={'primary.100'}
            fontSize={'md'}
            fontWeight={800}
            fontFamily={'Quicksand'}>
            {t('introex:historyTitle')}
          </Text>
        </Modal.Header>
        <Modal.Body pl={8}>
          {records.map((record, index) => (
            <Text
              key={index}
              testID={`record-${index}`}
              fontSize={'sm'}
              my={1}
              color={'primary.100'}>
              {getFormatDateHour2(record.updatedAt)}
            </Text>
          ))}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

ExerciseHistoryModal.propTypes = {
  exerciseId: PropTypes.any,
  records: PropTypes.array,
};

export default ExerciseHistoryModal;
